import classNames from 'classnames';
import React, { ChangeEvent, KeyboardEvent, PureComponent } from 'react';
import SearchIcon from '../../../assets/icon-search.svg';
import CloseIcon from '../../../assets/icon-close.svg';
import './SearchColumns.scss';

interface DispatchProps {
  onSearchChange: (str: string) => void;
};

interface StateProps {
  isActive: boolean
  str: string
  columnName: string
  onSearchChange: (str: string) => void
};

type Props = StateProps & DispatchProps;

type State = {};

class SearchColumn extends PureComponent<Props, State> {
  private searchRef: HTMLInputElement | null = null;
  state = {
    isActive: false
  }


  setSearchStr = (e: ChangeEvent) => {
    e.stopPropagation();
    const { value } = e.target as HTMLInputElement;
    this.props.onSearchChange(value);
  }

  searchStartActive = () => {
    if (!this.state.isActive) {
      this.setState({ isActive: true }, () => {
        this.searchRef && this.searchRef.focus();
      });
    }
  }

  searchClose = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.keyCode === 27) {
      this.searchStopActive();
    }
  }

  searchStopActive = () => {
    this.props.onSearchChange('');
    this.setState({ isActive: false });
  }

  render() {
    const { str, columnName } = this.props;
    const { isActive } = this.state;

    return (
      <div className={classNames('SearchColumn', {active: isActive})} onClick={this.searchStartActive}>
        <span className="search-placeholder">{columnName}</span>
        <SearchIcon className="search-icon" />
        <input
          ref={(ref) => this.searchRef = ref}
          className="search-control"
          value={str}
          onChange={this.setSearchStr}
          onKeyDown={this.searchClose}
          placeholder="Search..."
          autoFocus={true}
        />
        <CloseIcon className="search-close" onClick={this.searchStopActive} />
      </div>
    );
  }
}

export default SearchColumn;