import classnames from 'classnames';
import React, { BaseSyntheticEvent, ChangeEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { authActions, IChangePasswordForm } from '../../../store/auth';
import './ChangePasswordForm.scss';
import _ from 'lodash';
import { ThemedInput, ThemedButton } from '~/components/common/themed';

interface DispatchProps {
  updatePassword: (form: IChangePasswordForm) => Promise<{}>;
};

interface OwnProps extends RouteComponentProps {
  note: string;
};

interface StateProps {
  authenticatedUser: any;
  authenticationError: any[];
};

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  form: {
    password: string,
    confirm_password: string,
  }
};

const mapDispatchToProps = {
  updatePassword: authActions.updatePassword
}

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.auth.authenticatedUser,
  authenticationError: state.auth.errors,
  note: _.get(state, 'auth.authenticatedUser.display_note')
})

class ChangePasswordForm extends PureComponent<Props, State> {

  state = {
    form: {
      password: '',
      confirm_password: '',
    }
  }

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    // Validate form fields here
    // Send form
    const { password } = this.state.form;
    this.props.updatePassword({ password, must_change_password: false }).then(() => this.props.history.push('/'))
  }

  handleFieldChange = (id: string, value: string) => {
    this.setState({ form: { ...this.state.form, [id]: value } });
  }

  checkLength = () => this.state.form.password.length >= 8
  checkFirst = () => /^[a-zA-Z].*/.test(this.state.form.password)
  checkCapital = () => /[A-Z]+/.test(this.state.form.password)
  checkNumber = () => /[0-9]+/.test(this.state.form.password)
  checkSpecial = () => /[\!\@\#\$\%\^\&\*]+/.test(this.state.form.password)
  checkConfirm = () => this.state.form.password && this.state.form.password === this.state.form.confirm_password
  validPassword = () => this.checkLength() && this.checkFirst() && this.checkCapital() && this.checkNumber() && this.checkSpecial && this.checkConfirm()

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h1 className="prt-page-title">Set a new password</h1>
        <div className="prt-form-field password">
          <ThemedInput
            autoFocus
            id="password"
            onChange={this.handleFieldChange}
            placeholder="Password"
            required
            type="password"
            value={this.state.form.password}
          />
          <div className='ProfileAccount__PasswordRequirements'>
            <h4>Password requirements:</h4>
            <ul>
              <li className={this.checkLength() ? 'active' : ''}>8 characters minimum</li>
              <li className={this.checkFirst() ? 'active' : ''}>First character has to be alpha</li>
              <li className={this.checkCapital() ? 'active' : ''}>At least one capital letter</li>
              <li className={this.checkNumber() ? 'active' : ''}>At least one number</li>
              <li className={this.checkSpecial() ? 'active' : ''}>At least one special character<br />(!, @, #, $, %, ^, &, *)</li>
            </ul>
          </div>
        </div>
        <div className="prt-form-field">
          <ThemedInput
            id="confirm_password"
            onChange={this.handleFieldChange}
            placeholder="Retype password"
            required
            type="password"
            value={this.state.form.confirm_password}
          />
        </div>
        <ul className="errors">
          {this.props.authenticationError.map((error: string, i: number) => <li key={i}>{error}</li>)}
          {this.props.authenticationError.length === 0 && this.props.note && <li key='expired'>{this.props.note}</li>}
          {/* TODO Following line won't work because of response structure and lead to blank screen. Not sure if it should be fixed within a scope of given task */}
        </ul>
        <div className="prt-submit">
          <ThemedButton type="submit" className={classnames("btn-primary full-width", { disabled: !this.validPassword() })}>Sign In</ThemedButton>
        </div>
      </form>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm));