import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { customersActions, ICustomer } from '../../store/customer';
import './CustomersView.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import { modalActions } from '~/store/modal';
import { ThemedButton, ThemedInput } from '~/components/common/themed';
import { assetsActions } from '~/store/assets';
import _ from 'lodash';

interface OwnProps { }

interface DispatchProps {
  closeModal: () => void;
  deleteCustomer: (userId: string) => Promise<any>;
  exportItems: (opt: any) => Promise<any>;
  listCustomers: () => void;
  openModal: (modalId: string, props: any) => void;
  setCustomer: (userId: string) => void;
}

interface StateProps {
  customers: ICustomer[];
  errors: string[];
  isAuthenticated: boolean;
  isFetching: boolean;
  authenticatedUser: any;
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {}

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  deleteCustomer: customersActions.deleteCustomer,
  exportItems: assetsActions.exportItems,
  listCustomers: customersActions.listCustomers,
  openModal: modalActions.openModal,
  setCustomer: customersActions.setCustomer,
};

const mapStateToProps = (state: any) => ({
  customers: state.customers.customers,
  errors: state.customers.errors,
  isAuthenticated: state.auth.isAuthenticated,
  isFetching: state.customers.isFetching,
  authenticatedUser: state.auth.authenticatedUser
});


class CustomersView extends PureComponent<Props, State> {

  state = {
    search_email_phone: ''
  }

  componentDidMount() {
    // Fetch the list of users
    if (this.props.isAuthenticated) {
      this.props.listCustomers();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isAuthenticated && prevProps.isAuthenticated !== this.props.isAuthenticated) {
      this.props.listCustomers();
    }
  }

  handleEditCustomer = (customerId: string) => {
    this.props.openModal('CUSTOMER_MODAL', { customerId });
  }

  addCustomer = () => {
    this.props.openModal('CUSTOMER_MODAL', { customerId: null });
  }

  exportItems = (e: React.MouseEvent) => {
    e.preventDefault();
    const opt = {
      url: 'csv/customer'
    };
    this.props.exportItems(opt);

  }

  handleDeleteCustomer = (customerId: string) => {
    this.props.deleteCustomer(customerId).then(this.props.closeModal).then(this.props.listCustomers);
  }

  handleFieldChange = (id: string, value: string) => {
    this.setState({ search_email_phone: value });
  };

  isNumeric = (value: string) => {
    return /^-?\d+$/.test(value);
  }

  filterRows = (rows: any[]) => {
    if (this.state.search_email_phone) {
      const search_email_phone = this.state.search_email_phone;
      const isNumber = this.isNumeric(search_email_phone);
      const rows1 = _.filter(rows, (row: any) => {
        if (isNumber) {
          const phone_index = _.findIndex(row.phones, (phone: string) => { return phone.indexOf(search_email_phone) > -1 });
          if (phone_index >= 0) {
            return true;
          }
        }
        const email_index = _.findIndex(row.emails, (email: string) => { return email.toLowerCase().indexOf(search_email_phone) > -1 });
        return email_index >= 0;
      });
      return rows1;
    }
    return rows;
  }

  render() {

    const customersData = this.filterRows([...this.props.customers]).map((customer: ICustomer) => ([
      { text: customer.name, link: `/customers/${customer.id}` },
      customer.reseller_name,
      customer.user_cnt,
      customer.phone_cnt,
      '' + customer.status,
      new Date(customer.recent_activity),
      new Date(customer.created),
      {
        buttons: [
          {
            classnames: ['link'],
            onClick: () => this.handleEditCustomer(customer.id),
            text: 'Edit',
          },
          {
            classnames: ['link', 'archive'],
            onClick: () => this.props.openModal('CONFIRM_MODAL', {
              title: 'Archive Customer',
              body: `Are you sure you want to archive the customer <strong>${customer.name}</strong> ?`,
              isFetching: this.props.isFetching,
              onYes: this.handleDeleteCustomer.bind(this, customer.id),
              onNo: this.props.closeModal,
            }),
            text: 'Archive',
          }
        ]
      }
    ]));
    return (
      <div className='prt'>
        <Header />
        <main className="prt-body">
          <div className="prt-wrapper">
            <div className="prt-head">
              <h1 className="prt-page-title">Customers</h1>
              <div>
                {this.props.authenticatedUser.user_level === 'administrator' && <div className="searchby">
                  <ThemedInput
                    className='search-input'
                    autoFocus
                    id="search"
                    type='text'
                    onChange={this.handleFieldChange}
                    placeholder="Search E-mail/Numbers"
                    value={this.state.search_email_phone}
                  />
                </div>}
                <ThemedButton className="btn-primary" onClick={this.addCustomer}>Add Customer</ThemedButton>
              </div>
            </div>
            {this.props.errors && this.props.errors.length > 0 && (<div className='prt-form-field'>
              <div className="full-width error-msg">
                {this.props.errors.map((msg: string, key: number) => <p key={key}>{msg}</p>)}
              </div>
            </div>)}
            <Table
              data={customersData}
              columns={[
                { name: 'Customer Name', type: 'link' },
                { name: 'Partner', type: 'string', access: ['administrator'] },
                { name: 'Users', type: 'number', access: ['administrator', 'reseller'] },
                { name: 'Numbers', type: 'number' },
                { name: 'Status', type: 'status' },
                { name: 'Recent Activity', type: 'date' },
                { name: 'Added', type: 'date' },
                { name: '', type: 'buttons' },
              ]}
            />
            <div className="prt-table-footer">
              {/*<span className="prt-table-filter">
                Show: <a href="#" className="btn-secondary is-select">10</a>
              </span>
              <div className="prt-pagination">
                <a href="#" className="prt-pagination-prev disabled">Previous</a>
                <a href="#" className="current">1</a>
                <a href="#">2</a>
                <a href="#">3</a>
                <a href="#">4</a>
                <a href="#">5</a>
                <a href="#" className="prt-pagination-next">Next</a>
            </div>*/}
              <a href="#" onClick={this.exportItems} className="btn-secondary">Export</a>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersView as any);