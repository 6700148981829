import classNames from 'classnames';
import React, {PureComponent, ChangeEvent} from 'react';
import { connect } from 'react-redux';
import { EMAIL_REGEXP, FQDN_REGEXP, URL_REGEXP } from '~/config';
import './ThemedInput.scss';

interface StateProps {
  wl: any;
}

interface DispatchProps {

}

interface OwnProps {
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  maxLength?: number;
  max?: number;
  min?: number;
  name?: string; 
  onBlur?: any;
  onChange?: any;
  onClick?: any;
  onFocus?: any;
  onKeyDown?: any;
  placeholder?: string;
  required?: boolean;
  tag?: string;
  type?: string;
  validation?: string;
  value?: string;
  defaultValue?: string;
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  focus: boolean;
  valid: boolean | string;
}

const mapStateToProps = (state: any) => ({
  wl: state.users.wl
});

class ThemedInput extends PureComponent<Props, State> {
  state = {
    focus: false,
    valid: true
  }

  handleFocus = () => {
    this.setState({focus: true}, () => {
      if (this.props.onFocus) this.props.onFocus();
    });
  }

  handleBlur = () => {
    this.setState({focus: false}, () => {
      if (this.props.onBlur) this.props.onBlur();
    });
  }

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const {id, value} = e.target;
    if (this.props.validation === "phone") {
      let phone = value.charAt(0) === '+' ? value.slice(1) : value;
      if (/^\d*$/.test(phone)) {
        this.setState({ valid: this.validate(phone) }, () => this.props.onChange(id, phone ?
          /* TODO: Add plus when BE will be ready for that "+" + */ phone : ''));
      }
    } else if (this.props.validation === "zip") {
      this.setState({ valid: this.validate(value) }, () => {
        if (value === '' || (this.state.valid === true && /^\d*$/.test(value))) {
          this.props.onChange(id, value);
        }
      });
    } else if (this.props.validation === "number" || this.props.validation === "port") {
      this.setState({ valid: this.validate(value) }, () => {
        if (this.state.valid === true && Number.isNaN(+value) !== true) {
          this.props.onChange(id, value === '' ? '' : +value);
        }
      });
    } else {
      this.setState({ valid: this.validate(value) }, () => this.props.onChange(id, value));
    }
  }

  validate = (value: string) => {
    if (this.props.required && value === '') {
      return 'This field is required.';
    }
    if (value !== '' && this.props.validation === "email" && !EMAIL_REGEXP.test(value)) {
      return 'Not a valid email addess.';
    }
    if (value !== '' && this.props.validation === "fqdn" && !FQDN_REGEXP.test(value)) {
      return 'Not a valid domain name.'
    }
    if (value !== '' && this.props.validation === "phone" && !/^\d*$/.test(value)) {
      return 'Not a valid phone number.'
    }
    if (value !== '' && (this.props.validation === "number" || this.props.validation === "zip" ) && !/^\d*$/.test(value)) {
      return 'This field is numeric.'
    }
    if (value !== '' && this.props.validation === "port" && !(+value >= 0 && +value <= 65535)) {
      return 'Not a valid port number.';
    }
    if (value !== '' && this.props.validation === "url" && !URL_REGEXP.test(value)) {
      return 'Not a valid URL.'
    }
    if (value !== '' && this.props.validation === "campaign" && !/^C[A-Z0-9]{6}$/.test(value)) {
      return 'Not a valid Campaign ID (should be CXXXXXX).'
    }
    return true;
  }

  render() {
    const brandColor = (this.props.wl && this.props.wl.primary_css_color) ? this.props.wl.primary_css_color : false;
    const Tag = this.props.tag || 'input';
    return <div className="ThemedInput">
      { this.props.label && <label className={classNames({mandatory: this.props.required})} htmlFor={this.props.id}>
        {this.props.label}
      </label> }
      <Tag
        autoFocus={this.props.autoFocus ? true : false}
        autoComplete={this.props.autoComplete}
        className={classNames(this.props.className || 'prt-text-field', {error: this.props.validation && this.state.valid !== true})}
        disabled={this.props.disabled}
        id={this.props.id}
        maxLength={this.props.validation === "phone" ? 16 : this.props.maxLength}
        max={this.props.max}
        min={this.props.min}
        name={this.props.name}
        onClick={this.props.onClick}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onKeyDown={this.props.onKeyDown}
        placeholder={this.props.placeholder}
        required={this.props.required}
        style={brandColor && this.state.focus ? { borderColor: brandColor } : {}}
        type={this.props.type}
        defaultValue={this.props.defaultValue}
        value={this.props.validation === 'phone'
          ? this.props.value
            ? this.props.value.charAt(0) === '+'
              ? this.props.value
              : '+' + this.props.value
            : ''
          : this.props.value || ''}
      />
      { this.props.validation && <sub className={classNames("prt-valid-text", {error: this.state.valid !== true})}>
        {this.state.valid}
      </sub> }
    </div>;
  }
}

export default connect(mapStateToProps)(ThemedInput);
