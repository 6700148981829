import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '~/store/modal';
import { fpn } from '~/utils';
import Table from '../../components/Table/Table';
import { numbersActions, INumberForm } from '../../store/number';
import './Numbers.scss';
import { ThemedButton } from '~/components/common/themed';
import { AxiosResponse } from 'axios';
import Loader from '~/components/common/Loader/Loader';

interface OwnProps { }

interface DispatchProps {
  closeModal: () => void;
  createNumber: (form: INumberForm) => Promise<AxiosResponse>;
  deleteNumber: (phone_number: string) => Promise<AxiosResponse>;
  getCompNumbersWithCPaaSStatus: () => void;
  openModal: (modalId: string, props?: any) => void;
}

interface StateProps {
  isFetching: boolean;
  phones: any;
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {}


const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  createNumber: numbersActions.createNumber,
  deleteNumber: numbersActions.deleteNumber,
  getCompNumbersWithCPaaSStatus: numbersActions.getCompNumbersWithCPaaSStatus,
  openModal: modalActions.openModal,
};

const mapStateToProps = (state: any): StateProps => ({
  isFetching: state.numbers.isFetching,
  phones: state.numbers.phones,
});


class NumberView extends PureComponent<Props, State> {

  componentDidMount() {
    this.props.getCompNumbersWithCPaaSStatus();
  }

  handleEditNumber = (id: number) => {
    this.props.openModal('NUMBER_EDIT_MODAL', { id });
  }

  handleDeleteNumber = (phone_number: string) => {
    this.props.deleteNumber(phone_number).then(this.props.closeModal);
  }

  handleStatusColor = (status: any) => {
    switch (status.toUpperCase()) {
      case 'ACTIVE': {
        return '#007B5F';
      }
      case 'PENDING':
      case 'SMS Routing Pending'.toUpperCase():
      case 'MMS Routing Pending'.toUpperCase(): {
        return '#FFA300';
      }
      case 'SMS Routing Error'.toUpperCase():
      case 'MMS Routing Error'.toUpperCase(): {
        return '#F93822';
      }
    }
    return '#F93822';
  }

  render() {
    if (this.props.isFetching) {
      return <Loader />;
    }
    const data = this.props.phones.map((number: any) => {
      const buttons = [{
        text: 'Manage',
        onClick: () => this.handleEditNumber(number.id),
        classnames: ['link']
      }];
      // If number is not assign to any user we can delete it
      if (!number.users.length) {
        buttons.push({
          classnames: ['link', 'archive'],
          onClick: () => this.props.openModal('CONFIRM_MODAL', {
            title: 'Delete Number',
            body: `Are you sure you want to delete the number <strong>${fpn(number.phone_number)}</strong> ?<br />Auto-replies, suggested replies, lists, and contacts saved for this number will be deleted.`,
            isFetching: this.props.isFetching,
            onYes: this.handleDeleteNumber.bind(this, number.phone_number),
            onNo: this.props.closeModal,
          }),
          text: 'Delete'
        });
      }
      return [
        {
          data: fpn(number.phone_number),
          props: { className: 'text-bold text-underline' }
        },
        number.campaignId || '',
        number.title || 'title',
        number.users.length === 0 ? 'Not assigned' : number.users.map((user: any) => [user.first_name, user.last_name].join(' ')).join(', '),
        {
          data: number.status || 'Not Found',
          props: { style: { color: this.handleStatusColor(number.status || 'Not Found'), fontWeight: 'bold' } }
        },
        number.created_at,
        { buttons }
      ];
    });

    return (
      <React.Fragment>
        <div className="prt-actions">
          <ThemedButton className="btn-primary" onClick={() => this.props.openModal('NUMBER_ADD_MODAL')}>Add Numbers</ThemedButton>
        </div>
        <Table
          className="table-control"
          data={data}
          searchType='phone'
          columns={[
            { name: 'Phone Number', type: 'props' },
            { name: 'Campaign ID', type: 'string' },
            { name: 'Number Label', type: 'string' },
            { name: 'Assigned To', type: 'string' },
            { name: 'Status', type: 'props' },
            { name: 'Added', type: 'date' },
            { name: '', type: 'buttons' },
          ]}
        />
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberView as any);
