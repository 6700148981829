import { createBrowserHistory, Location } from 'history';
import { Dispatch } from 'redux';

// ------------------------------------
// Constants
// ------------------------------------
export const LOCATION_CHANGE = 'LOCATION_CHANGE';

// ------------------------------------
// Actions
// ------------------------------------
export function locationChange(location = '/') {
  return {
    type: LOCATION_CHANGE,
    payload: location
  };
}

// ------------------------------------
// Specialized Action Creator
// ------------------------------------
export const updateLocation = ({dispatch}: {dispatch: Dispatch}) => {
  return (nextLocation) => dispatch(locationChange(nextLocation));
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState: Location<any> = createBrowserHistory().location;
export default function locationReducer (state = initialState, {type, payload}: {type: string, payload?: any}) {
  return type === LOCATION_CHANGE ? payload : state;
}
