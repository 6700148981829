import React from 'react';
import { ICustomer } from '~/store/customer';
import { statuses } from '~/store/user';
import { ThemedButton } from '../common/themed';

const extractContact = (customer: ICustomer, prop: string) => (
  <React.Fragment>
    {['contact', 'email', 'phone'].map((subprop: string, key: number) => {
      const field = `${prop}_${subprop}`;
      return (customer && customer.contact !== null && customer.contact[field]) ? <p key={key}>{customer.contact[field]}</p> : null;
    })}
  </React.Fragment>
);

export default ({customer, setCustomer, openModal}: {customer: ICustomer, setCustomer: any, openModal: any}) => (
  <React.Fragment>
    <div className="prt-actions">
      <ThemedButton className="btn-primary" onClick={() => {
        openModal('CUSTOMER_MODAL', {customerId: customer.id});
      }}>Edit Customer</ThemedButton>
    </div>
    <h2 className="prt-group-header invisible">General Information</h2>
    <div className="prt-info-group">
      <div className="prt-info-item">
        <div className="prt-info-label">Customer ID</div>
        <div className="prt-info-value">{customer.id}</div>
      </div>
      <div className="prt-info-item">
        <div className="prt-info-label">Partner</div>
        <div className="prt-info-value">{customer.reseller_name}</div>
      </div>
      <div className="prt-info-item">
        <div className="prt-info-label">Status</div>
        <div className="prt-info-value"><span className={`prt-status-${statuses[customer.status].toLowerCase()}`}>{statuses[customer.status].toUpperCase()}</span></div>
      </div>
    </div>
    <h2 className="prt-group-header">Contacts</h2>
    <div className="prt-info-group">
      <div className="prt-info-item">
        <div className="prt-info-label">Administrator</div>
        <div className="prt-info-value">
          {extractContact(customer, 'primary')}
        </div>
      </div>
      <div className="prt-info-item">
        <div className="prt-info-label">Primary Contact</div>
        <div className="prt-info-value">
          {extractContact(customer, 'admin')}
        </div>
      </div>
      <div className="prt-info-item">
        <div className="prt-info-label">Billing Contact</div>
        <div className="prt-info-value">
          {extractContact(customer, 'billing')}
        </div>
      </div>
    </div>
    {/* <h2 className="prt-group-header">Agreement</h2>
    <div className="prt-info-group">
      <div className="prt-info-item">
        <div className="prt-info-label">Contract</div>
        <div className="prt-info-value"><a href="#">agreement_01.pdf</a></div>
      </div>
      <div className="prt-info-item">
        <div className="prt-info-label">Contract Signed Date</div>
        <div className="prt-info-value">01/01/2019</div>
      </div>
      <div className="prt-info-item">
        <div className="prt-info-label">Contract Expiration Date</div>
        <div className="prt-info-value">01/01/2020</div>
      </div>
      <div className="prt-info-item">
        <div className="prt-info-label">Brightlink Account Manager</div>
        <div className="prt-info-value">
          Helen Jones <br />
          helen.jones@mail.com <br />
          (404) 342-0029
        </div>
      </div>
      <div className="prt-info-item">
        <div className="prt-info-label">Contract Notes</div>
        <div className="prt-info-value">Agreement Notes</div>
      </div>
    </div> */}
  </React.Fragment>
);