import React from "react";
import Modal from "../AbstractModal/AbstractModal";
import NumberEditForm from "~/components/forms/Number/NumberEdit";
// import { connect } from "react-redux";

export default ({ id }: { id: number }) => (
  <Modal title={`Manage number`} className='NumberModal'>
    <div><NumberEditForm phoneId={id} /></div>
  </Modal>
);
