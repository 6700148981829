import { Location } from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, RouteComponentProps, withRouter } from 'react-router-dom';
import { authActions } from '~/store/auth';
import routes from '../../routes/index';
import PrivateRoute from '../common/PrivateRoute';
import ModalRoot from '../modals';
import './App.scss';
import { AxiosResponse } from 'axios';
import { usersActions } from '~/store/user';

import Loader from '~/components/common/Loader/Loader';
import { FaviconAndTitle } from './FaviconAndTitle/FaviconAndTitle';
import { Branding } from "~/branding";

interface DispatchProps {
  verifyToken: (token: string) => Promise<AxiosResponse<any>>;
  loadDomainCustomization: () => void;
};

interface OwnProps extends RouteComponentProps {

};

interface StateProps {
  location: Location;
  wl: any;
};

type Props = StateProps & DispatchProps & OwnProps;

const mapDispatchToProps = {
  verifyToken: authActions.verifyToken,
  loadDomainCustomization: usersActions.loadDomainCustomization
};

const mapStateToProps = (state: any) => ({
  location: state.location,
  wl: (state.users && state.users.wl) || {}
});

class App extends Component<Props> {
  state = {
    isLoading: true
  }

  isLocalStorageAvailable() {
    var test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }

  componentDidMount() {
    const isDebug = Object.fromEntries(new URLSearchParams(location.search));
    if (isDebug.debug !== "true") {
      window.console.log = () => { };
      window.console.group = () => { };
      window.console.groupEnd = () => { };
      window.console.groupCollapsed = () => { };
      window.console.warn = () => { };
      window.console.error = () => { };
    }

    // Take token from storage if exist
    if (this.isLocalStorageAvailable()) {
      const token = localStorage.getItem('user');
      if (token && window) {
        // Send token to server to verify it
        let pathname = window.location.pathname || '';
        let hash = window.location.hash || '';
        pathname = pathname.split('/')
        pathname.shift();
        this.props.verifyToken(token).then(() => {
          this.props.history.push('/', { pathname, hash });
        });
      }
    }

    this.handleloadCustomisation();
  }

  handleloadCustomisation = async () => {
    await this.props.loadDomainCustomization().finally(() => this.setState({ isLoading: false }));
  }

  render() {
    if (this.state.isLoading) {
      return <div className="prt-center"><Loader /></div>
    }

    return (
      <React.Fragment>
        <FaviconAndTitle wl={this.props.wl} defaultTitle={`${Branding.APP_NAME} ${Branding.ADMIN_APP_NAME}`} />
        <ModalRoot />
        <Switch location={this.props.location}>
          {routes.map((route, i) => route.canAccess ?
            <Route key={i} {...route} /> :
            <PrivateRoute key={i} {...route} />
          )}
        </Switch>

      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
