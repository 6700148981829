import React, { BaseSyntheticEvent, ChangeEvent, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { authActions, IResetPasswordForm } from '~/store/auth';
import Loader from '~/components/common/Loader/Loader';
import { EMAIL_REGEXP } from '~/config';
import { AxiosResponse } from 'axios';
import { ThemedButton, ThemedInput } from '~/components/common/themed';


interface DispatchProps {
    resetPassword: (form: IResetPasswordForm) => Promise<{}>;
}

interface OwnProps extends RouteComponentProps {

}

interface StateProps {
    authenticatedUser: any;
    authenticationError: any[];
    isFetching: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {
    form: IResetPasswordForm
    result: string
};

const mapDispatchToProps = {
    resetPassword: authActions.resetPassword
};

const mapStateToProps = (state: any) => ({
    authenticatedUser: state.auth.authenticatedUser,
    authenticationError: state.auth.errors,
    isFetching: state.auth.isFetching,
});

class ResetPasswordForm extends PureComponent<Props, State> {
    state = {
        form: {
            email: ''
        },
        result: ''
    }

    handleSubmit = (e: BaseSyntheticEvent) => {
        e.preventDefault();
        // Validate form fields here

        // Send form
        if (EMAIL_REGEXP.test(this.state.form.email)) {
            this.props.resetPassword(this.state.form).then((res: AxiosResponse) => this.setState({ result: res.data }));
        }
    }

    handleFieldChange = (id: string, value: string) => {
        this.setState({ form: { ...this.state.form, [id]: value } });
    }

    render() {
        return (this.state.result ? [
            <p style={{ marginTop: '70px' }} key='0'>{this.state.result}</p>,
            <Link to='/login' className='prt-login-form-pass' key='1'>Back to Sign In</Link>] :
            <form onSubmit={this.handleSubmit} key='2'>
                <h1 className='prt-page-title'>Reset Password</h1>
                <p>Password reset link will be sent to the email address below</p>
                <div className='prt-form-field'>
                    <ThemedInput
                        autoFocus
                        id="email"
                        onChange={this.handleFieldChange}
                        placeholder="Enter your email"
                        required
                        type="email"
                        validation="email"
                        value={this.state.form.email}
                    />
                </div>
                <ul className='errors'>
                    {this.props.authenticationError.map((error: string, i: number) => <li key={i}>{error}</li>)}
                </ul>
                <div className='prt-submit'>
                    <ThemedButton
                        type="submit"
                        className='btn-primary full-width'
                        disabled={this.props.isFetching}
                    >{this.props.isFetching ? <Loader /> : 'Send'}</ThemedButton>
                </div>
                <Link to='/login' className='prt-login-form-pass'>Back to Sign In</Link>
            </form>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm));
