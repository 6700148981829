import React, { PureComponent } from 'react';
import { connect } from 'react-redux';


interface StateProps {
  wl: any;
}

interface DispatchProps {

}

interface OwnProps {
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  id?: string;
  maxLength?: number;
  name?: string; 
  onBlur?: any;
  onChange?: any;
  onClick?: any;
  onFocus?: any;
  placeholder?: string;
  value?: string;
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  focus: boolean
}

const mapStateToProps = (state: any) => ({
  wl: state.users.wl
});

class ThemedTextarea extends PureComponent<Props, State> {
  state = {
    focus: false
  }

  handleFocus = () => {
    this.setState({focus: true}, () => {
      if (this.props.onFocus) this.props.onFocus();
    });
  }

  handleBlur = () => {
    this.setState({focus: false}, () => {
      if (this.props.onBlur) this.props.onBlur();
    });
  }

  render() {
    const brandColor = (this.props.wl && this.props.wl.primary_css_color) ? this.props.wl.primary_css_color : false;
    return (
      <textarea
        autoComplete={this.props.autoComplete}
        className={this.props.className || 'input'}
        disabled={this.props.disabled}
        id={this.props.id}
        maxLength={this.props.maxLength}
        name={this.props.name}
        onClick={this.props.onClick}
        onChange={this.props.onChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        placeholder={this.props.placeholder}
        style={brandColor && this.state.focus ? { borderColor: brandColor } : {}}
        value={this.props.value}
      ></textarea>
    );
  }
}

export default connect(mapStateToProps)(ThemedTextarea);