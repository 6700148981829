import Axios, { AxiosError, AxiosResponse } from 'axios';
import { API_URL } from '../config';
import { Dispatch, AnyAction } from 'redux';
import { withAuthorizationHeader } from './index';
import { MODAL_SHOW, MODAL_HIDE } from './modal';

// ------------------------------------
// Constants
// ------------------------------------
const CUSTOMER_CREATE_FAILURE = 'CUSTOMER_CREATE_FAILURE';
const CUSTOMER_CREATE_REQUEST = 'CUSTOMER_CREATE_REQUEST';
const CUSTOMER_CREATE_SUCCESS = 'CUSTOMER_CREATE_SUCCESS';

export const CUSTOMER_DELETE_FAILURE = 'CUSTOMER_DELETE_FAILURE';
export const CUSTOMER_DELETE_REQUEST = 'CUSTOMER_DELETE_REQUEST';
export const CUSTOMER_DELETE_SUCCESS = 'CUSTOMER_DELETE_SUCCESS';

const CUSTOMER_GET_FAILURE = 'CUSTOMER_GET_FAILURE';
const CUSTOMER_GET_REQUEST = 'CUSTOMER_GET_REQUEST';
const CUSTOMER_GET_SUCCESS = 'CUSTOMER_GET_SUCCESS';

const CUSTOMER_LIST_FAILURE = 'CUSTOMER_LIST_FAILURE';
const CUSTOMER_LIST_REQUEST = 'CUSTOMER_LIST_REQUEST';
const CUSTOMER_LIST_SUCCESS = 'CUSTOMER_LIST_SUCCESS';

const CUSTOMER_SET = 'CUSTOMER_SET';

const CUSTOMER_UPDATE_FAILURE = 'CUSTOMER_UPDATE_FAILURE';
const CUSTOMER_UPDATE_REQUEST = 'CUSTOMER_UPDATE_REQUEST';
const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';

// ------------------------------------
// Action Creators
// ------------------------------------
const createCustomerRequest = () => ({ type: CUSTOMER_CREATE_REQUEST });
const createCustomerSuccess = (res: AxiosResponse) => ({ type: CUSTOMER_CREATE_SUCCESS, payload: res.data });
const createCustomerFailure = (err: AxiosError) => ({ type: CUSTOMER_CREATE_FAILURE, payload: err && err.response && err.response.data });

const deleteCustomerRequest = () => ({ type: CUSTOMER_DELETE_REQUEST });
const deleteCustomerSuccess = (userId: string) => ({ type: CUSTOMER_DELETE_SUCCESS, payload: userId });
const deleteCustomerFailure = (err: AxiosError) => ({ type: CUSTOMER_DELETE_FAILURE, payload: err && err.response && err.response.data });

const getCustomerRequest = () => ({ type: CUSTOMER_GET_REQUEST });
const getCustomerSuccess = (res: AxiosResponse) => ({ type: CUSTOMER_GET_SUCCESS, payload: res.data });
const getCustomerFailure = (err: AxiosError) => ({ type: CUSTOMER_GET_FAILURE, payload: err && err.response && err.response.data });

const listCustomersRequest = () => ({ type: CUSTOMER_LIST_REQUEST });
const listCustomersSuccess = (res: AxiosResponse) => ({ type: CUSTOMER_LIST_SUCCESS, payload: res.data });
const listCustomersFailure = (err: AxiosError) => ({ type: CUSTOMER_LIST_FAILURE, payload: err && err.response && err.response.data });

const setActiveCustomer = (customer: ICustomer | null) => ({ type: CUSTOMER_SET, payload: customer });

const updateCustomerRequest = () => ({ type: CUSTOMER_UPDATE_REQUEST });
const updateCustomerSuccess = (res: AxiosResponse) => ({ type: CUSTOMER_UPDATE_SUCCESS, payload: res.data });
const updateCustomerFailure = (err: AxiosError) => ({ type: CUSTOMER_UPDATE_FAILURE, payload: err && err.response && err.response.data });


export interface ICustomerForm {
  name: string;
  email: string;
  status: number;
  user_level: string;
  cpaas_account_id: number | null,
  id?: string;
  contact: {
    admin_contact: string;
    admin_email: string;
    admin_phone: string;
    billing_contact: string;
    billing_email: string;
    billing_phone: string;
    primary_contact: string;
    primary_email: string;
    primary_phone: string;
  };
  parent?: string;
}

export interface ICustomer {
  created: string;
  id: string;
  name: string;
  email: string;
  parent_name: string,
  recent_activity: string;
  reseller_id: string;
  reseller_name: string;
  status: number;
  user_level: string;
  phone_cnt?: number;
  user_cnt?: number;
  cpaas_account_id: number | null;
  contact: {
    admin_contact: string;
    admin_email: string;
    admin_phone: string;
    billing_contact: string;
    billing_email: string;
    billing_phone: string;
    primary_contact: string;
    primary_email: string;
    primary_phone: string;
    [key: string]: any;
  }
}


// ------------------------------------
// Actions
// ------------------------------------
const createCustomer = (form: ICustomerForm) => (dispatch: Dispatch, getState: () => any) => {
  return new Promise(async (resolve, reject) => {
    const { authenticatedUser } = getState().auth;
    if (authenticatedUser && authenticatedUser.token && (authenticatedUser.user_level === 'administrator' || authenticatedUser.user_level === 'reseller')) {
      try {
        dispatch(createCustomerRequest());
        const res = await Axios.post(`${API_URL}/users`, form, withAuthorizationHeader(authenticatedUser.token))
        dispatch(createCustomerSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(createCustomerFailure(err));
        reject(err);
      }
    }
  });
}

const deleteCustomer = (customerId: string) => async (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  if (authenticatedUser && authenticatedUser.token) {
    dispatch(deleteCustomerRequest());
    try {
      await Axios.delete(`${API_URL}/users/${customerId}`, withAuthorizationHeader(authenticatedUser.token))
      dispatch(deleteCustomerSuccess(customerId));
    } catch (err) {
      dispatch(deleteCustomerFailure(err));
    }
  }
}

const getCustomer = (customerId: string) => async (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  if (authenticatedUser && authenticatedUser.token) {
    try {
      dispatch(getCustomerRequest());
      const res = await Axios.get(`${API_URL}/users/${customerId}`, withAuthorizationHeader(authenticatedUser.token))
      dispatch(getCustomerSuccess(res));
    } catch (err) {
      dispatch(getCustomerFailure(err));
    }
  }
}

const listCustomers = (parentId?: string) => (dispatch: Dispatch, getState: () => any) => {
  return new Promise(async (resolve, reject) => {
    const { authenticatedUser } = getState().auth;
    if (authenticatedUser && authenticatedUser.token && (authenticatedUser.user_level === 'administrator' || authenticatedUser.user_level === 'reseller')) {
      try {
        const id = parentId || (authenticatedUser.user_level === 'administrator' ? '' : authenticatedUser.parent);
        dispatch(listCustomersRequest());
        const res = await Axios.get(`${API_URL}/users/customers/${id}`, withAuthorizationHeader(authenticatedUser.token));
        dispatch(listCustomersSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(listCustomersFailure(err));
        reject(err);
      }
    }
  });
}

const setCustomer = (customerId: string) => async (dispatch: Dispatch, getState: () => any) => {
  const { customers } = getState().customers;
  const customer = customers.find((customer: ICustomer) => customer.id === customerId);
  if (!customer) {
    getCustomer(customerId)(dispatch, getState);
  } else {
    dispatch(setActiveCustomer(customer));
  }
}

const clearCustomer = () => async (dispatch: Dispatch) => {
  dispatch(setActiveCustomer(null));
}

/**
 * 
 * @function updateCustomer
 * @param form @{}
 */
const updateCustomer = (customerId: string, form: ICustomerForm) => (dispatch: Dispatch, getState: () => any) => {
  return new Promise(async (resolve, reject) => {
    const { authenticatedUser } = getState().auth;
    if (authenticatedUser && authenticatedUser.token) {
      dispatch(updateCustomerRequest());
      try {
        const res = await Axios.put(`${API_URL}/users/${customerId}`, form, withAuthorizationHeader(authenticatedUser.token));
        dispatch(updateCustomerSuccess(res.data));
        resolve(res);
      } catch (err) {
        dispatch(updateCustomerFailure(err));
        reject(err);
      }
    } else {
      reject();
    }
  });
}

export const customersActions = {
  createCustomer,
  deleteCustomer,
  getCustomer,
  listCustomers,
  setCustomer,
  clearCustomer,
  updateCustomer
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState = {
  errors: [],
  isFetching: false,
  customer: null,
  customers: [],
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case CUSTOMER_CREATE_REQUEST:
    case CUSTOMER_DELETE_REQUEST:
    case CUSTOMER_GET_REQUEST:
    case CUSTOMER_LIST_REQUEST:
    case CUSTOMER_UPDATE_REQUEST:
      return { ...state, isFetching: true };

    case CUSTOMER_CREATE_FAILURE:
    case CUSTOMER_DELETE_FAILURE:
    case CUSTOMER_GET_FAILURE:
    case CUSTOMER_LIST_FAILURE:
    case CUSTOMER_UPDATE_FAILURE:
      return { ...state, isFetching: false, errors: payload.messages };

    case CUSTOMER_CREATE_SUCCESS:
      return { ...state, isFetching: false, customers: [...state.customers, payload.data] };

    case CUSTOMER_GET_SUCCESS:
      return { ...state, isFetching: false, customer: payload.data };

    case CUSTOMER_LIST_SUCCESS:
      return { ...state, isFetching: false, customers: payload.data };

    case CUSTOMER_SET:
      return { ...state, customer: payload ? payload : state.customer };

    case CUSTOMER_UPDATE_SUCCESS:
      const updatedCustomers = Object.assign([], state.customers.map((customer: ICustomer) => customer.id === payload.id ? payload : customer));
      return { ...state, isFetching: false, customers: updatedCustomers, customer: payload };

    case CUSTOMER_DELETE_SUCCESS:
      return { ...state, isFetching: false, customers: state.customers.filter((customer: ICustomer) => customer.id !== payload) };

    case MODAL_SHOW:
    case MODAL_HIDE:
      return { ...state, errors: [] };

    default:
      return state;
  }
}
