import React from "react";
import Modal from "../AbstractModal/AbstractModal";
import UserForm from "~/components/forms/UserForm/UserForm";
import './UserModal.scss';
import { connect } from "react-redux";

function UserModal({ userId }: { userId: string }) {
  return (
    <Modal title={`${userId ? 'Edit' : 'Add'} User`} className='UserModal'>
      <div><UserForm userId={userId} /></div>
    </Modal>
  );
}

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps)(UserModal);