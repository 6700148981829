import React, { BaseSyntheticEvent, ChangeEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import Select from 'react-select';
import Loader from '~/components/common/Loader/Loader';
import { ThemedButton, ThemedCheckbox, ThemedInput } from '~/components/common/themed';
import { modalActions } from '~/store/modal';
import { IPartnerForm, partnersActions } from '~/store/partner';

interface DispatchProps {
  closeModal: () => void;
  createPartner: (form: any) => Promise<any>;
  updatePartner: (partnerId: string, form: any) => Promise<any>;
  listPartner: () => void;
  clearPartner: () => void;
  setPartner: (partnerId: string) => void;
  listCPAASAccount: () => void;
};

interface OwnProps extends RouteComponentProps {
  partnerId?: string;
};

interface StateProps {
  authenticatedUser: any;
  isAuthenticated: boolean;
  isFetching: boolean;
  partner: any;
  partners: any;
  errors: string[];
  cpaasAccounts: any[];
};

type State = {
  isAttachmentsOpen: boolean,
  isContactsOpen: boolean,
  form: IPartnerForm
};

type Props = StateProps & DispatchProps & OwnProps;

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  createPartner: partnersActions.createPartner,
  updatePartner: partnersActions.updatePartner,
  listPartner: partnersActions.listPartners,
  clearPartner: partnersActions.clearPartner,
  setPartner: partnersActions.setPartner,
  listCPAASAccount: partnersActions.listCPAASAccounts
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.auth.authenticatedUser,
  isAuthenticated: state.auth.isAuthenticated,
  isFetching: state.partners.isFetching,
  partner: state.partners.partner,
  errors: state.partners.errors,
  partners: state.partners.partners,
  cpaasAccounts: state.partners.cpaasAccounts
});

class PartnerForm extends PureComponent<Props, State> {

  state = {
    isAttachmentsOpen: false,
    isContactsOpen: true,
    form: {
      name: '',
      email: '',
      status: 0,
      cpaas_account_id: null,
      user_level: 'reseller',
      reseller: {
        address: '',
        city: '',
        state: '',
        zip: ''
      },
      customer_name: '',
      customer_status: '',
      contact: {
        admin_contact: '',
        admin_email: '',
        admin_phone: '',
        billing_contact: '',
        billing_email: '',
        billing_phone: '',
        primary_contact: '',
        primary_email: '',
        primary_phone: '',
      },
      cpaas_detail: {
        cpaas_enabled: false,
        customer_key: "",
        sms_key: "",
        mms_key: ""
      }
    }
  }

  componentDidMount() {
    this.props.listCPAASAccount();
    if (this.props.partnerId) {
      this.props.clearPartner();
      this.props.setPartner(this.props.partnerId);
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.partner && !prevProps.partner) {
      const partner = this.props.partner;
      this.setState({
        form: {
          name: partner.name,
          email: partner.email,
          status: partner.status,
          user_level: partner.user_level,
          cpaas_account_id: partner.cpaas_account_id,
          reseller: partner.reseller || {
            address: '',
            city: '',
            state: '',
            zip: ''
          },
          customer_name: partner.name,
          customer_status: partner.status,
          contact: partner.contact || {
            admin_contact: '',
            admin_email: '',
            admin_phone: '',
            billing_contact: '',
            billing_email: '',
            billing_phone: '',
            primary_contact: '',
            primary_email: '',
            primary_phone: '',
          },
          cpaas_detail: partner.cpaas_detail || {
            cpaas_enabled: false,
            customer_key: "",
            sms_key: "",
            mms_key: ""
          }
        }
      });
    }
  }

  componentWillUnmount = () => {
    this.props.clearPartner();
  }

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    // Validate form fields here

    this.state.form.contact.primary_email = this.state.form.email;

    // Send form
    if (this.props.partnerId) {
      this.props.updatePartner(this.props.partnerId, this.state.form)
        .then(this.props.closeModal)
        .then(this.props.clearPartner)
        .then(this.props.listPartner);
    } else {

      this.props.createPartner(this.state.form)
        .then(this.props.closeModal)
        .then(this.props.clearPartner)
        .then(this.props.listPartner);
    }
  }

  handleFieldChange = (id: string, value: string) => {
    this.setState({ form: { ...this.state.form, [id]: value } });
  }

  handleContactFieldChange = (id: string, value: string) => {
    this.setState({ form: { ...this.state.form, contact: { ...this.state.form.contact, [id]: value } } });
  }

  handleResellerFieldChange = (id: string, value: string) => {
    this.setState({ form: { ...this.state.form, reseller: { ...this.state.form.reseller, [id]: value } } });
  }

  handleSelectChange = (status: any) => {
    this.setState({ form: { ...this.state.form, status: status.value } });
  }

  handleCheckboxChange = (e: ChangeEvent) => {
    e.stopPropagation();
    const { id } = e.target as HTMLInputElement;
    this.setState(prevState => ({
      form: {
        ...this.state.form,
        cpaas_detail: { ...this.state.form.cpaas_detail, [id]: !prevState.form.cpaas_detail[id] }
      }
    }));
  };

  handleCpaasFieldChange = (id: string, value: string) => {
    this.setState({
      form: {
        ...this.state.form,
        cpaas_detail: { ...this.state.form.cpaas_detail, [id]: value }
      }
    });
  };

  handleSelectCPAASAccount = (cpaasAccount: any) => {
    this.setState({
      ...this.state, form: {
        ...this.state.form,
        cpaas_account_id: cpaasAccount.value,
        cpaas_detail: {
          cpaas_enabled: cpaasAccount.value !== null,
          customer_key: cpaasAccount.value !== null ? cpaasAccount.value : "",
          sms_key: cpaasAccount.sms_key ? cpaasAccount.sms_key : '',
          mms_key: cpaasAccount.mms_key ? cpaasAccount.mms_key : ''
        }
      }
    })
  }

  render() {
    const statuses = [{
      value: 1,
      label: 'Active'
    }, {
      value: 2,
      label: 'Inactive'
    }];

    const cpaasAccounts = [{ value: null, label: 'select' }, ...this.props.cpaasAccounts.map((cpaasAccount: any) => ({
      value: cpaasAccount.customerId,
      label: cpaasAccount.customerName,
      sms_key: cpaasAccount.smsConfigId,
      mms_key: cpaasAccount.mmsConfigId
    }))];

    const cpaasAccount = cpaasAccounts.find((cpaasAccount: any) => cpaasAccount.value === this.state.form.cpaas_account_id);


    const statusValue = statuses.find(status => status.value === this.state.form.status);

    return (
      <form onSubmit={this.handleSubmit} autoComplete='off'>
        {this.props.errors.length > 0 && (<div className="prt-form-field">
          <div className="full-width error-msg">
            {this.props.errors.map((msg: string, key: number) => <p key={key}>{msg}</p>)}
          </div>
        </div>)}
        <div className="prt-form-field">
          <div className="prt-form-cell one-third">
            <ThemedInput
              autoFocus
              id="name"
              label="Partner Name"
              onChange={this.handleFieldChange}
              placeholder="Name"
              required
              type="text"
              validation="required"
              value={this.state.form.name}
            />
          </div>
          <div className="prt-form-cell one-third">
            <label htmlFor="partner">CPaaS Account</label>
            <Select
              name="partner"
              className="selector"
              onChange={this.handleSelectCPAASAccount}
              value={cpaasAccount}
              options={cpaasAccounts}
            />
          </div>
          <div className="prt-form-cell one-third">
            <label className="mandatory" htmlFor="status">Status</label>
            <Select
              name="status"
              label="Status"
              className="selector"
              isLoading={!this.props.authenticatedUser}
              isDisabled={this.props.authenticatedUser && this.props.authenticatedUser.user_level !== 'administrator'}
              onChange={this.handleSelectChange}
              value={statusValue}
              options={statuses}
              isSearchable={false}
            />
          </div>
        </div>

        <div className="prt-form-field">
          <div className="prt-form-cell one-fourth">
            <ThemedInput
              id="address"
              label="Address"
              onChange={this.handleResellerFieldChange}
              placeholder="Address"
              // required
              type="text"
              validation="required"
              value={this.state.form.reseller.address}
            />
          </div>
          <div className="prt-form-cell one-fourth">
            <ThemedInput
              id="city"
              label="City"
              onChange={this.handleResellerFieldChange}
              placeholder="City"
              // required
              type="text"
              validation="required"
              value={this.state.form.reseller.city}
            />
          </div>
          <div className="prt-form-cell one-fourth">
            <ThemedInput
              id="state"
              label="State"
              onChange={this.handleResellerFieldChange}
              placeholder="State"
              // required
              type="text"
              validation="required"
              value={this.state.form.reseller.state}
            />
          </div>
          <div className="prt-form-cell one-fourth">
            <ThemedInput
              id="zip"
              label="Zip Code"
              onChange={this.handleResellerFieldChange}
              placeholder="Zip Code"
              // required
              type="text"
              maxLength={5}
              validation="zip"
              value={this.state.form.reseller.zip}
            />
          </div>
        </div>

        <div className='accordeon' onClick={() => this.setState(prevState => ({ isContactsOpen: !prevState.isContactsOpen }))}>
          <span>{this.state.isContactsOpen ? '-' : '+'} Contacts</span>
        </div>
        {this.state.isContactsOpen && <React.Fragment>
          {!this.props.partnerId && <React.Fragment>
            <div className="prt-form-field">
              <div className="prt-form-cell one-third">
                <ThemedInput
                  id="primary_contact"
                  label="Admin"
                  onChange={this.handleContactFieldChange}
                  placeholder="Name"
                  required
                  type="text"
                  validation="required"
                  value={this.state.form.contact.primary_contact}
                />
              </div>
              <div className="prt-form-cell one-third">
                <ThemedInput
                  id="email"
                  label="E-mail"
                  onChange={this.handleFieldChange}
                  placeholder="E-mail"
                  required
                  type="email"
                  validation="email"
                  value={this.state.form.email}
                />
              </div>
              <div className="prt-form-cell one-third">
                <ThemedInput
                  id="primary_phone"
                  label="Phone"
                  onChange={this.handleContactFieldChange}
                  placeholder="Phone"
                  required
                  type="text"
                  validation="phone"
                  value={this.state.form.contact.primary_phone}
                />
              </div>
            </div></React.Fragment>}
          <div className="prt-form-field">
            <div className="prt-form-cell one-third">
              <ThemedInput
                id="admin_contact"
                label="Primary Contact"
                onChange={this.handleContactFieldChange}
                placeholder="Name"
                type="text"
                value={this.state.form.contact.admin_contact}
              />
            </div>
            <div className="prt-form-cell one-third">
              <ThemedInput
                id="admin_email"
                label="E-mail"
                onChange={this.handleContactFieldChange}
                placeholder="E-mail"
                type="email"
                validation="email"
                value={this.state.form.contact.admin_email}
              />
            </div>
            <div className="prt-form-cell one-third">
              <ThemedInput
                id="admin_phone"
                label="Phone"
                onChange={this.handleContactFieldChange}
                placeholder="Phone"
                type="text"
                validation="phone"
                value={this.state.form.contact.admin_phone}
              />
            </div>
          </div>
          <div className="prt-form-field">
            <div className="prt-form-cell one-third">
              <label htmlFor="billing_contact">Billing Contact</label>
              <ThemedInput
                id="billing_contact"
                onChange={this.handleContactFieldChange}
                placeholder="Name"
                type="text"
                value={this.state.form.contact.billing_contact}
              />
            </div>
            <div className="prt-form-cell one-third">
              <ThemedInput
                id="billing_email"
                label="E-mail"
                onChange={this.handleContactFieldChange}
                placeholder="E-mail"
                type="email"
                validation="email"
                value={this.state.form.contact.billing_email}
              />
            </div>
            <div className="prt-form-cell one-third">
              <ThemedInput
                id="billing_phone"
                label="Phone"
                onChange={this.handleContactFieldChange}
                placeholder="Phone"
                type="text"
                validation="phone"
                value={this.state.form.contact.billing_phone}
              />
            </div>
          </div>
        </React.Fragment>}

        <div className="prt-form-field" style={{ paddingLeft: 30 }}>
          <ThemedCheckbox
            checked={this.state.form.cpaas_detail.cpaas_enabled}
            id="cpaas_enabled"
            onChange={this.handleCheckboxChange}
            text="Manual CPaaS Integration"
          />
        </div>

        {this.state.form.cpaas_detail && (
          <React.Fragment>
            {this.state.form.cpaas_detail.cpaas_enabled && (
              <React.Fragment>
                <div className="prt-form-field">
                  <div className="prt-form-cell one-fourth">
                    <ThemedInput
                      id="customer_key"
                      label="Customer Key"
                      onChange={this.handleCpaasFieldChange}
                      placeholder="Customer Key"
                      required
                      type="text"
                      maxLength={6}
                      validation="zip"
                      value={this.state.form.cpaas_detail.customer_key}
                    />
                  </div>

                  <div className="prt-form-cell one-fourth">
                    <ThemedInput
                      id="sms_key"
                      label="SMS Key"
                      onChange={this.handleCpaasFieldChange}
                      placeholder="SMS Key"
                      required
                      type="text"
                      maxLength={6}
                      validation="zip"
                      value={this.state.form.cpaas_detail.sms_key}
                    />
                  </div>

                  <div className="prt-form-cell one-fourth">
                    <ThemedInput
                      id="mms_key"
                      label="MMS Key"
                      onChange={this.handleCpaasFieldChange}
                      placeholder="MMS Key"
                      required
                      type="text"
                      maxLength={6}
                      validation="zip"
                      value={this.state.form.cpaas_detail.mms_key}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {/* <div className='accordeon' onClick={() => this.setState(prevState => ({isAttachmentsOpen: !prevState.isAttachmentsOpen}))}>
          <span>{this.state.isAttachmentsOpen ? '-' : '+'} Attachments</span>
        </div>
        { this.state.isContactsOpen && <React.Fragment>
          <div className="prt-form-field">
            <div className="prt-form-cell one-third">
              <label htmlFor="admin_contact_name">Contract</label>
              <input
                className="prt-text-field"
                id="admin_contact"
                onChange={this.handleAgreementChange}
                placeholder="Name"
                type="text"
                value={this.state.form.contact.admin_contact}
              />
            </div>
            <div className="prt-form-cell one-third">
              <label htmlFor="admin_contact_email">Contract Signed Date</label>
              <input
                className="prt-text-field"
                id="admin_email"
                onChange={this.handleAgreementChange}
                placeholder="E-mail"
                type="email"
                value={this.state.form.contact.admin_email}
              />
            </div>
            <div className="prt-form-cell one-third">
              <label htmlFor="admin_contact_phone">Contract Expire Date</label>
              <input
                className="prt-text-field"
                id="admin_phone"
                onChange={this.handleAgreementChange}
                placeholder="Phone"
                type="text"
                value={this.state.form.contact.admin_phone}
              />
            </div>
          </div>
        </React.Fragment> } */}
        <div className="prt-form-field">
          <ThemedButton
            className="btn-primary"
            disabled={this.props.isFetching
              || this.state.form.status === 0
              // || this.state.form.name === ''
              // || this.state.form.reseller.address === ''
              // || this.state.form.reseller.city === ''
              // || this.state.form.reseller.state === ''
              // || !this.state.form.cpaas_account_id
              // || this.state.form.reseller.zip === undefined
              || ((this.state.form.cpaas_detail && this.state.form.cpaas_detail.cpaas_enabled) && (
                this.state.form.cpaas_detail.customer_key === '' ||
                this.state.form.cpaas_detail.sms_key === '' ||
                this.state.form.cpaas_detail.mms_key === ''))
              || this.state.form.contact.primary_contact === ''
              || this.state.form.contact.primary_phone === ''
              || this.state.form.email === ''
            }
            type="submit">{this.props.isFetching ? <Loader /> : this.props.partnerId ? 'Update' : 'Add'}</ThemedButton>
          <button className="btn-secondary is-cancel" type="button" onClick={this.props.closeModal}>Cancel</button>
        </div>
      </form>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerForm));
