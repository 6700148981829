import React from 'react'; // we need this to make JSX compile
import Favicon from 'react-favicon';
import Helmet from 'react-helmet';

interface StateProps {
    wl: any,
    defaultTitle: String
};

export const FaviconAndTitle = (prop: StateProps) => <aside>
    {prop.wl.favicon && <Favicon url={prop.wl.favicon} />}
    <Helmet>
        {prop.wl.application_name && <title>{prop.wl.application_name} {prop.wl.cc_name}</title>}
        {!prop.wl.application_name && <title>{prop.defaultTitle}</title>}
    </Helmet>
</aside>
