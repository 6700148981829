import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

interface StateProps {
  wl: any;
}
interface DispatchProps {

}
interface OwnProps {
  className: string;
  key: any;
  onClick?: any;
  'data-tab-id': any;
}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: any) => ({
  wl: state.users.wl
});

class ThemedTab extends PureComponent<Props> {
  render() {
    const brandColor = (this.props.wl && this.props.wl.primary_css_color) ? this.props.wl.primary_css_color : false;
    const style = {
      color: brandColor ? brandColor : 'auto',
      borderColor: brandColor ? brandColor : 'auto'
    }
    return (
      <li
        key={this.props.key}
        className={this.props.className}
        style={style}
        onClick={this.props.onClick}
        data-tab-id={this.props['data-tab-id']}>{this.props.children}</li>
    );
  }
}

export default connect(mapStateToProps)(ThemedTab);