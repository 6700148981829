import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import styled from 'styled-components';
import { LightenDarkenColor } from '~/utils';

interface StateProps {
  wl: any;
}
interface DispatchProps {

}
interface OwnProps {
  className: string;
  checked: boolean;
  key: any;
  onClick?: any;
  'data-tab-id': any;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: any) => ({
  wl: state.users.wl
});

class ThemedSwitch extends PureComponent<Props> {
  render() {
    const brandColor = (this.props.wl && this.props.wl.primary_css_color) ? this.props.wl.primary_css_color : false;
    const lightenColor = brandColor ? LightenDarkenColor(brandColor, 30) : false;
    

    let SwitchWrapper = styled.div`
      .checked .react-switch-bg {
        background: ${lightenColor} !important;
      }
    `;

    return (
      <SwitchWrapper>
        {/* <Switch className={this.props.checked ? 'checked' : ''} {...this.props} /> */}
        <Switch
          handleDiameter={30}
          height={40}
          width={100}
          onColor={lightenColor || "#00bbe5"}
          offColor={"#a1a1a1"}
          className={this.props.checked ? "checked" : ""}
          {...this.props}
        />
      </SwitchWrapper>
    );
  }
}

export default connect(mapStateToProps)(ThemedSwitch);