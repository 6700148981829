import React, { PureComponent } from 'react';
import '~/routes/Auth/AuthView.scss';
import Logo from '~/components/common/Logo/Logo';
import ChangePasswordForm from '~/components/forms/ChangePasswordForm/ChangePasswordForm';
import { connect } from 'react-redux';
import { Branding } from "~/branding";

interface StateProps {
  wl: any;
}

interface DispatchProps {

}

interface OwnProps {

}

export type Props = StateProps & DispatchProps & OwnProps;

const mapDispatchToProps = {

}

const mapStateToProps = (state: any) => ({
  wl: state.users.wl
});

class SignUpForm extends PureComponent<Props> {

  render() {
    return (
      <div className="prt-login">
        <div className="prt-login-box">
          <div className="prt-login-form">
            <span className="prt-logo">
              <Logo wl={this.props.wl} />
              <span>{`${Branding.ADMIN_APP_NAME}`}</span>
            </span>
            <ChangePasswordForm />
            {this.props.wl && this.props.wl.show_login_logo_cc && <img
              className="powered"
              src={Branding.POWERED_BY_LOGO}
              alt={`Powered by ${Branding.COMPANY_NAME}`}
            />}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);