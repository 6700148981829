import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

type Props = {
  callback: (e: MouseEvent) => any;
  className: string;
};

type State = {
  isUserMenuOpen: boolean;
};

export default class Outside extends PureComponent<Props, State> {
  wrapperRef: HTMLDivElement | null = null;

  static propTypes = {
    callback: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node: HTMLDivElement) => {
    this.wrapperRef = node;
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target as Node)) {
      this.props.callback(event);
    }
  }

  render() {
    return <div className={this.props.className} ref={this.setWrapperRef}>{this.props.children}</div>;
  }
}
