import React, { PureComponent } from 'react';
import Logo from '~/components/common/Logo/Logo';
import AppName from '~/components/common/AppName/AppName';
import ResetPassword from '~/components/forms/ResetPasswordForm/ResetPasswordForm';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { authActions, ICheckTokenForm } from '~/store/auth';
import { Branding } from "~/branding";

interface StateProps {
  wl: any;
}

interface DispatchProps {
  checkResetPasswordToken: (form: ICheckTokenForm) => Promise<unknown>
}

interface OwnProps extends RouteComponentProps {

}

export type Props = StateProps & DispatchProps & OwnProps;


const mapDispatchToProps = {
  checkResetPasswordToken: authActions.checkResetPasswordToken
}

const mapStateToProps = (state: any) => ({
  wl: state.users.wl
});

class ResetPasswordView extends PureComponent<Props> {

  componentDidMount() {
    const token = this.props.match && this.props.match.params && (this.props.match.params as { token: string }).token;
    if (token) {
      // Check reset token
      this.props.checkResetPasswordToken({ token }).then(() => {
        this.props.history.push('/');
      }).catch();
    }
  }

  render() {
    return (
      <div className="prt-login">
        <div className="prt-login-box">
          <div className="prt-login-form">
            <span className="prt-logo">
              <Logo wl={this.props.wl} />
              <span><AppName wl={this.props.wl} /></span>
            </span>
            <ResetPassword />
            {this.props.wl && this.props.wl.show_login_logo_cc && <img
              className="powered"
              src={Branding.POWERED_BY_LOGO}
              alt={`Powered by ${Branding.COMPANY_NAME}`}
            />}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordView));