type APISIDEBAR = {
    [key: string]: string;
};

export const API_FRONTEND_ACCESS_URL = {
    COMMANDCENTER_API_URL: '/simpleconnect-admin-api-documentation',
    MESSAGEVIEW_API_URL: '/simpleconnect-api-documentation'
}

export const apiTags = {
    TABLE: "TABLE",
    IMG: "IMG",
    JSON: "JSON",
    H3: "H3",
    H4: "H4",
    SECTION: "SECTION",
    P: "P",
    A: "A"
}

export const CommandcenterAPISidebar: APISIDEBAR = {
    Authentication: "Authentication",
    Read: "Read",
    Count: "Count",
    Write: "Write",
}

export const MessageviewAPISidebar: APISIDEBAR = {
    Authentication: "Authentication",
    Read: "Read",
    Write: "Write",
}