export const fpn = (str: string) => {
  const input = str.replace(/\D/g, '');
  const country = input.substring(0, 1);
  const zip = input.substring(1, 4);
  const middle = input.substring(4, 7);
  const last = input.substring(7, 11);
  return `${country} (${zip}) ${middle} ${last}`;
}

export const optionStyles = {
  multiValue: (provided: any, _state: any) => ({
    ...provided,
    backgroundColor: '#7A7A7A',
    // paddingLeft: '6px',
    // paddingTop: '5px',
    // paddingBottom: '5px',
  }),
  multiValueLabel: (provided: any, _state: any) => ({
    ...provided,
    color: '#fff'
  }),
  multiValueRemove: (provided: any, _state: any) => ({
    ...provided,
    backgroundColor: '#5F5F5F',
    color: '#fff',
    opacity: 0.5,
    marginLeft: '6px'
  }),
}

export const userOption = {
  option: (provided: any, _state: any) => ({
    ...provided,
    fontSize: '15px'
  }),
  menu: (provided: any, _state: any) => ({
    ...provided,
    padding: 0,
    margin: 0
  })
}

export const LightenDarkenColor = (col: string, amt: number) => {
  let usePound = false;
  if ( col[0] == "#" ) {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col,16);

  let r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if  (r < 0) r = 0;

  let b = ((num >> 8) & 0x00FF) + amt;
  if (b > 255) b = 255;
  else if  (b < 0) b = 0;

  let g = (num & 0x0000FF) + amt;
  if (g > 255) g = 255;
  else if  (g < 0) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}
