import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';

/**
 * Component that protects route from unauthorized users.
 * @type {Function}
 */
export default ({component: Component, ...rest}: {component: typeof React.Component}) =>
  <Route {...rest} render={props => (
    localStorage.getItem('user')
      ? <Suspense fallback={<div>Loading...</div>}><Component {...props} /></Suspense>
      : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  )} />