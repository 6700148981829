import classnames from 'classnames';
import React, { BaseSyntheticEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import Select from 'react-select';
import Loader from '~/components/common/Loader/Loader';
import { ThemedButton, ThemedInput } from '~/components/common/themed';
import { EMAIL_REGEXP } from '~/config';
import { customersActions, ICustomer, ICustomerForm } from '~/store/customer';
import { modalActions } from '~/store/modal';
import { partnersActions } from '~/store/partner';

interface DispatchProps {
  closeModal: () => void;
  listCustomers: (parentId: string) => void;
  createCustomer: (form: ICustomerForm) => Promise<any>;
  updateCustomer: (customerId: string, form: any) => Promise<any>;
  clearCustomer: () => void;
  listPartners: () => void;
  listCPAASAccount: () => void;
};

interface OwnProps extends RouteComponentProps {
  customerId?: string;
};

interface StateProps {
  customers: ICustomer[];
  customer: ICustomer;
  isFetching: boolean;
  errors: string[];
  authenticatedUser: any;
  partners: any[];
  cpaasAccounts: any[];
};

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  form: ICustomerForm;
  partner: {
    value: string | undefined;
    label: string;
  } | null;
};


const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  createCustomer: customersActions.createCustomer,
  updateCustomer: customersActions.updateCustomer,
  listCustomers: customersActions.listCustomers,
  clearCustomer: customersActions.clearCustomer,
  listPartners: partnersActions.listPartners,
  listCPAASAccount: partnersActions.listCPAASAccounts,
}

const mapStateToProps = (state: any) => ({
  customers: state.customers.customers,
  customer: state.customers.customer,
  isFetching: state.customers.isFetching,
  errors: state.customers.errors,
  authenticatedUser: state.auth.authenticatedUser,
  partners: state.partners.partners,
  cpaasAccounts: state.partners.cpaasAccounts,
})

class CustomerForm extends PureComponent<Props, State> {

  state: State = {
    partner: null,
    form: {
      name: '',
      email: '',
      status: 1,
      user_level: 'customer',
      cpaas_account_id: null,
      contact: {
        admin_contact: '',
        admin_email: '',
        admin_phone: '',
        billing_contact: '',
        billing_email: '',
        billing_phone: '',
        primary_contact: '',
        primary_email: '',
        primary_phone: '',
      }
    }
  }

  componentDidMount = () => {
    this.props.listCPAASAccount();
    if (!this.props.customerId && this.props.authenticatedUser.user_level === 'administrator') {
      this.props.listPartners();
    }
    if (this.props.customerId) {
      // If current user Customer - can only edit owm profile and haven't this.props.customers prop
      if (this.props.authenticatedUser && (this.props.authenticatedUser.user_level === 'customer' || this.props.authenticatedUser.user_level === 'customer_admin')) {
        this.setState({ form: { ...this.props.authenticatedUser } });
      } else {
        if (this.props.customer || (this.props.customers && this.props.customers.length > 0)) {
          // Reseller and administrator have this.props.customers prop => find selected Customer
          const customer = this.props.customer && this.props.customer.id === this.props.customerId ?
            this.props.customer :
            this.props.customers.find((customer: ICustomer) => customer.id === this.props.customerId);
          if (customer) {
            this.setState({ form: customer });
          }
        }
      }
    }
  }

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const parent = (!this.props.customerId && this.props.authenticatedUser.user_level === 'administrator') ? this.state.partner?.value || '' : this.props.location.pathname.split('/')[2];
    // Validate form fields here
    // Send form
    if (this.props.customerId) {
      this.props.updateCustomer(this.props.customerId, this.state.form)
        .then(this.props.closeModal)
        .then(this.props.clearCustomer);
    } else {
      const form: ICustomerForm = {
        ...this.state.form,
        email: this.state.form.contact.primary_email,
        parent
      };
      this.props.createCustomer(form)
        .then(this.props.closeModal)
        .then(this.props.clearCustomer)
        // Because haven't data for Reseller yeat
        .then(() => this.props.listCustomers(parent));
    }
  }

  handleFieldChange = (id: string, value: string) => {
    this.setState({ form: { ...this.state.form, [id]: value } });
  }

  handleContactFieldChange = (id: string, value: string) => {
    this.setState({ form: { ...this.state.form, contact: { ...this.state.form.contact, [id]: value } } });
  }

  handleSelectChange = (status: any) => {
    this.setState({ form: { ...this.state.form, status: status.value } });
  }

  handleSelectPartner = (partner: any) => {
    if (partner) {
      this.setState({ partner });
    }
  }

  handleSelectCPAASAccount = (cpaasAccount: any) => {
    this.setState({
      ...this.state, form: {
        ...this.state.form,
        cpaas_account_id: cpaasAccount.value,
      }
    })
  }

  render() {
    const selectPartner = !this.props.customerId && this.props.authenticatedUser.user_level === 'administrator';
    const partners = selectPartner ? this.props.partners.map((partner: ICustomerForm) => ({
      value: partner.id,
      label: partner.name
    })) : [];
    const statuses = [{
      value: 1,
      label: 'Active'
    }, {
      value: 2,
      label: 'Inactive'
    }];
    const readOnlyStatus = (this.props.authenticatedUser && this.props.authenticatedUser.user_level && ['customer', 'customer_admin'].includes(this.props.authenticatedUser.user_level));
    const statusValue = statuses.find(status => status.value === this.state.form.status);
    const cpaasAccounts = [{ value: null, label: 'select' }, ...this.props.cpaasAccounts.map((cpaasAccount: any) => ({
      value: cpaasAccount.customerId,
      label: cpaasAccount.customerName,
      sms_key: cpaasAccount.smsConfigId,
      mms_key: cpaasAccount.mmsConfigId
    })).filter((cpaasAccount: any) => cpaasAccount.sms_key && cpaasAccount.mms_key)];
    const cpaasAccount = cpaasAccounts.find((cpaasAccount: any) => cpaasAccount.value === this.state.form.cpaas_account_id);
    return (
      <form onSubmit={this.handleSubmit} autoComplete='off'>
        {this.props.errors && this.props.errors.length > 0 && (<div className='prt-form-field'>
          <div className="full-width error-msg">
            {this.props.errors.map((msg: string, key: number) => <p key={key}>{msg}</p>)}
          </div>
        </div>)}
        <div className="prt-form-field">
          {selectPartner && (<div className={classnames('prt-form-cell', { 'two-third': !selectPartner }, { 'one-third': selectPartner })}>
            <label className="mandatory" htmlFor="partner">Partner</label>
            <Select
              name="partner"
              className="selector"
              onChange={this.handleSelectPartner}
              value={this.state.partner}
              options={partners}
            />
          </div>)}
          <div className={classnames('prt-form-cell', { 'two-third': !selectPartner }, { 'one-third': selectPartner })}>
            <ThemedInput
              autoFocus
              id="name"
              label="Customer Name"
              onChange={this.handleFieldChange}
              placeholder="Name"
              required
              type="text"
              validation="required"
              value={this.state.form.name}
            />
          </div>
          <div className="prt-form-cell one-third">
            <label className="mandatory" htmlFor="status">Status</label>
            <Select
              name="status"
              label="Status"
              className="selector"
              onChange={this.handleSelectChange}
              value={statusValue}
              options={statuses}
              isDisabled={readOnlyStatus}
              isSearchable={false}
            />
          </div>
        </div>

        <div className="prt-form-field">
          <div className="prt-form-cell one-third">
            <ThemedInput
              id="primary_contact"
              label="Admin"
              onChange={this.handleContactFieldChange}
              placeholder="Name"
              type="text"
              validation="required"
              value={this.state.form.contact.primary_contact}
            />
          </div>
          <div className="prt-form-cell one-third">
            <ThemedInput
              id="primary_email"
              label="E-mail"
              onChange={this.handleContactFieldChange}
              placeholder="E-mail"
              type="email"
              validation="email"
              value={this.state.form.contact.primary_email}
            />
          </div>
          <div className="prt-form-cell one-third">
            <ThemedInput
              id="primary_phone"
              label="Phone"
              onChange={this.handleContactFieldChange}
              placeholder="Phone"
              type="text"
              validation="phone"
              value={this.state.form.contact.primary_phone}
            />
          </div>
        </div>

        <div className="prt-form-field">
          <div className="prt-form-cell one-third">
            <ThemedInput
              id="admin_contact"
              label="Primary Contact"
              onChange={this.handleContactFieldChange}
              placeholder="Name"
              type="text"
              value={this.state.form.contact.admin_contact}
            />
          </div>
          <div className="prt-form-cell one-third">
            <ThemedInput
              id="admin_email"
              label="E-mail"
              onChange={this.handleContactFieldChange}
              placeholder="E-mail"
              type="email"
              validation="email"
              value={this.state.form.contact.admin_email}
            />
          </div>
          <div className="prt-form-cell one-third">
            <ThemedInput
              id="admin_phone"
              label="Phone"
              onChange={this.handleContactFieldChange}
              placeholder="Phone"
              type="text"
              validation="phone"
              value={this.state.form.contact.admin_phone}
            />
          </div>
        </div>

        <div className="prt-form-field">
          <div className="prt-form-cell one-third">
            <ThemedInput
              id="billing_contact"
              label="Billing Contact"
              onChange={this.handleContactFieldChange}
              placeholder="Name"
              type="text"
              value={this.state.form.contact.billing_contact}
            />
          </div>
          <div className="prt-form-cell one-third">
            <ThemedInput
              id="billing_email"
              label="E-mail"
              onChange={this.handleContactFieldChange}
              placeholder="E-mail"
              type="email"
              validation="email"
              value={this.state.form.contact.billing_email}
            />
          </div>
          <div className="prt-form-cell one-third">
            <ThemedInput
              id="billing_phone"
              label="Phone"
              maxLength={10}
              onChange={this.handleContactFieldChange}
              placeholder="Phone"
              type="text"
              validation="phone"
              value={this.state.form.contact.billing_phone}
            />
          </div>
        </div>

        <div className="prt-form-field">
          <div className="prt-form-cell two-third">
            <label htmlFor="partner">CPaaS Account (overrides Partner setting)</label>
            <Select
              name="partner"
              className="selector"
              onChange={this.handleSelectCPAASAccount}
              value={cpaasAccount}
              options={cpaasAccounts}
            />
          </div>
        </div>

        <div className="prt-form-field">
          <ThemedButton
            className="btn-primary"
            disabled={this.props.isFetching
              || (this.state.form.contact.primary_email && !EMAIL_REGEXP.test(this.state.form.contact.primary_email))
              || !this.state.form.name.length
              || (selectPartner && !this.state.partner)
            }
            type="submit">{this.props.isFetching ? <Loader /> : this.props.customerId ? 'Update' : 'Add'}</ThemedButton>
          <button className="btn-secondary is-cancel" type="button" onClick={this.props.closeModal}>Cancel</button>
        </div>
      </form>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerForm));
