import CompanyLogo from "~/assets/nuso-logo.jpg";
import PoweredByLogo from "~/assets/powered_by_NUSO.png";

export const Branding = {
  COMPANY_LOGO: CompanyLogo,
  POWERED_BY_LOGO: PoweredByLogo,
  COMPANY_NAME: "NUSO",
  APP_NAME: "SimplyConnect",
  ADMIN_APP_NAME: "Admin Center",
  SUPPORT_EMAIL: "support@nuso.cloud",
  SUPPORT_PHONE_NUMBER: "844-438-6876",
  WEBSITE_URL: "nuso.cloud",
};
