import React, { Component } from 'react';
import classnames from 'classnames';
import './DragAndDrop.scss';

namespace DragAndDrop {
  export type State = {
    drag: boolean;
    filename: string;
  }
  export type Props = {
    handleDrop: (files: FileList) => void;
    csvData: any;
    error: string;
  }
}

class DragAndDrop extends Component<DragAndDrop.Props, DragAndDrop.State> {
  state = {
    drag: false,
    filename: ''
  }
  dragCounter: number = 0;
  file: any;
  dropRef = React.createRef<HTMLDivElement>();
  handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }
  handleDragIn = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true })
    }
  }
  handleDragOut = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({ drag: false })
    }
  }
  handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false })
    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      ;
      this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
      this.setState({ filename: e.dataTransfer.files[0].name });
    }
  }
  handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length === 1) {
      this.props.handleDrop(e.target.files);
      this.dragCounter = 0;
      this.setState({ filename: e.target.files[0].name });
    }
  }
  componentDidMount() {
    let div = this.dropRef.current;
    if (div) {
      div.addEventListener('dragenter', this.handleDragIn);
      div.addEventListener('dragleave', this.handleDragOut);
      div.addEventListener('dragover', this.handleDrag);
      div.addEventListener('drop', this.handleDrop);
    }
  }
  componentWillUnmount() {
    const div = this.dropRef.current;
    if (div) {
      div.removeEventListener('dragenter', this.handleDragIn);
      div.removeEventListener('dragleave', this.handleDragOut);
      div.removeEventListener('dragover', this.handleDrag);
      div.removeEventListener('drop', this.handleDrop);
    }
  }
  render() {
    const upload_hint = <div className="upload-hint"><span onClick={() => this.file.click()}>Choose CSV file</span> or just drag & drop file here</div>;
    return (
      <div
        className="dragndrop-container"
        ref={this.dropRef}
      >
        {(this.state.drag || this.props.csvData.length === 0) &&
          <div className={classnames('innerArea', { hover: this.state.drag })}>
            <div className="placeholder">
              {upload_hint}
            </div>
          </div>
        }
        {(!this.state.drag && this.props.csvData.length > 0) && (
          <React.Fragment>
            <div className='innerArea table'>
              {upload_hint}
              {this.state.filename.length > 0 && (<div className="upload-hint text-left">File {this.state.filename}:</div>)}
              <div className="row">
                <div className="cell th">Email</div>
                <div className="cell th">First name</div>
                <div className="cell th">Last name</div>
                <div className="cell th">Title</div>
                <div className="cell th">Phone number</div>
                <div className="cell th">Validate</div>
                <div className="cell th">Reason</div>
              </div>
              {this.props.csvData.slice(0, 8).map((row: any, rid: number) => (
                <div className="row" key={rid}>
                  {['email', 'first_name', 'last_name', 'title', 'phone_number', 'exists', 'reason'].map((key: string, cid: number) => (
                    <div className="cell" key={rid * 4 + cid}>{key === 'notes' ? `${row[key].substring(0, 32)}${row[key].length > 32 ? '…' : ''}` : row[key]}</div>
                  ))}
                </div>
              ))}
              {this.props.csvData.length > 8 && (<div className="upload-hint">… and {this.props.csvData.length - 8} more lines </div>)}
            </div>
          </React.Fragment>
        )}
        <input type="file" ref={ref => { this.file = ref; }} onChange={this.handleChangeFile} />
        {!!this.props.error && <span className="error-msg">{this.props.error}</span>}
      </div>
    )
  }
}

export default DragAndDrop;
