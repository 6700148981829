import { combineReducers, Store } from 'redux';
import { routerReducer } from 'react-router-redux';
import administrators from './administrator';
import assets from './assets';
import auth from './auth';
import locationReducer from './location';
import customers from './customer';
import customize from './customize';
import partners from './partner';
import numbers from './number';
import orders from './orders';
import modal from './modal';
import users from './user';

export const makeRootReducer = (asyncReducers?: {}) => {
  return combineReducers({
    administrators,
    assets,
    auth,
    customers,
    customize,
    location: locationReducer,
    modal,
    numbers,
    partners,
    router: routerReducer,
    users,
    orders,
    ...asyncReducers
  })
}

export const injectReducer = (store: Store, {key, reducer}: {key: string, reducer: any}) => {
  if (Object.hasOwnProperty.call(store['asyncReducers'], key)) return;
  store['asyncReducers'][key] = reducer;
  store.replaceReducer(makeRootReducer(store['asyncReducers']));
}

export default makeRootReducer;