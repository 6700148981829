import './ThemedRadio.scss';
import React, { ChangeEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import { LightenDarkenColor } from '~/utils';

interface StateProps {
  wl: any;
}

interface OwnProps {
  checked: boolean;
  id: string;
  name: string;
  onChange: (e: ChangeEvent) => void;
  text: string;
  value: string;
}

type Props = StateProps & OwnProps;

const mapStateToProps = (state: any) => ({
  wl: state.users.wl
});

class ThemedRadio extends PureComponent<Props> {
  render() {
    const brandColor = (this.props.wl && this.props.wl.primary_css_color) ? LightenDarkenColor(this.props.wl.primary_css_color, 30) : '#004B87';
    return (
      <label className='ThemedRadio' htmlFor={this.props.id}>
        <input
          checked={!!this.props.checked}
          id={this.props.id}
          name={this.props.name}
          onChange={this.props.onChange}
          type='radio'
          value={this.props.value}
        />
        <span style={brandColor && !!this.props.checked ? { color: brandColor } : {}}>{this.props.text}</span>
      </label>
    );
  }
}

export default connect(mapStateToProps)(ThemedRadio);