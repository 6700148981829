import React, { Component, BaseSyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '~/store/modal';
import './AbstractModal.scss'

const mapDispatchToProps = {
  closeModal: modalActions.closeModal
};

const mapStateToProps = (state: any) => ({
  
});

namespace AbstractModal {
  interface StateProps {
    closeModal: () => void
  }
  
  interface OwnProps {
    children?: any
    className?: string
    onBack?: () => void
    showBack?: boolean,
    dontShowClose?: boolean,
    title: string | JSX.Element
  }
  export type Props = StateProps & OwnProps;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export default class AbstractModal extends Component<AbstractModal.Props> {

  handleClose = (e: BaseSyntheticEvent) => {
    if (e.currentTarget === e.target || e.target.className === 'icon-close') {
      e.stopPropagation();
      this.props.closeModal();
    }
  }

  render() {
    return (
      <div className={this.props.className ? 'modal ' + this.props.className : 'modal'}>
        <div className='inner'>
          <header>
            { this.props.showBack && <button type='button' className='back-button' onClick={this.props.onBack}>
              <i className="icon-back"></i>
            </button> }
            { this.props.dontShowClose !== true && <button type='button' className='close-button' onClick={this.handleClose}>
              <i className="icon-close"></i>
            </button> }
            { this.props.title && <h1>{this.props.title}</h1> }
          </header>
          {this.props.children}
        </div>
      </div>
    )
  }
}