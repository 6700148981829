import Axios, { AxiosError, AxiosResponse } from 'axios';
import { API_URL } from '../config';
import { Dispatch, AnyAction } from 'redux';
import { withAuthorizationHeader } from './index';
import { MODAL_HIDE } from './modal';

// ------------------------------------
// Constants
// ------------------------------------
const USERS_CREATE_USER_FAILURE = 'USERS_CREATE_USER_FAILURE';
const USERS_CREATE_USER_REQUEST = 'USERS_CREATE_USER_REQUEST';
const USERS_CREATE_USER_SUCCESS = 'USERS_CREATE_USER_SUCCESS';

export const USERS_DELETE_USER_FAILURE = 'USERS_DELETE_USER_FAILURE';
export const USERS_DELETE_USER_REQUEST = 'USERS_DELETE_USER_REQUEST';
export const USERS_DELETE_USER_SUCCESS = 'USERS_DELETE_USER_SUCCESS';

const USERS_GET_USER_FAILURE = 'USERS_GET_USER_FAILURE';
const USERS_GET_USER_REQUEST = 'USERS_GET_USER_REQUEST';
const USERS_GET_USER_SUCCESS = 'USERS_GET_USER_SUCCESS';

const USERS_LIST_USERS_FAILURE = 'USERS_LIST_USERS_FAILURE';
const USERS_LIST_USERS_REQUEST = 'USERS_LIST_USERS_REQUEST';
const USERS_LIST_USERS_SUCCESS = 'USERS_LIST_USERS_SUCCESS';

const USERS_SET_USER = 'USERS_SET_USER';

const USERS_UPDATE_USER_FAILURE = 'USERS_UPDATE_USER_FAILURE';
const USERS_UPDATE_USER_REQUEST = 'USERS_UPDATE_USER_REQUEST';
const USERS_UPDATE_USER_SUCCESS = 'USERS_UPDATE_USER_SUCCESS';

const LOAD_CUSTOMISATION_REQUEST = 'LOAD_CUSTOMISATION_REQUEST';
const LOAD_CUSTOMISATION_FAILURE = 'LOAD_CUSTOMISATION_FAILURE';
const LOAD_CUSTOMISATION_SUCCESS = 'LOAD_CUSTOMISATION_SUCCESS';

const USERS_IMPORT_FAILURE = 'USERS_IMPORT_FAILURE';
const USERS_IMPORT_REQUEST = 'USERS_IMPORT_REQUEST';
const USERS_IMPORT_SUCCESS = 'USERS_IMPORT_SUCCESS';

export const USER_INVITED = 0;
export const USER_IS_ACTIVE = 1;
export const USER_SUSPENDED = 2;
export const USER_DELETED = 3;


// ------------------------------------
// Action Creators
// ------------------------------------
const createUserRequest = () => ({ type: USERS_CREATE_USER_REQUEST });
const createUserSuccess = (res: AxiosResponse) => ({ type: USERS_CREATE_USER_SUCCESS, payload: res.data });
const createUserFailure = (err: AxiosError) => ({ type: USERS_CREATE_USER_FAILURE, payload: err && err.response && err.response.data && err.response.data.messages });

const deleteUserRequest = () => ({ type: USERS_DELETE_USER_REQUEST });
const deleteUserSuccess = (userId: number) => ({ type: USERS_DELETE_USER_SUCCESS, payload: userId });
const deleteUserFailure = (err: AxiosError) => ({ type: USERS_DELETE_USER_FAILURE, payload: err && err.response && err.response.data });

const listUsersRequest = () => ({ type: USERS_LIST_USERS_REQUEST });
const listUsersSuccess = (res: AxiosResponse) => ({ type: USERS_LIST_USERS_SUCCESS, payload: res.data });
const listUsersFailure = (err: AxiosError) => ({ type: USERS_LIST_USERS_FAILURE, payload: err && err.response && err.response.data });

const setActiveUser = (user: any) => ({ type: USERS_SET_USER, payload: user });

const updateUserRequest = () => ({ type: USERS_UPDATE_USER_REQUEST });
const updateUserSuccess = (res: AxiosResponse) => ({ type: USERS_UPDATE_USER_SUCCESS, payload: res.data.data.data });
const updateUserFailure = (err: AxiosError) => ({ type: USERS_UPDATE_USER_FAILURE, payload: err && err.response && err.response.data && err.response.data.messages });

const loadDomainCustomizationRequest = () => ({ type: LOAD_CUSTOMISATION_REQUEST })
const loadDomainCustomizationSuccess = (res: AxiosResponse) => ({ type: LOAD_CUSTOMISATION_SUCCESS, payload: res.data })
const loadDomainCustomizationFailure = () => ({ type: LOAD_CUSTOMISATION_FAILURE })

const importUsersRequest = () => ({ type: USERS_IMPORT_REQUEST });
const importUsersSuccess = (res: AxiosResponse) => ({ type: USERS_IMPORT_SUCCESS, payload: res.data });
const importUsersFailure = (err: AxiosError) => ({ type: USERS_IMPORT_FAILURE, payload: err && err.response && err.response.data && err.response.data.messages });


// ------------------------------------
// Actions
// ------------------------------------
const createUser = (form: any) => (dispatch: Dispatch, getState: () => any) => {
  dispatch(createUserRequest());
  return new Promise(async (resolve, reject) => {
    const { token } = getState().auth.authenticatedUser;
    const { id } = getState().customers.customer;
    if (token && id) {
      try {
        const res = await Axios.post(`${API_URL}/messageview/users/${id}`, form, withAuthorizationHeader(token));
        dispatch(createUserSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(createUserFailure(err));
        reject(err);
      }
    }
  });
}

const deleteUser = (userId: number) => (dispatch: Dispatch, getState: () => any) => {
  dispatch(deleteUserRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = getState().auth.authenticatedUser;
      await Axios.delete(`${API_URL}/messageview/users/${userId}`, withAuthorizationHeader(token))
      dispatch(deleteUserSuccess(userId));
      resolve(userId);
    } catch (err) {
      dispatch(deleteUserFailure(err));
      reject(err);
    }
  });
}

const getUser = (userId: string) => (dispatch: Dispatch, getState: () => any) => {

}

const listUsers = () => async (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  if (authenticatedUser && authenticatedUser.token && getState().customers.customer.id) {
    try {
      dispatch(listUsersRequest());
      const res = await Axios.get(`${API_URL}/messageview/users/${getState().customers.customer.id}`, withAuthorizationHeader(authenticatedUser.token))
      dispatch(listUsersSuccess(res));
    } catch (err) {
      dispatch(listUsersFailure(err));
    }
  } else {
    console.log('[ERROR] unknown current customer! customer id from State: ', getState().customers.customer.id);
  }
}


const setUser = (userId: string) => async (dispatch: Dispatch, getState: () => any) => {
  const { users } = getState().users;
  const user = users.find((user: any) => user.id === userId);
  if (!user) {
    getUser(userId)(dispatch, getState);
  } else {
    dispatch(setActiveUser(user));
  }
}

/**
 *
 * @function updateUser
 * @param form @{}
 */
const updateUser = (form: any) => (dispatch: Dispatch, getState: () => any) => {
  dispatch(updateUserRequest());
  return new Promise(async (resolve, reject) => {
    const { token } = getState().auth.authenticatedUser;
    if (token) {
      try {
        const id = form.id;
        delete form.id;
        const res = await Axios.put(`${API_URL}/messageview/users/${id}`, form, withAuthorizationHeader(token));
        dispatch(updateUserSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(updateUserFailure(err));
        reject(err);
      }
    } else {
      reject();
    }
  });
}

/**
 * @function loadDomainCustomization
 * @param form @{}
 */
const loadDomainCustomization = () => (dispatch: Dispatch) => {
  dispatch(loadDomainCustomizationRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const origin = window.location.hostname;
      const res = await Axios.post(`${API_URL}/wl`, { origin });
      dispatch(loadDomainCustomizationSuccess(res));
      resolve(res);
    } catch (err) {
      dispatch(loadDomainCustomizationFailure());
      reject(err);
    }
  });
}

const importUsers = (csvData: string[]) => (dispatch: Dispatch, getState: () => any): Promise<any> => {
  dispatch(importUsersRequest());
  return new Promise(async (resolve, reject) => {
    const { token } = getState().auth.authenticatedUser;
    const { id } = getState().customers.customer;
    if (token && id) {
      try {
        const res = await Axios.post(`${API_URL}/messageview/import/users/${id}`, csvData, withAuthorizationHeader(token));
        dispatch(importUsersSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(importUsersFailure(err));
        reject(err);
      }
    }
  });
}

export const statuses: {
  [key: number]: string
} = {
  [USER_DELETED]: 'Closed',
  [USER_INVITED]: 'Invited',
  [USER_IS_ACTIVE]: 'Active',
  [USER_SUSPENDED]: 'Inactive'
};


export const usersActions = {
  createUser,
  importUsers,
  deleteUser,
  getUser,
  listUsers,
  setUser,
  updateUser,
  loadDomainCustomization
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState = {
  isAuthenticated: false,
  authenticatedUser: null,
  errors: [],
  info: '',
  isFetching: false,
  user: null,
  users: [],
  wl: false
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case USERS_CREATE_USER_REQUEST:
    case USERS_DELETE_USER_REQUEST:
    case USERS_GET_USER_REQUEST:
    case USERS_LIST_USERS_REQUEST:
    case USERS_UPDATE_USER_REQUEST:
    case USERS_IMPORT_REQUEST:
      return { ...state, isFetching: true, errors: [], info: '' };

    case USERS_CREATE_USER_FAILURE:
    case USERS_DELETE_USER_FAILURE:
    case USERS_GET_USER_FAILURE:
    case USERS_LIST_USERS_FAILURE:
    case USERS_UPDATE_USER_FAILURE:
    case USERS_IMPORT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: payload.length !== undefined
          ? payload
          : payload && payload.messages || []
      };

    case USERS_CREATE_USER_SUCCESS:
      return { ...state, isFetching: false, users: [payload.data, ...state.users], errors: [], info: 'User created' };

    case USERS_IMPORT_SUCCESS:
      return { ...state, isFetching: false, users: payload.data.users.concat(state.users), errors: [], info: payload.data.message };

    case USERS_GET_USER_SUCCESS:
      return { ...state, isFetching: false, user: payload, errors: [] };

    case USERS_SET_USER:
      return { ...state, isFetching: false, user: payload, errors: [] };

    case USERS_LIST_USERS_SUCCESS:
      return { ...state, isFetching: false, users: payload.data, errors: [] };

    case USERS_UPDATE_USER_SUCCESS:
      const updatedUsers = Object.assign([], state.users.map(user => user.id === payload.id ? payload : user));
      return { ...state, isFetching: false, users: updatedUsers, user: null };

    case USERS_DELETE_USER_SUCCESS:
      return { ...state, isFetching: false, users: state.users.filter((user: any) => user.id !== payload) };

    case LOAD_CUSTOMISATION_SUCCESS:
      return { ...state, wl: payload.data };

    case LOAD_CUSTOMISATION_FAILURE:
      return { ...state, wl: false };

    case MODAL_HIDE:
      return { ...state, errors: [], info: '' };

    default:
      return state;
  }
}
