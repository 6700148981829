import { AxiosResponse } from 'axios';
import timezones from 'google-timezones-json';
import React, { BaseSyntheticEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Loader from '~/components/common/Loader/Loader';
import { modalActions } from '~/store/modal';
import { numbersActions } from '~/store/number';
import { usersActions } from '~/store/user';
import { optionStyles, userOption } from '~/utils';
import { ThemedInput, ThemedButton } from '~/components/common/themed';

interface SelectItem {
  value: string;
  label: string;
}

interface State {
  isUserSelectOpen: boolean;
  errors: string[];
  form: {
    id: number;
    phone_number: string;
    title: string;
    users: any[];
    timezone: SelectItem;
    rateOfSend: number;
  }
}

interface StateProps {
  authenticatedUser: any;
  isFetching: boolean;
  users: any[];
  phones: any;
}

interface OwnProps {
  phoneId: number;
}

interface DispatchProps {
  closeModal: () => void;
  editNumber: (phone: any) => Promise<AxiosResponse>;
  getCompNumbers: () => void;
  listUsers: () => void;
}

type Props = DispatchProps & OwnProps & StateProps;

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  editNumber: numbersActions.editNumber,
  getCompNumbers: numbersActions.getCompNumbersWithCPaaSStatus,
  listUsers: usersActions.listUsers
};

const mapStateToProps = (state: any) => ({
  phones: state.numbers.phones,
  authenticatedUser: state.auth.authenticatedUser,
  isFetching: state.users.isFetching,
  users: state.users.users,
});

class NumberEditForm extends PureComponent<Props, State> {
  userSelect: Select | null = null;
  state = {
    isUserSelectOpen: false,
    errors: [],
    form: {
      id: 0,
      phone_number: '',
      title: '',
      users: [],
      timezone: { value: '', label: '' },
      rateOfSend: '1'
    }
  }

  componentDidMount = () => {
    this.props.listUsers();
    const form: any = { ...this.props.phones.find((phone: any) => (phone.id === this.props.phoneId)) };
    console.log('form ', form);
    form.users = form.users.map((user: any) => ({ value: user.id, label: [user.first_name, user.last_name].join(' ') }));
    form.timezone = { value: form.timezone, label: form.timezone }
    if (form) {
      this.setState({ form });
    }
  }

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const { id, title, timezone: { value }, users, rateOfSend } = this.state.form;
    const payload = {
      id,
      title,
      rateOfSend,
      timezone: value,
      users: users.map((user: any) => (user.value))
    };
    this.props.editNumber(payload).then(() => {
      this.props.closeModal();
      this.props.getCompNumbers();
    });
  }

  handleFieldChange = (id: string, value: string) => {
    this.setState({ form: { ...this.state.form, [id]: value } });
  }

  handleSelectChangeUsers = (value: any) => {
    if (!value) {
      value = [];
    }
    if (value) {
      const default_options: any[] = [];
      const user_to_set_id: string[] = value.map((opt: SelectItem) => (opt.value));
      this.props.users
        .filter((user: any) => (user.hasOwnProperty('default_phone_id') && user.default_phone_id === this.props.phoneId))
        .forEach((user: any) => {
          if (!user_to_set_id.includes(user.id)) {
            default_options.push(this.makeSelectItem(user));
          }
        });
      const errors = default_options.length > 0 ? ['You can not delete the user’s default phone number.'] : [];
      this.setState({
        isUserSelectOpen: false,
        errors,
        form: {
          ...this.state.form,
          users: [...default_options, ...value]
        }
      });
    }
  }

  handleSelectChangeTimezone = (value: any) => {
    if (value) {
      this.setState({
        isUserSelectOpen: false,
        form: {
          ...this.state.form,
          timezone: value
        }
      });
    }
  }

  handleSelectFocus = () => {
    if (this.userSelect) {
      return !this.state.isUserSelectOpen
        ? this.userSelect.select.focus()
        : this.userSelect.select.blur();
    }
  }

  handleFocus = () => this.setState({ isUserSelectOpen: true });

  makeSelectItem(user: any) {
    return {
      value: user.id,
      label: `${user.first_name} ${user.last_name}`,
      email: user.email
    };
  }

  render() {
    const options: SelectItem[] = this.props.users
      .filter((user: any) => (user.hasOwnProperty('default_phone_id')))
      .map((user: any) => (this.makeSelectItem(user)));

    const timezoneOptions = () => {
      const res = [{ value: '', label: 'Select time zone' }];
      for (var value in timezones) {
        if (timezones.hasOwnProperty(value)) {
          res.push({ value, label: value });
        }
      }
      return res;
    };

    const Option = (props: any) => {
      const {
        innerProps: { ref, ...restInnerProps },
      } = props;
      return (
        <div className="select-option" ref={ref} {...restInnerProps}>
          <label>{props.label}</label>
          {props.data.email}
        </div>
      );
    };

    return (
      <form onSubmit={this.handleSubmit} autoComplete='off'>
        <div className="prt-form-field">
          <div className="prt-form-cell one-half">
            <ThemedInput
              id="phone_number"
              label="Phone Number"
              onChange={() => { }}
              type="text"
              value={this.state.form.phone_number}
            />
          </div>
          <div className="prt-form-cell one-half">
            <ThemedInput
              id="title"
              label="Number Label"
              onChange={this.handleFieldChange}
              required
              type="text"
              validation="required"
              value={this.state.form.title}
            />
          </div>
        </div>
        <div className="prt-form-field">
          <div className="prt-form-cell full-width">
            <label>Assigned to</label>
            <Select
              className='selector user-selector'
              isMulti
              id="users"
              name="users"
              value={this.state.form.users}
              onChange={this.handleSelectChangeUsers}
              components={{ Option }}
              // ref={ref => this.numberSelect = ref}
              // menuIsOpen={true}
              // onFocus={this.handleFocus}
              styles={{ ...optionStyles, ...userOption }}
              options={options}
            />
          </div>
          <div className="prt-form-cell full-width">
            <label>Time Zone</label>
            <Select
              className='selector'
              id="timezone"
              name="timezone"
              value={this.state.form.timezone}
              onChange={this.handleSelectChangeTimezone}
              // ref={ref => this.numberSelect = ref}
              // menuIsOpen={this.state.isUserSelectOpen}
              // onFocus={this.handleFocus}
              styles={optionStyles}
              options={timezoneOptions()}
            />
          </div>
          {this.props.authenticatedUser.user_level === 'administrator' && (<div className="prt-form-cell one-half">
            <ThemedInput
              id="rateOfSend"
              label="Rate of Send"
              onChange={this.handleFieldChange}
              type="text"
              validation="number"
              value={this.state.form.rateOfSend}
            />
          </div>)}
        </div>
        {this.state.errors.length > 0 && (<div className='prt-form-field'>
          <div className="full-width error-msg">
            {this.state.errors.map((msg: string, key: number) => <p key={key}>{msg}</p>)}
          </div>
        </div>)}
        <div className="prt-form-field"></div>
        <div className="prt-form-field">
          <ThemedButton
            className="btn-primary"
            disabled={this.props.isFetching}
            type="submit">{this.props.isFetching ? <Loader /> : 'Update'}</ThemedButton>
          <button className="btn-secondary is-cancel" type="button" onClick={this.props.closeModal}>Cancel</button>
        </div>
      </form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberEditForm as any);