import Axios, { AxiosError, AxiosResponse } from 'axios';
import { API_URL } from '../config';
import { Dispatch, AnyAction } from 'redux';
import { withAuthorizationHeader } from './index';
import { MODAL_HIDE } from './modal';

// ------------------------------------
// Constants
// ------------------------------------

interface User {
  first_name: string;
  last_name: string;
}

interface Did {
  did: [number];
  npa: string;
}

const sortDID = (dids: Did[]): Did[] => ((dids.length > 0) ? dids.sort((a: Did, b: Did) => (a.did[0] > b.did[0] ? 1 : -1)) : dids);
const sortDIDNumber = (dids: any[]): any[] => ((dids.length > 0) ? dids.sort((a: any, b: any) => (a.didNumber > b.didNumber ? 1 : -1)) : dids);

export interface INumberForm {
  id: number;
  phone_number: string;
  title: string;
  timezone?: string;
  created_at: string;
  users: User[]
}

export interface ICPAASInvertoryNumberForm {
  serachNumber: String;
  customerId: String;
  excludedNumbers: any[] | null;
}

export interface CpaasNumberDetail {
  a2p: boolean;
  account: string;
  accountId: number;
  country: string;
  customer: string;
  didNumber: number;
  didType: string;
  // e911Capable: boolean;
  // e911Enabled: boolean;
  // editableSupplier: boolean;
  mmsCapable: boolean;
  mmsEnabled: boolean;
  // portinCapable: boolean;
  // portinEnabled: boolean;
  // reputationAndCnamPolicies: {
  //   alarmingReputationScore: any;
  //   cnamDippingEnabled: boolean;
  //   reputationActionForAlarming: any;
  //   reputationActionForWarning: any;
  //   reputationScoreEnable: boolean;
  //   warningReputationScore: any;
  // };
  smsCapable: boolean;
  smsEnabled: boolean;
  trunkId: string;
}

export interface NumberDetail {
  country: string;
  did: number[];
  didType: string;
  // lata: string;
  // npa: string;
  // nxx: string;
  // quantity: number;
  // rateCenter: string;
  // snEnd: string;
  // snStart: string;
  state: string;
}

const NUMBER_ALLOCATE_FAILURE = 'NUMBER_ALLOCATE_FAILURE';
const NUMBER_ALLOCATE_REQUEST = 'NUMBER_ALLOCATE_REQUEST';
const NUMBER_ALLOCATE_SUCCESS = 'NUMBER_ALLOCATE_SUCCESS';

const NUMBER_CREATE_FAILURE = 'NUMBER_CREATE_FAILURE';
const NUMBER_CREATE_REQUEST = 'NUMBER_CREATE_REQUEST';
const NUMBER_CREATE_SUCCESS = 'NUMBER_CREATE_SUCCESS';

export const NUMBER_DELETE_FAILURE = 'NUMBER_DELETE_FAILURE';
export const NUMBER_DELETE_REQUEST = 'NUMBER_DELETE_REQUEST';
export const NUMBER_DELETE_SUCCESS = 'NUMBER_DELETE_SUCCESS';

const NUMBER_EDIT_FAILURE = 'NUMBER_EDIT_FAILURE';
const NUMBER_EDIT_REQUEST = 'NUMBER_EDIT_REQUEST';
const NUMBER_EDIT_SUCCESS = 'NUMBER_EDIT_SUCCESS';

const NUMBER_LIST_FAILURE = 'NUMBER_LIST_FAILURE';
const NUMBER_LIST_REQUEST = 'NUMBER_LIST_REQUEST';
const NUMBER_LIST_SUCCESS = 'NUMBER_LIST_SUCCESS';

const COMPANY_NUMBER_LIST_FAILURE = 'COMPANY_NUMBER_LIST_FAILURE';
const COMPANY_NUMBER_LIST_REQUEST = 'COMPANY_NUMBER_LIST_REQUEST';
const COMPANY_NUMBER_LIST_SUCCESS = 'COMPANY_NUMBER_LIST_SUCCESS';

const NUMBER_LIST_CLEAR = 'NUMBER_LIST_CLEAR';

const CPAAS_INVENTORY_NUMBER_LIST_FAILURE = 'CPAAS_INVENTORY_NUMBER_LIST_FAILURE';
const CPAAS_INVENTORY_NUMBER_LIST_REQUEST = 'CPAAS_INVENTORY_NUMBER_LIST_REQUEST';
const CPAAS_INVENTORY_NUMBER_LIST_SUCCESS = 'CPAAS_INVENTORY_NUMBER_LIST_SUCCESS';

// ------------------------------------
// Action Creators
// ------------------------------------

const allocateNumberRequest = () => ({ type: NUMBER_ALLOCATE_REQUEST });
const allocateNumberSuccess = (res: AxiosResponse) => ({ type: NUMBER_ALLOCATE_SUCCESS, payload: res.data });
const allocateNumberFailure = (err: AxiosError) => ({ type: NUMBER_ALLOCATE_FAILURE, payload: err && err.response && err.response.data });

const createNumberRequest = () => ({ type: NUMBER_CREATE_REQUEST });
const createNumberSuccess = (res: AxiosResponse) => ({ type: NUMBER_CREATE_SUCCESS, payload: res.data && res.data.tns });
const createNumberFailure = (err: AxiosError) => ({ type: NUMBER_CREATE_FAILURE, payload: err && err.response && err.response.data });

const deleteNumberRequest = () => ({ type: NUMBER_DELETE_REQUEST });
const deleteNumberSuccess = (phone_number: string) => ({ type: NUMBER_DELETE_SUCCESS, payload: phone_number });
const deleteNumberFailure = (err: AxiosError) => ({ type: NUMBER_DELETE_FAILURE, payload: err && err.response && err.response.data });

const numberEditRequest = () => ({ type: NUMBER_EDIT_REQUEST });
const numberEditSuccess = (res: AxiosResponse) => ({ type: NUMBER_EDIT_SUCCESS, payload: res.data && res.data.data && res.data.data.data });
const numberEditFailure = (err: AxiosError) => ({ type: NUMBER_EDIT_FAILURE, payload: err && err.response && err.response.data });

const getNumberRequest = () => ({ type: NUMBER_LIST_REQUEST });
const getNumberSuccess = (res: AxiosResponse) => ({ type: NUMBER_LIST_SUCCESS, payload: res.data && res.data.tns });
const getNumberFailure = (err: AxiosError) => ({ type: NUMBER_LIST_FAILURE, payload: err && err.response && err.response.data });

const getCompNumberRequest = () => ({ type: COMPANY_NUMBER_LIST_REQUEST });
const getCompNumberSuccess = (res: AxiosResponse) => ({ type: COMPANY_NUMBER_LIST_SUCCESS, payload: res.data && res.data.data && res.data.data.data });
const getCompNumberFailure = (err: AxiosError) => ({ type: COMPANY_NUMBER_LIST_FAILURE, payload: err && err.response && err.response.data });

const getCPAASInventoryNumberRequest = () => ({ type: CPAAS_INVENTORY_NUMBER_LIST_REQUEST });
const getCPAASInventoryNumberSuccess = (res: AxiosResponse) => ({ type: CPAAS_INVENTORY_NUMBER_LIST_SUCCESS, payload: res.data });
const getCPAASInventoryNumberFailure = (err: AxiosError) => ({ type: CPAAS_INVENTORY_NUMBER_LIST_FAILURE, payload: err && err.response && err.response.data });

const numbersClearRequest = () => ({ type: NUMBER_LIST_CLEAR });


// ------------------------------------
// Actions
// ------------------------------------

const getNumbers = (areaCode: number, didType: String, country: String) => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  return new Promise(async (resolve, reject) => {
    if (authenticatedUser && authenticatedUser.token) {
      try {
        dispatch(getNumberRequest());
        const res = await Axios.post(`${API_URL}/phones/${areaCode}`, { didType, country }, withAuthorizationHeader(authenticatedUser.token))
        dispatch(getNumberSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(getNumberFailure(err));
        reject(err);
      }
    }
  });
}

const getCPAASInventoryNumbers = (number: ICPAASInvertoryNumberForm) => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  return new Promise(async (resolve, reject) => {
    if (authenticatedUser && authenticatedUser.token) {
      try {
        dispatch(getCPAASInventoryNumberRequest());
        const res = await Axios.post(`${API_URL}/cpaas/numbers`, number, withAuthorizationHeader(authenticatedUser.token));
        dispatch(getCPAASInventoryNumberSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(getCPAASInventoryNumberFailure(err));
        reject(err);
      }
    }
  });
}

const createNumber = (number: INumberForm) => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  return new Promise(async (resolve, reject) => {
    if (authenticatedUser && authenticatedUser.token) {
      try {
        dispatch(createNumberRequest());
        const res = await Axios.post(`${API_URL}/phones`, number, withAuthorizationHeader(authenticatedUser.token))
        dispatch(createNumberSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(createNumberFailure(err));
        reject(err);
      }
    }
  });
}

const deleteNumber = (phone_number: string) => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  return new Promise(async (resolve, reject) => {
    if (authenticatedUser && authenticatedUser.token) {
      try {
        dispatch(deleteNumberRequest());
        const res = await Axios.delete(`${API_URL}/phones/${phone_number}`, withAuthorizationHeader(authenticatedUser.token))
        dispatch(deleteNumberSuccess(phone_number));
        resolve(res);
      } catch (err) {
        dispatch(deleteNumberFailure(err));
        reject(err);
      }
    }
  });
}

const allocateNumber = (phone_number: string, owner: string, businessTollfreeInfo?: any, campaignId?: string) => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  return new Promise(async (resolve, reject) => {
    if (authenticatedUser && authenticatedUser.token) {
      try {
        dispatch(allocateNumberRequest());
        // const res = await Axios.post(`${API_URL}/phones/allocate/${phone_number}`, {}, withAuthorizationHeader(authenticatedUser.token))
        const res = await Axios.post(`${API_URL}/sms_order_create`, { phone_number, owner, campaignId, smscOrderInfoDTO: businessTollfreeInfo }, withAuthorizationHeader(authenticatedUser.token));
        dispatch(allocateNumberSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(allocateNumberFailure(err));
        reject(err);
      }
    }
  });
}

const getCompNumbers = (customerId?: string) => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  return new Promise(async (resolve, reject) => {
    if (authenticatedUser && authenticatedUser.token) {
      try {
        dispatch(getCompNumberRequest());
        const path = `/${customerId ? customerId : getState().customers.customer.id}`;
        const res = await Axios.get(`${API_URL}/phones/company${path}`, withAuthorizationHeader(authenticatedUser.token))
        dispatch(getCompNumberSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(getCompNumberFailure(err));
        reject(err);
      }
    }
  });
}

const getCompNumbersWithCPaaSStatus = (customerId?: string) => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  return new Promise(async (resolve, reject) => {
    if (authenticatedUser && authenticatedUser.token) {
      try {
        dispatch(getCompNumberRequest());
        const path = `/status/${customerId ? customerId : getState().customers.customer.id}`;
        const res = await Axios.get(`${API_URL}/phones-numbers/company${path}`, withAuthorizationHeader(authenticatedUser.token))
        dispatch(getCompNumberSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(getCompNumberFailure(err));
        reject(err);
      }
    }
  });
}

const editNumber = (phone: any) => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  return new Promise(async (resolve, reject) => {
    if (authenticatedUser && authenticatedUser.token) {
      try {
        dispatch(numberEditRequest());
        const res = await Axios.put(`${API_URL}/phone`, phone, withAuthorizationHeader(authenticatedUser.token))
        dispatch(numberEditSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(numberEditFailure(err));
        reject(err);
      }
    }
  });
}

const numbersClear = () => (dispatch: Dispatch) => {
  dispatch(numbersClearRequest());
}

export const numbersActions = {
  allocateNumber,
  createNumber,
  deleteNumber,
  editNumber,
  getCompNumbers,
  getNumbers,
  numbersClear,
  getCPAASInventoryNumbers,
  getCompNumbersWithCPaaSStatus,
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState = {
  errors: [],
  isFetching: false,
  number: null,
  numbers: [],
  phones: [],
  isCPAASInventoryNumberFetching: false,
  cpaasInventoryNumbers: [],
  cpaasInventoryErrors: []
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case CPAAS_INVENTORY_NUMBER_LIST_REQUEST:
      return { ...state, isCPAASInventoryNumberFetching: true, isFetching: false, errors: [], cpaasInventoryErrors: [], cpaasInventoryNumbers: [] }

    case CPAAS_INVENTORY_NUMBER_LIST_SUCCESS:
      return { ...state, isCPAASInventoryNumberFetching: false, cpaasInventoryNumbers: sortDIDNumber(payload) }

    case CPAAS_INVENTORY_NUMBER_LIST_FAILURE:
      return { ...state, isCPAASInventoryNumberFetching: false, cpaasInventoryErrors: payload.messages ? payload.messages : payload }

    case NUMBER_DELETE_REQUEST:
      return { ...state, isFetching: true };

    case NUMBER_LIST_REQUEST:
    case NUMBER_ALLOCATE_REQUEST:
      return { ...state, isFetching: true, isCPAASInventoryNumberFetching: false, errors: [], numbers: [], cpaasInventoryErrors: [] };

    case NUMBER_LIST_FAILURE:
    case NUMBER_CREATE_FAILURE:
    case NUMBER_DELETE_FAILURE:
    case NUMBER_ALLOCATE_FAILURE:
      return { ...state, isFetching: false, errors: payload.messages ? payload.messages : payload };

    case NUMBER_DELETE_SUCCESS:
      return { ...state, isFetching: false, phones: state.phones.filter(number => number.phone_number !== payload) };

    case NUMBER_LIST_SUCCESS:
      return { ...state, isFetching: false, numbers: sortDID(payload) };

    case COMPANY_NUMBER_LIST_REQUEST:
      return { ...state, isFetching: true, numbers: [], cpaasInventoryErrors: [], cpaasInventoryNumbers: [] };

    case COMPANY_NUMBER_LIST_FAILURE:
      return { ...state, isFetching: false, errors: payload, phones: [], cpaasInventoryErrors: [], cpaasInventoryNumbers: [] };

    case COMPANY_NUMBER_LIST_SUCCESS:
      return { ...state, isFetching: false, phones: payload };

    case NUMBER_LIST_CLEAR:
      return { ...state, numbers: [], cpaasInventoryNumbers: [] };

    case MODAL_HIDE:
      return { ...state, errors: [], cpaasInventoryErrors: [] };

    default:
      return state;
  }
}
