import { Dispatch, AnyAction } from "redux";
import {
  ADMINISTRATOR_DELETE_FAILURE,
  ADMINISTRATOR_DELETE_REQUEST,
  ADMINISTRATOR_DELETE_SUCCESS
} from './administrator';
import {
  CUSTOMER_DELETE_FAILURE,
  CUSTOMER_DELETE_REQUEST,
  CUSTOMER_DELETE_SUCCESS
} from './customer';
import {
  NUMBER_DELETE_FAILURE,
  NUMBER_DELETE_REQUEST,
  NUMBER_DELETE_SUCCESS
} from "./number";
import {
  USERS_DELETE_USER_FAILURE,
  USERS_DELETE_USER_REQUEST,
  USERS_DELETE_USER_SUCCESS
} from './user';

// ------------------------------------
// Constants
// ------------------------------------
export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_HIDE = 'MODAL_HIDE';
export const MODAL_SET_STATE = 'MODAL_SET_STATE';

// ------------------------------------
// Actions
// ------------------------------------
const openModal = (modalType: string, modalProps = {}) => (dispatch: Dispatch) => {
  dispatch({ type: MODAL_SHOW, payload: { modalType, modalProps } });
};

const closeModal = () => (dispatch: Dispatch) => {
  dispatch({ type: MODAL_HIDE });
};

const setModalState = (payload: any) => (dispatch: Dispatch) => {
  dispatch({ type: MODAL_SET_STATE, payload });
};

export const modalActions = {
  closeModal,
  openModal,
  setModalState
};

// ------------------------------------
// State
// ------------------------------------
const initialState = {
  isFetching: false,
  errors: [],
  modalType: '',
  modalProps: {},
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, {type, payload}: AnyAction) => {
  switch (type) {
    case MODAL_SHOW:
      return {
        ...state,
        isFetching: false,
        errors: [],
        modalType: payload.modalType,
        modalProps: payload.modalProps
      };

    case MODAL_HIDE:
      return initialState;

    case MODAL_SET_STATE:
      return { ...state, initProps: payload };

    case ADMINISTRATOR_DELETE_REQUEST:
    case CUSTOMER_DELETE_REQUEST:
    case NUMBER_DELETE_REQUEST:
    case USERS_DELETE_USER_REQUEST:
      return { ...state, isFetching: true };

    case ADMINISTRATOR_DELETE_SUCCESS:
    case CUSTOMER_DELETE_SUCCESS:
    case NUMBER_DELETE_SUCCESS:
    case USERS_DELETE_USER_SUCCESS:
      return { ...state, isFetching: false };

    case ADMINISTRATOR_DELETE_FAILURE:
    case CUSTOMER_DELETE_FAILURE:
    case NUMBER_DELETE_FAILURE:
    case USERS_DELETE_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: payload && payload.messages ? payload.messages : []
      };

    default:
      return state;
  }
}
