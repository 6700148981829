import { AxiosResponse } from 'axios';
import React, { FormEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import Loader from '~/components/common/Loader/Loader';
import { ThemedButton, ThemedInput } from '~/components/common/themed';
import { EMAIL_REGEXP } from '~/config';
import { administratorsActions, IAdministrator, IAdministratorForm } from '~/store/administrator';
import { modalActions } from '~/store/modal';
import { RouteComponentProps, matchPath, withRouter } from 'react-router';

interface State {
  form: IAdministratorForm;
};

interface StateProps {
  authenticatedUser: any;
  isFetching: boolean;
  administrators: IAdministrator[];
  errors: string[];
}

interface OwnProps extends RouteComponentProps {
  administratorId: string;
}

interface DispatchProps {
  closeModal: () => void;
  createAdmin: (form: any) => Promise<AxiosResponse>;
  updateAdmin: (form: any) => Promise<AxiosResponse>;
}

type Props = DispatchProps & OwnProps & StateProps;

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  createAdmin: administratorsActions.createAdmin,
  updateAdmin: administratorsActions.updateAdmin,
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.auth.authenticatedUser,
  isFetching: state.administrators.isFetching,
  administrators: state.administrators.administrators,
  errors: state.administrators.errors,
});

type RouterMatch = {
  params: {
    parent: string;
  }
} | null;

class AdministratorForm extends PureComponent<Props, State> {

  state = {
    form: {
      email: '',
      name: '',
      last_name: '',
      user_level: 'reseller'
    }
  }

  componentDidMount() {
    this.loadAdministrator();
  }

  loadAdministrator = () => {
    if (this.props.administratorId) {
      const administrator = this.props.administrators.find((administrator: IAdministrator) => administrator.id === this.props.administratorId);
      if (administrator) {
        this.setState({
          form: {
            id: administrator.id,
            email: administrator.email,
            name: administrator.first_name ? administrator.first_name : administrator.name,
            last_name: administrator.last_name,
            user_level: 'reseller'
          }
        });
      }
    }
  }

  handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const matchAdministrator: RouterMatch = matchPath(this.props.history.location.pathname, {
      path: '/administrators',
      exact: true,
      strict: false
    });

    const matchPartner: RouterMatch = matchPath(this.props.history.location.pathname, {
      path: '/partners/:parent',
      exact: true,
      strict: false
    });

    const matchCustomer: RouterMatch = matchPath(this.props.history.location.pathname, {
      path: '/customers/:parent',
      exact: true,
      strict: false
    });

    let parent: string = '';
    let user_level: string = '';
    if (matchAdministrator) {
      // Form add L1 or L2
      parent = this.props.authenticatedUser.user_level === 'reseller' ?
        this.props.authenticatedUser.parent :
        this.props.authenticatedUser.id;
      user_level = this.props.authenticatedUser.user_level === 'administrator' ?
        'administrator' :
        'reseller';
    } else if (matchPartner) {
      parent = matchPartner.params.parent;
      user_level = 'reseller';
    } else if (matchCustomer) {
      parent = this.props.authenticatedUser.user_level === 'customer_admin' ? this.props.authenticatedUser.parent : matchCustomer.params.parent;
      user_level = 'customer_admin';
    }
    const { administratorId }: { administratorId: string } = this.props;
    const form: any = {
      ...this.state.form,
      ...!administratorId && { parent },
      ...!administratorId && { user_level },
      send_email_invite: true,
    };
    if (administratorId) {
      delete form.user_level;
    }
    if (matchCustomer) {
      form['first_name'] = form.name;
      delete form.name;
    }
    if (this.props.administratorId) {
      this.props.updateAdmin(form).then(this.props.closeModal);
    } else {
      this.props.createAdmin(form).then(this.props.closeModal);
    }
  }

  handleFieldChange = (id: string, value: string) => this.setState({ form: { ...this.state.form, [id]: value } });

  render() {
    return (
      <form className="AdministratorForm" onSubmit={this.handleSubmit} autoComplete='off'>
        <div className="prt-form-field">
          <div className="prt-form-cell one-third">
            <ThemedInput
              autoFocus
              disabled={!!this.props.administratorId}
              id="email"
              label="E-mail"
              onChange={this.handleFieldChange}
              placeholder="E-mail"
              required
              type="email"
              validation="email"
              value={this.state.form.email}
            />
          </div>
          <div className="prt-form-cell one-third">
            <ThemedInput
              id="name"
              label="First Name"
              onChange={this.handleFieldChange}
              placeholder="First Name"
              required
              type="text"
              validation="required"
              value={this.state.form.name}
            />
          </div>
          <div className="prt-form-cell one-third">
            <ThemedInput
              id="last_name"
              label="Last Name"
              onChange={this.handleFieldChange}
              placeholder="Last Name"
              required
              type="text"
              validation="required"
              value={this.state.form.last_name}
            />
          </div>
        </div>

        <ul className="error-msg">
          {this.props.errors && this.props.errors.map((error: string, i: number) => <li key={i}>{error}</li>)}
        </ul>

        <div className="prt-form-field">
          <ThemedButton
            className="btn-primary"
            disabled={this.props.isFetching
              || !EMAIL_REGEXP.test(this.state.form.email)
              || !this.state.form.name.length
              || !this.state.form.last_name.length}
            type="submit">{this.props.isFetching ? <Loader /> : this.props.administratorId ? 'Update' : 'Add'}</ThemedButton>
          <button className="btn-secondary is-cancel" type="button" onClick={this.props.closeModal}>Cancel</button>
        </div>
      </form>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdministratorForm)
);