import React, { BaseSyntheticEvent, ChangeEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import { authActions, ILoginForm } from '../../../store/auth';
import Loader from '~/components/common/Loader/Loader';
import { ThemedCheckbox, ThemedInput. ThemedButton  } from '~/components/common/themed';

interface DispatchProps {
    loginUser: (form: ILoginForm) => Promise<{}>;
}

interface OwnProps extends RouteComponentProps {

}

interface StateProps {
    authenticatedUser: any;
    authenticationError: any[];
    isFetching: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {
    form: ILoginForm
};

const mapDispatchToProps = {
    loginUser: authActions.loginUser
};

const mapStateToProps = (state: any) => ({
    authenticatedUser: state.auth.authenticatedUser,
    authenticationError: state.auth.errors,
    isFetching: state.auth.isFetching,
});

class LoginForm extends PureComponent<Props, State> {
    state = {
        form: {
            email: '',
            password: '',
            remember_me: false
        }
    }

    handleSubmit = (e: BaseSyntheticEvent) => {
        e.preventDefault();
        // Validate form fields here

        // Send form
        this.props.loginUser(this.state.form).then(() => this.props.history.push('/'));
    }

    handleFieldChange = (id: string, value: string) => {
        this.setState({ form: { ...this.state.form, [id]: value } });
    }

    handleCheckboxChange = (e: ChangeEvent) => {
        e.stopPropagation();
        const {id, checked} = e.target as HTMLInputElement;
        this.setState({ form: { ...this.state.form, [id]: checked } });
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <h1 className='prt-page-title'>Sign In</h1>
                <div className='prt-form-field'>
                    <ThemedInput
                        autoFocus
                        id="email"
                        onChange={this.handleFieldChange}
                        placeholder="Email"
                        required
                        type="email"
                        validation="email"
                        value={this.state.form.email}
                    />
                </div>
                <div className='prt-form-field'>
                    <ThemedInput
                        id='password'
                        onChange={this.handleFieldChange}
                        placeholder='Password'
                        required
                        type='password'
                        value={this.state.form.password}
                    />
                </div>
                <ul className='errors'>
                    {this.props.authenticationError.map((error: string, i: number) => <li key={i}>{error}</li>)}
                </ul>
                <div className='prt-form-field'>
                    <ThemedCheckbox 
                        checked={this.state.form.remember_me}
                        id='remember_me'
                        onChange={this.handleCheckboxChange}
                        text='Keep me logged in'
                    />
                </div>
                <div className='prt-submit'>
                    <ThemedButton
                        className='btn-primary full-width'
                        disabled={this.props.isFetching}
                        type='submit'>{this.props.isFetching ? <Loader /> : 'Sign In' }</ThemedButton>
                </div>
                <Link to='/reset' className='prt-login-form-pass'>Forgot Password?</Link>
            </form>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
