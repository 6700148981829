import { AxiosResponse } from 'axios';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { assetsActions } from '~/store/assets';
import classNames from 'classnames';

import { usersActions } from '~/store/user';
import { modalActions } from '~/store/modal';
import { ThemedButton } from '../common/themed';
import Table from '../Table/Table';

import './CompanyUsers.scss';

interface StateProps {
  authenticatedUser: any;
  customers: any[];
  isAuthenticated?: boolean;
  users: any[];
}

interface DispatchProps {
  closeModal: () => void;
  deleteUser: (userId: string) => Promise<AxiosResponse>;
  exportItems: (opt: any) => Promise<any>;
  listUsers: () => void;
  setUser: (userId: string) => void;
  openModal: (modalId: string, modalProps?: any) => void;
}

interface OwnProps extends RouteComponentProps {

}

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  isMenuOpen: Boolean;
}


const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  deleteUser: usersActions.deleteUser,
  exportItems: assetsActions.exportItems,
  listUsers: usersActions.listUsers,
  setUser: usersActions.setUser,
  openModal: modalActions.openModal,
}

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.auth.authenticatedUser,
  customers: state.customers,
  isAuthenticated: state.auth.isAuthenticated,
  users: state.users.users,
});


class CompanyUsers extends PureComponent<Props, State> {
  state = {
    isMenuOpen: false
  }

  componentDidMount() {
    this.props.listUsers();
  }

  componentDidUpdate(prevProps: Props) {
    if(this.props.isAuthenticated && prevProps.isAuthenticated !== this.props.isAuthenticated) {
      this.props.listUsers();
    }
  }

  handleEditUser = (id: string) => {
    this.props.setUser(id);
    this.props.openModal('USER_MODAL', {userId: id});
  }

  toggleMenu = () => this.setState((prevState) => ({ isMenuOpen: !prevState.isMenuOpen }));

  openUserModal = () => {
    this.props.openModal('USER_MODAL');
    this.toggleMenu();
  }

  openImportModal = () => {
    this.props.openModal('CSV_IMPORT_MODAL');
    this.toggleMenu();
  }

  exportItems = (e: MouseEvent) => {
    e.preventDefault();
    const opt = {
      url: `csv/user?uid=${this.props.customers.customer.id}`
    };
    this.props.exportItems(opt);  
  }

  deleteUser = (id: string) => {
    this.props.deleteUser(id)
      .then(this.props.listUsers)
      .then(this.props.closeModal);
  }


  render() {
    const usersData = this.props.users.map(user => ([
      (user.first_name || user.name) + (user.last_name && ` ${user.last_name}`),
      user.title,
      user.phones ? user.phones.length : '-',
      // Separate MessageView user from additional customer
      new Date(user.created),
      user.must_change_password ? 'No' : 'Yes',
      {
        buttons: [{
          classnames: ['link'],
          onClick: () => {
            this.handleEditUser(user.id)
          },
          text: 'Edit',
        }, {
          classnames: ['link', 'archive'],
          onClick: () => this.props.openModal('CONFIRM_MODAL', {
            title: 'Delete User',
            body: `Are you sure you want to delete user <strong>${(user.first_name || user.name) + (user.last_name && ` ${user.last_name}`)}</strong> ?<br/>Auto-replies, suggested replies, lists, and contacts saved for this number will be deleted.`,
            onYes: this.deleteUser.bind(this, user.id),
            onNo: this.props.closeModal,
          }),
          text: 'Delete'
        }]
      }
    ]));

    return <React.Fragment>
      <div className={classNames('prt-companyUsers', { open: this.state.isMenuOpen })}>
        <div className="prt-actions">
          <ThemedButton className="btn-primary" onClick={this.toggleMenu}>
            <span className="add-button">Add User</span>
          </ThemedButton>
        </div>
        <div className='prt-account-dropdown'>
          <span onClick={this.openImportModal}>Import Users</span>
          <span onClick={this.openUserModal}>Add manually</span>
        </div>
      </div>
      <Table
        data={usersData}
        columns={[
          { name: 'Name', type: 'string' },
          { name: 'Title', type: 'string' },
          { name: 'Assigned Numbers', type: 'string' },
          { name: 'Added', type: 'date' },
          { name: 'Confirmed', type: 'string' },
          { name: '', type: 'buttons' },
        ]}
      />
      <div className="prt-table-footer">
              {/*<span className="prt-table-filter">
                Show: <a href="#" className="btn-secondary is-select">10</a>
              </span>
              <div className="prt-pagination">
                <a href="#" className="prt-pagination-prev disabled">Previous</a>
                <a href="#" className="current">1</a>
                <a href="#">2</a>
                <a href="#">3</a>
                <a href="#">4</a>
                <a href="#">5</a>
                <a href="#" className="prt-pagination-next">Next</a>
            </div>*/}
              <a href="#" onClick={this.exportItems} className="btn-secondary">Export</a>
            </div>
    </React.Fragment>;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyUsers));
