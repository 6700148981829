import { History } from 'history';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import './Footer.scss';
import { Branding } from "~/branding";

namespace Footer {
  interface StateProps {
    isAuthenticated?: boolean;
    wl: any;
  }
  interface DispatchProps {
    logoutUser: (cb: Function) => void
  }
  interface OwnProps extends RouteComponentProps { }
  export type Props = StateProps & DispatchProps & OwnProps;
}

import { usersActions } from '~/store/user';

const mapDispatchToProps = {
  logoutUser: usersActions.logoutUser
}

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
  wl: state.users.wl
});

class Footer extends PureComponent<Footer.Props> {

  HandleLink = ({ children, conditionVariable }: any) => {
    if (!this.props.wl) {
      return children;
    }
    return this.props.wl && this.props.wl[conditionVariable] && children;
  }

  render() {
    return (
      <footer className="prt-footer">
        <div className="prt-wrapper">
          <div>{this.props.wl && this.props.wl.show_logo_cc && <img
            className="powered"
            src={Branding.POWERED_BY_LOGO}
            alt={`Powered by ${Branding.COMPANY_NAME}`}
          />}</div>
          <div className="prt-legal">
            <this.HandleLink conditionVariable="show_copywrite"><span>&copy; {`2019 ${Branding.COMPANY_NAME}`}</span></this.HandleLink>
            <this.HandleLink conditionVariable="show_terms_of_use_link"><a href="#">Terms of Use</a></this.HandleLink>
            <this.HandleLink conditionVariable="show_privacy_policy_link"><a href={(this.props.wl && this.props.wl.privacy_policy_link_url) ? this.props.wl.privacy_policy_link_url : "#"} target={(this.props.wl && this.props.wl.privacy_policy_link_url) ? "_blank" : ""}>Privacy Policy</a></this.HandleLink>
            <this.HandleLink conditionVariable="show_help_link"><a href="#">Help</a></this.HandleLink>
          </div>
        </div>
      </footer>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));