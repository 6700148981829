import classnames from 'classnames';
import React, { PureComponent, BaseSyntheticEvent } from "react";
import Modal from "../AbstractModal/AbstractModal";
import './CustomizeModal.scss';
import CustomizeForm from '~/components/forms/CustomizeForm/CustomizeForm';
import { ThemedTab } from '~/components/common/themed';

type State = {
  isPreview: boolean
}

export default class CustomizeModal extends PureComponent<State> {
  state = {
    activeTab: 'customize',
    isPreview: false
  }

  changeTitle = () => this.setState((prevState: State) => ({isPreview: !prevState.isPreview}))

  changeTab = (tab: string) => {
    this.setState({activeTab: tab})
  }

  nextTab = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    this.changeTab('email_setup');
  }

  backTab = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    this.changeTab('customize');
  }

  render() {
    return (
      <Modal title={<>
          <span>{this.state.isPreview ? 'Application Preview' : 'Customize'}</span>
          { !this.state.isPreview && <ul className="prt-tabs">
            <ThemedTab
              className={classnames({ active: this.state.activeTab === 'customize' })}
              onClick={this.backTab}
            >
              <a href="#">Appearance</a>
            </ThemedTab>
            <ThemedTab
              className={classnames({ active: this.state.activeTab === 'email_setup' })}
              onClick={this.nextTab}
            >
              <a href="#">Email Setup</a>
            </ThemedTab>
          </ul> }
        </>} className={classnames('CustomizeModal', { previewMode: this.state.isPreview })}>
        <div>
          <CustomizeForm
            activeTab={this.state.activeTab}
            backTab={this.backTab}
            nextTab={this.nextTab}
            previewCallback={this.changeTitle}
          />
        </div>
      </Modal>
    );
  }
}