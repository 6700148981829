import { History } from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import AppName from '~/components/common/AppName/AppName';
import Loader from '~/components/common/Loader/Loader';
import Logo from '~/components/common/Logo/Logo';
import LoginForm from '~/components/forms/LoginForm/LoginForm';
import { usersActions } from '~/store/user';
import './AuthView.scss';
import { Branding } from "~/branding";

interface StateProps {
  wl: any;
  history: History;
};

interface DispatchProps extends RouteComponentProps {
  loadDomainCustomization: () => Promise<any>;
};

const mapStateToProps = (state: any) => ({
  wl: state.users.wl
});

const mapDispatchToProps = {
  loadDomainCustomization: usersActions.loadDomainCustomization
};

type Props = StateProps & DispatchProps;

class AuthView extends Component<Props> {
  render() {
    if (Object.keys(this.props.wl).length === 0 && this.props.wl.constructor === Object) {
      return <div className="centered"><Loader /></div>
    }
    return (
      <div className="prt-login">
        <div className="prt-login-box">
          <div className="prt-login-form">
            <span className="prt-logo">
              <Logo />
              <span><AppName /></span>
            </span>
            <LoginForm />
            {this.props.wl && this.props.wl.show_login_logo_cc && <img
              className="powered"
              src={Branding.POWERED_BY_LOGO}
              alt={`Powered by ${Branding.COMPANY_NAME}`}
            />}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthView);