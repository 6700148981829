import Axios, { AxiosError, AxiosResponse } from 'axios';
import { API_URL } from '../config';
import { withAuthorizationHeader } from './index';
import { Dispatch, AnyAction } from 'redux';


// ------------------------------------
// Constants
// ------------------------------------

const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';

const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';

const AUTH_RESET_REQUEST_PASSWORD_FAILURE = 'AUTH_RESET_REQUEST_PASSWORD_FAILURE';
const AUTH_RESET_REQUEST_PASSWORD_REQUEST = 'AUTH_RESET_REQUEST_PASSWORD_REQUEST';
const AUTH_RESET_REQUEST_PASSWORD_SUCCESS = 'AUTH_RESET_REQUEST_PASSWORD_SUCCESS';

const AUTH_RESET_CHECK_PASSWORD_FAILURE = 'AUTH_RESET_CHECK_PASSWORD_FAILURE';
const AUTH_RESET_CHECK_PASSWORD_REQUEST = 'AUTH_RESET_CHECK_PASSWORD_REQUEST';
const AUTH_RESET_CHECK_PASSWORD_SUCCESS = 'AUTH_RESET_CHECK_PASSWORD_SUCCESS';

const AUTH_UPDATE_PASSWORD_FAILURE = 'AUTH_UPDATE_PASSWORD_FAILURE';
const AUTH_UPDATE_PASSWORD_REQUEST = 'AUTH_UPDATE_PASSWORD_REQUEST';
const AUTH_UPDATE_PASSWORD_SUCCESS = 'AUTH_UPDATE_PASSWORD_SUCCESS';

const AUTH_VERIFY_TOKEN_SUCCESS = 'AUTH_VERIFY_TOKEN_SUCCESS';
const AUTH_VERIFY_TOKEN_FAILURE = 'AUTH_VERIFY_TOKEN_FAILURE';

export const UPDATE_USER_TOKEN = 'UPDATE_USER_TOKEN';


// ------------------------------------
// Action Creators
// ------------------------------------

const loginUserRequest = ()                   => ({type: AUTH_LOGIN_REQUEST});
const loginUserSuccess = (res: AxiosResponse) => ({type: AUTH_LOGIN_SUCCESS, payload: res.data});
const loginUserFailure = (err: AxiosError)    => ({type: AUTH_LOGIN_FAILURE, payload: err && err.response && err.response.data});

const logoutUserRequest = () => ({type: AUTH_LOGOUT_REQUEST});

const checkResetTokenRequest = ()                   => ({type: AUTH_RESET_CHECK_PASSWORD_REQUEST});
const checkResetTokenSuccess = (res: AxiosResponse) => ({type: AUTH_RESET_CHECK_PASSWORD_SUCCESS, payload: res.data});
const checkResetTokenFailure = (err: AxiosError)    => ({type: AUTH_RESET_CHECK_PASSWORD_FAILURE, payload: err && err.response && err.response.data});

const resetUserRequest = ()                   => ({type: AUTH_RESET_REQUEST_PASSWORD_REQUEST});
const resetUserSuccess = (res: AxiosResponse) => ({type: AUTH_RESET_REQUEST_PASSWORD_SUCCESS, payload: res.data});
const resetUserFailure = (err: AxiosError)    => ({type: AUTH_RESET_REQUEST_PASSWORD_FAILURE, payload: err && err.response && err.response.data});

const updateUserRequest = ()                   => ({type: AUTH_UPDATE_PASSWORD_REQUEST});
const updateUserSuccess = (res: AxiosResponse) => ({type: AUTH_UPDATE_PASSWORD_SUCCESS, payload: res.data});
const updateUserFailure = (err: AxiosError)    => ({type: AUTH_UPDATE_PASSWORD_FAILURE, payload: err && err.response && err.response.data});

const verifyTokenSuccess = (res: AxiosResponse) => ({type: AUTH_VERIFY_TOKEN_SUCCESS, payload: res.data});
const verifyTokenFailure = (err: AxiosError)    => ({type: AUTH_VERIFY_TOKEN_FAILURE, payload: err && err.response && err.response.data});


// ------------------------------------
// Interfaces
// ------------------------------------

export interface ILoginForm {
  email: string;
  password: string;
  remember_me: boolean;
}

export interface IChangePasswordForm {
  must_change_password: boolean;
  password: string;
}

export interface IResetPasswordForm {
  email: string;
}

export interface ICheckTokenForm {
  token: string;
}


// ------------------------------------
// Actions
// ------------------------------------

const loginUser = (form: ILoginForm) => (dispatch: Dispatch) => {
  dispatch(loginUserRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.post(`${API_URL}/login`, form);
      dispatch(loginUserSuccess(res));
      localStorage.setItem('user', res.data.data.token);
      resolve(res);
    } catch (err) {
      dispatch(loginUserFailure(err));
      reject(err);
    }
  });
}

const logoutUser = (callback: Function) => (dispatch: Dispatch) => {
  dispatch(logoutUserRequest());
  localStorage.removeItem('user');
  callback();
}

/**
 * @function resetPassword
 * @param form @{}
 */
const resetPassword = (form: IResetPasswordForm) => (dispatch: Dispatch) => {
  dispatch(resetUserRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.post(`${API_URL}/password/request`, form);
      dispatch(resetUserSuccess(res));
      resolve(res);
    } catch (err) {
      dispatch(resetUserFailure(err));
      reject(err);
    }
  });
}

/**
 * @function checkResetPasswordToken
 * @param form @{}
 */
const checkResetPasswordToken = (form: ICheckTokenForm) => (dispatch: Dispatch) => {
  dispatch(checkResetTokenRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.post(`${API_URL}/password/reset`, form);
      dispatch(checkResetTokenSuccess(res));
      localStorage.setItem('user', res.data.token);
      resolve(res);
    } catch (err) {
      dispatch(checkResetTokenFailure(err));
      reject(err);
    }
  });
}

/**
 * @function updatePassword
 * @param form @{}
 */
const updatePassword = (form: IChangePasswordForm) => (dispatch: Dispatch, getState: () => any) => {
  dispatch(updateUserRequest());
  return new Promise(async (resolve, reject) => {
    const { id, token } = getState().auth.authenticatedUser;
    if (id && token) {
      try {
        const res = await Axios.put(`${API_URL}/users/${id}`, form, withAuthorizationHeader(token));
        dispatch(updateUserSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(updateUserFailure(err));
        reject(err);
      }
    } else {
      reject();
    }
  });
}

const verifyToken = (token: string) => (dispatch: Dispatch): Promise<AxiosResponse> => {
  return new Promise(async (resolve, reject) => {
    if (token) {
      try {
        const res = await Axios.get(`${API_URL}/jwt`, withAuthorizationHeader(token));
        dispatch(verifyTokenSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(verifyTokenFailure(err));
        reject(err);
      }
    } else {
      reject();
    }
  });
}

export const authActions = {
  checkResetPasswordToken,
  loginUser,
  logoutUser,
  resetPassword,
  updatePassword,
  verifyToken,
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState = {
  authenticatedUser: null,
  errors: [],
  info: [],
  isAuthenticated: false,
  isFetching: false,
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case AUTH_LOGIN_REQUEST:
    case AUTH_RESET_CHECK_PASSWORD_REQUEST:
    case AUTH_RESET_REQUEST_PASSWORD_REQUEST:
    case AUTH_UPDATE_PASSWORD_REQUEST:
      return {...state, isFetching: true, errors: [], info: []};

    case AUTH_RESET_CHECK_PASSWORD_FAILURE:
    case AUTH_RESET_REQUEST_PASSWORD_FAILURE:
    case AUTH_UPDATE_PASSWORD_FAILURE:
      return {...state, isFetching: false, errors: payload};

    case AUTH_RESET_REQUEST_PASSWORD_SUCCESS:
      return {...state, isFetching: false};
    
    case AUTH_LOGIN_FAILURE:
      return {...state, isFetching: false, errors: payload.messages};
    
    case AUTH_VERIFY_TOKEN_FAILURE:
    case AUTH_LOGOUT_REQUEST:
      return {...state, isAuthenticated: false, authenticatedUser: null};

    case AUTH_UPDATE_PASSWORD_SUCCESS:
        return {...state, isFetching: false, authenticatedUser: payload.data};
      
    case AUTH_RESET_CHECK_PASSWORD_SUCCESS:
      return {...state, isFetching: false, isAuthenticated: true, authenticatedUser: payload};

    case AUTH_VERIFY_TOKEN_SUCCESS:
    case AUTH_LOGIN_SUCCESS:
      return {...state, isFetching: false, isAuthenticated: true, authenticatedUser: payload.data};

    case UPDATE_USER_TOKEN:
      return {...state, authenticatedUser: {...state.authenticatedUser, token: payload}};

    default:
      return state;
  }
}