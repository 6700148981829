import React from 'react';
import { connect } from 'react-redux';
import { Branding, Branding } from "~/branding";

interface StateProps {
  wl: any;
}

const mapStateToProps = (state: any) => ({
  wl: state.users.wl
});

class AppName extends React.PureComponent<StateProps> {

  render() {
    return <>{this.props.wl && this.props.wl.cc_name ? this.props.wl.cc_name : `${Branding.ADMIN_APP_NAME}`}</>;
  }
}

export default connect(mapStateToProps, null)(AppName);