import { lazy } from 'react';
import Auth from './Auth/AuthView';
import ResetPassword from './ResetPassword/ResetPasswordView';
import Signup from './SignUpForm/SignUpForm';
import ResolvePage from './ResolvePage/ResolvePage';
import APIDocumentation from './APIDocumentation/APIDocumentation';
import { API_FRONTEND_ACCESS_URL } from './APIDocumentation/APIDocConstants';

const AsyncAdministrators = lazy(() => import("./Administrators/AdministratorsView"));
const AsyncCompany = lazy(() => import("./Company/CompanyView"));
const AsyncCustomers = lazy(() => import("./Customers/CustomersView"));
const AsyncOrders = lazy(() => import("./Orders/OrdersView"));
const AsyncPartners = lazy(() => import("./Partners/PartnersView"));
const AsyncPartner = lazy(() => import("./Partners/PartnerView"));
const AsyncAPIDocumentationView = lazy(() => import("./APIDocumentation/APIDocumentationView"));
// const AsyncAPIDocumentation = lazy(() => import("./APIDocumentation/APIDocumentation"));

export default [
  {
    canAccess: false,
    component: AsyncPartner,
    exact: true,
    path: '/partners/:partnerId'
  },
  {
    canAccess: true,
    component: ResolvePage,
    exact: true,
    path: '/'
  }, {
    canAccess: true,
    component: ResetPassword,
    exact: true,
    path: '/reset'
  }, {
    canAccess: true,
    component: ResetPassword,
    path: '/reset/:token'
  }, {
    canAccess: false,
    component: AsyncCustomers,
    exact: true,
    path: '/customers'
  }, {
    canAccess: false,
    component: AsyncCompany,
    exact: true,
    path: '/customers/:customerId'
  }, {
    canAccess: true,
    component: Auth,
    exact: true,
    path: '/login'
  }, {
    canAccess: false,
    component: Signup,
    exact: true,
    path: '/signup'
  }, {
    canAccess: false,
    component: AsyncOrders,
    exact: true,
    path: '/orders'
  }, {
    canAccess: false,
    component: AsyncPartners,
    exact: true,
    path: '/partners'
  }, {
    canAccess: false,
    component: AsyncAdministrators,
    exact: true,
    path: '/administrators'
  }, {
    canAccess: false,
    component: AsyncAPIDocumentationView,
    exact: true,
    path: '/api'
  },
  {
    canAccess: true,
    component: APIDocumentation,
    exact: true,
    path: API_FRONTEND_ACCESS_URL.COMMANDCENTER_API_URL,
  },
  {
    canAccess: true,
    component: APIDocumentation,
    exact: true,
    path: API_FRONTEND_ACCESS_URL.MESSAGEVIEW_API_URL,
  }
];