import Axios, { AxiosError, AxiosResponse } from 'axios';
import { API_URL } from '../config';
import { Dispatch, AnyAction } from 'redux';
import { withAuthorizationHeader } from './index';
import { MODAL_HIDE } from './modal';

// This interface used only on client side
export interface IAdministratorForm {
  email: string;
  first_name: string;
  last_name: string;
}

// Object of this interface is returned by server
export interface IAdministrator extends IAdministratorForm {
  created: string;
  id: string;
  name: string;
  recent_activity: string;
  status?: number;
  user_level: string;
}

// ------------------------------------
// Constants
// ------------------------------------

const ADMINISTRATOR_CUSTOMER_LIST_FAILURE = 'ADMINISTRATOR_CUSTOMER_LIST_FAILURE';
const ADMINISTRATOR_CUSTOMER_LIST_REQUEST = 'ADMINISTRATOR_CUSTOMER_LIST_REQUEST';
const ADMINISTRATOR_CUSTOMER_LIST_SUCCESS = 'ADMINISTRATOR_CUSTOMER_LIST_SUCCESS';

const ADMINISTRATOR_PARTNER_LIST_FAILURE = 'ADMINISTRATOR_PARTNER_LIST_FAILURE';
const ADMINISTRATOR_PARTNER_LIST_REQUEST = 'ADMINISTRATOR_PARTNER_LIST_REQUEST';
const ADMINISTRATOR_PARTNER_LIST_SUCCESS = 'ADMINISTRATOR_PARTNER_LIST_SUCCESS';

const ADMINISTRATOR_SUPER_LIST_FAILURE = 'ADMINISTRATOR_SUPER_LIST_FAILURE';
const ADMINISTRATOR_SUPER_LIST_REQUEST = 'ADMINISTRATOR_SUPER_LIST_REQUEST';
const ADMINISTRATOR_SUPER_LIST_SUCCESS = 'ADMINISTRATOR_SUPER_LIST_SUCCESS';

const ADMINISTRATOR_CREATE_FAILURE = 'ADMINISTRATOR_CREATE_FAILURE';
const ADMINISTRATOR_CREATE_REQUEST = 'ADMINISTRATOR_CREATE_REQUEST';
const ADMINISTRATOR_CREATE_SUCCESS = 'ADMINISTRATOR_CREATE_SUCCESS';

const ADMINISTRATOR_UPDATE_FAILURE = 'ADMINISTRATOR_UPDATE_FAILURE';
const ADMINISTRATOR_UPDATE_REQUEST = 'ADMINISTRATOR_UPDATE_REQUEST';
const ADMINISTRATOR_UPDATE_SUCCESS = 'ADMINISTRATOR_UPDATE_SUCCESS';

export const ADMINISTRATOR_DELETE_FAILURE = 'ADMINISTRATOR_DELETE_FAILURE';
export const ADMINISTRATOR_DELETE_REQUEST = 'ADMINISTRATOR_DELETE_REQUEST';
export const ADMINISTRATOR_DELETE_SUCCESS = 'ADMINISTRATOR_DELETE_SUCCESS';

const createAdminRequest = () => ({ type: ADMINISTRATOR_CREATE_REQUEST });
const createAdminSuccess = (res: AxiosResponse) => ({ type: ADMINISTRATOR_CREATE_SUCCESS, payload: res.data });
const createAdminFailure = (err: AxiosError) => ({ type: ADMINISTRATOR_CREATE_FAILURE, payload: err && err.response && err.response.data && err.response.data.messages });

const updateAdminRequest = () => ({ type: ADMINISTRATOR_UPDATE_REQUEST });
const updateAdminSuccess = (res: AxiosResponse) => ({ type: ADMINISTRATOR_UPDATE_SUCCESS, payload: res.data });
const updateAdminFailure = (err: AxiosError) => ({ type: ADMINISTRATOR_UPDATE_FAILURE, payload: err && err.response && err.response.data && err.response.data.messages });

const deleteAdminRequest = () => ({ type: ADMINISTRATOR_DELETE_REQUEST });
const deleteAdminSuccess = (res: AxiosResponse) => ({ type: ADMINISTRATOR_DELETE_SUCCESS, payload: res.data });
const deleteAdminFailure = (err: AxiosError) => ({ type: ADMINISTRATOR_DELETE_FAILURE, payload: err && err.response && err.response.data });

// ------------------------------------
// Action Creators
// ------------------------------------

const listCustomerAdministratorsRequest = () => ({
  type: ADMINISTRATOR_CUSTOMER_LIST_REQUEST
});
const listCustomerAdministratorsSuccess = (res: AxiosResponse) => ({
  type: ADMINISTRATOR_CUSTOMER_LIST_SUCCESS,
  payload: res.data
});
const listCustomerAdministratorsFailure = (err: AxiosError) => ({
  type: ADMINISTRATOR_CUSTOMER_LIST_FAILURE,
  payload: err && err.response && err.response.data
});
const listPartnerAdministratorsRequest = () => ({
  type: ADMINISTRATOR_PARTNER_LIST_REQUEST
});
const listPartnerAdministratorsSuccess = (res: AxiosResponse) => ({
  type: ADMINISTRATOR_PARTNER_LIST_SUCCESS,
  payload: res.data
});
const listPartnerAdministratorsFailure = (err: AxiosError) => ({
  type: ADMINISTRATOR_PARTNER_LIST_FAILURE,
  payload: err && err.response && err.response.data
});
const listSuperAdministratorsRequest = () => ({
  type: ADMINISTRATOR_PARTNER_LIST_REQUEST
});
const listSuperAdministratorsSuccess = (res: AxiosResponse) => ({
  type: ADMINISTRATOR_PARTNER_LIST_SUCCESS,
  payload: res.data
});
const listSuperAdministratorsFailure = (err: AxiosError) => ({
  type: ADMINISTRATOR_PARTNER_LIST_FAILURE,
  payload: err && err.response && err.response.data
});

// ------------------------------------
// Actions
// ------------------------------------

const listCustomerAdministrators = (parentId?: string) => (dispatch: Dispatch, getState: () => any) => {
  return new Promise(async (resolve, reject) => {
    const { authenticatedUser } = getState().auth;
    if (authenticatedUser && authenticatedUser.token) {
      try {
        dispatch(listCustomerAdministratorsRequest());
        const res = await Axios.get(`${API_URL}/customers/${parentId}/admins`, withAuthorizationHeader(authenticatedUser.token));
        dispatch(listCustomerAdministratorsSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(listCustomerAdministratorsFailure(err));
        reject(err);
      }
    }
  });
}

const listPartnerAdministrators = (parentId?: string) => (dispatch: Dispatch, getState: () => any) => {
  return new Promise(async (resolve, reject) => {
    const { authenticatedUser } = getState().auth;
    if (authenticatedUser && authenticatedUser.token && (authenticatedUser.user_level === 'administrator' || authenticatedUser.user_level === 'reseller')) {
      try {
        dispatch(listPartnerAdministratorsRequest());
        const res = await Axios.get(`${API_URL}/users/resellers/${parentId}`, withAuthorizationHeader(authenticatedUser.token));
        dispatch(listPartnerAdministratorsSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(listPartnerAdministratorsFailure(err));
        reject(err);
      }
    }
  });
}

const listSuperAdministrators = () => (dispatch: Dispatch, getState: () => any) => {
  return new Promise(async (resolve, reject) => {
    const { authenticatedUser } = getState().auth;
    if (authenticatedUser && authenticatedUser.token && authenticatedUser.user_level === 'administrator') {
      try {
        dispatch(listSuperAdministratorsRequest());
        const res = await Axios.get(`${API_URL}/administrators`, withAuthorizationHeader(authenticatedUser.token));
        dispatch(listSuperAdministratorsSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(listSuperAdministratorsFailure(err));
        reject(err);
      }
    }
  });
}

const createAdmin = (form: IAdministratorForm) => (dispatch: Dispatch, getState: () => any) => {
  dispatch(createAdminRequest());
  return new Promise(async (resolve, reject) => {
    const { token } = getState().auth.authenticatedUser;
    if (token) {
      try {
        const res = await Axios.post(`${API_URL}/users`, form, withAuthorizationHeader(token));
        dispatch(createAdminSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(createAdminFailure(err));
        reject(err);
      }
    }
  });
}

const updateAdmin = (form: IAdministratorForm) => (dispatch: Dispatch, getState: () => any) => {
  dispatch(updateAdminRequest());
  return new Promise(async (resolve, reject) => {
    const { token } = getState().auth.authenticatedUser;
    if (token) {
      try {
        const res = await Axios.put(`${API_URL}/users/${form.id}`, form, withAuthorizationHeader(token));
        dispatch(updateAdminSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(updateAdminFailure(err));
        reject(err);
      }
    }
  });
}


const deleteAdmin = (id: string, administrator_delete: boolean = false) => (dispatch: Dispatch, getState: () => any) => {
  dispatch(deleteAdminRequest());
  return new Promise(async (resolve, reject) => {
    const { token } = getState().auth.authenticatedUser;
    if (token) {
      try {
        const res = await Axios.delete(`${API_URL}/users/${id}/${administrator_delete}`, withAuthorizationHeader(token));
        dispatch(deleteAdminSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(deleteAdminFailure(err));
        reject(err);
      }
    }
  });
}


export const administratorsActions = {
  listCustomerAdministrators,
  listPartnerAdministrators,
  listSuperAdministrators,
  createAdmin,
  updateAdmin,
  deleteAdmin
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState = {
  errors: [],
  delete_errors: [],
  isFetching: false,
  administrator: null,
  administrators: [],
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case ADMINISTRATOR_CUSTOMER_LIST_REQUEST:
    case ADMINISTRATOR_PARTNER_LIST_REQUEST:
    case ADMINISTRATOR_SUPER_LIST_REQUEST:
    case ADMINISTRATOR_DELETE_REQUEST:
    case ADMINISTRATOR_CREATE_REQUEST:
    case ADMINISTRATOR_UPDATE_REQUEST:
      return { ...state, isFetching: true, errors: [], delete_errors: [] };

    case ADMINISTRATOR_CUSTOMER_LIST_FAILURE:
    case ADMINISTRATOR_PARTNER_LIST_FAILURE:
    case ADMINISTRATOR_SUPER_LIST_FAILURE:
      return { ...state, isFetching: false, errors: payload.messages };

    case ADMINISTRATOR_CUSTOMER_LIST_SUCCESS:
    case ADMINISTRATOR_PARTNER_LIST_SUCCESS:
    case ADMINISTRATOR_SUPER_LIST_SUCCESS:
      return { ...state, isFetching: false, administrators: payload.data };

    case ADMINISTRATOR_CREATE_SUCCESS:
      return { ...state, isFetching: false, administrators: [payload.data, ...state.administrators] };

    case ADMINISTRATOR_CREATE_FAILURE:
      return { ...state, isFetching: false, errors: payload };

    case ADMINISTRATOR_UPDATE_SUCCESS:
      return { ...state, isFetching: false, administrators: state.administrators.map((admin: IAdministrator) => admin.id === payload.data.id ? payload.data : admin) };

    case ADMINISTRATOR_UPDATE_FAILURE:
      return { ...state, isFetching: false, errors: payload };

    case ADMINISTRATOR_DELETE_SUCCESS:
      return { ...state, isFetching: false, administrators: state.administrators.filter((admin: IAdministrator) => admin.id !== payload.data.id) };

    case ADMINISTRATOR_DELETE_FAILURE:
      return { ...state, isFetching: false, delete_errors: payload && payload.messages };

    case MODAL_HIDE:
      return { ...state, isFetching: false, errors: [] };

    default:
      return state;
  }
}