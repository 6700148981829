import { AxiosResponse } from 'axios';
import React, { BaseSyntheticEvent, ChangeEvent, MouseEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import Loader from '~/components/common/Loader/Loader';
import { customersActions } from '~/store/customer';
import { modalActions } from '~/store/modal';
import { numbersActions } from '~/store/number';
import { IOrderForm, ordersActions } from '~/store/orders';
import { partnersActions } from '~/store/partner';
import { optionStyles } from '~/utils';
import './OrderForm.scss';
import { ThemedButton, ThemedInput } from '~/components/common/themed';

interface SelectItem {
  value: string;
  label: string;
}

interface State {
  numberCode: number | string;
  isNumberSelectOpen: boolean;
  partner?: any;
  customer?: any;
  form: {
    numbers: any[];
    business: string;
  }
}

interface StateProps {
  authenticatedUser: any;
  isFetching: boolean;
  isNumbersFetching: boolean;
  partners: SelectItem[];
  customers: SelectItem[];
  numbers: any[];
  errors: string[];
}

interface OwnProps {

}

interface DispatchProps {
  closeModal: () => void;
  getNumbers: (areaCode: number | undefined) => Promise<AxiosResponse>;
  createOrder: (form: IOrderForm) => Promise<AxiosResponse>;
  listCustomers: (parentId?: string) => Promise<AxiosResponse>;
  listPartners: () => void;
  listOrders: () => void;
}

type Props = DispatchProps & OwnProps & StateProps;

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  createOrder: ordersActions.createOrder,
  listOrders: ordersActions.listOrders,
  getNumbers: numbersActions.getNumbers,
  listCustomers: customersActions.listCustomers,
  listPartners: partnersActions.listPartners

};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.auth.authenticatedUser,
  isFetching: state.users.isFetching,
  isNumbersFetching: state.numbers.isFetching,
  customer: state.customers.customer,
  numbers: state.numbers.numbers,
  partners: state.partners.partners,
  customers: state.customers.customers,
  user: state.users.user,
  errors: state.orders.errors,
});

class OrderForm extends PureComponent<Props, State> {
  numberSelect: Select | null = null;
  state = {
    numberCode: '',
    isNumberSelectOpen: false,
    partner: { value: '', label: '' },
    customer: { value: '', label: '' },
    form: {
      numbers: [],
      business: ''
    }
  }

  componentDidMount = () => {
    if (this.props.authenticatedUser && this.props.authenticatedUser.user_level === 'administrator') {
      // Load all partners for administrator
      this.props.listPartners();
    } else {
      // Set one partner for Partner
      this.setState({
        partner: {
          value: this.props.authenticatedUser.id,
          label: this.props.authenticatedUser.name
        }
        // If current Partner - current user, right away load Customers
      }, () => this.props.listCustomers(this.props.authenticatedUser.parent));
    }
  }

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    if (this.state.customer.value.length > 0) {
      const customer_id = this.state.customer.value ? this.state.customer.value : '';
      this.props.createOrder({ numbers: this.state.form.numbers.map((numer: any) => (numer.value)), customer_id }).then(this.props.closeModal);
    }
  }

  handleFieldChange = (id: string, value: string) => this.setState({ [id]: value });

  handleSelectChange = (value: ValueType<any>[]) => {
    if (value) {
      this.setState({
        // isNumberSelectOpen: true,
        form: {
          ...this.state.form,
          numbers: [...value]
        }
      });
    }
  }

  handleSelectPartner = (opt: ValueType<any>) => {
    if (opt) {
      this.setState({
        partner: opt,
        customer: ''
      }, () => {
        const customer_id = opt.value;
        this.props.listCustomers(customer_id);
      });
    }
  }

  handleSelectCustomer = (value: ValueType<{ value: string; label: string; }>) => {
    if (value) {
      this.setState({
        customer: value
      });
    }
  }

  handleSelectFocus = () => {
    if (this.numberSelect) {
      !this.state.isNumberSelectOpen
        ? this.numberSelect.select.focus()
        : this.numberSelect.select.blur();
    }
  }

  handleFocus = () => this.setState({ isNumberSelectOpen: true });
  handleBlur = () => this.setState({ isNumberSelectOpen: false });

  getNumbers = (e: MouseEvent) => {
    e.stopPropagation();
    const { numberCode } = this.state;
    if (typeof numberCode === 'number' || numberCode === '') {
      this.props.getNumbers(numberCode === '' ? undefined : numberCode as unknown as number).then(_res => {
        this.setState({ isNumberSelectOpen: true }, this.handleSelectFocus);
      });
    }
  }

  render() {
    const options = this.props.numbers.map((number: any) => ({
      value: '1' + number.did[0],
      label: '1' + number.did[0]
    }));
    const partners = this.props.authenticatedUser.user_level === 'reseller' ? [{
      value: this.props.authenticatedUser.parent,
      label: this.props.authenticatedUser.name
    }] : this.props.partners.map((partner: any) => ({
      value: partner.id,
      label: partner.name
    }));
    const customers = this.props.customers.map((customers: any) => ({
      value: customers.id,
      label: customers.name
    }));

    const newAssignNumbers = () => (<React.Fragment>
      
      
    </React.Fragment>);

    return (
      <form onSubmit={this.handleSubmit} autoComplete='off'>
        {this.props.errors.length > 0 && (<div className="prt-form-field">
          <div className="full-width error-msg">
            {this.props.errors.map((msg: string, key: number) => <p key={key}>{msg}</p>)}
          </div>
        </div>)}
        {/* <div className="prt-form-field">
          <div className="prt-form-cell one-third">
            <Radio
              checked={this.state.numberType === NUMBER_GET_NEW}
              id='assign_number_new'
              name='assign_number'
              onChange={this.handleRadio}
              text='Get New Number'
              value={'' + NUMBER_GET_NEW}
            />
            <Radio
              checked={this.state.numberType === NUMBER_USE_BUSINESS}
              id='assign_number_business'
              name='assign_number'
              onChange={this.handleRadio}
              text='Use Business Number'
              value={'' + NUMBER_USE_BUSINESS}
            />
          </div>
        </div> */}
        <div className="prt-form-field">
          <div className="prt-form-cell one-half">
            <ThemedInput
              id="numberCode"
              label="Area Code (NPA)"
              onChange={this.handleFieldChange}
              placeholder="Area Code"
              type="text"
              maxLength={3}
              validation="number"
              value={this.state.numberCode}
            />
          </div>
          <div className="prt-form-cell one-half">
            <label>&nbsp;</label>
            <ThemedButton
              className="assignNumbers__getButton"
              disabled={this.props.isNumbersFetching || (this.state.numberCode !== '' && this.state.numberCode as unknown as number < 99)}
              onClick={this.getNumbers}
              type="button">{this.props.isNumbersFetching ? <Loader /> : 'Get Numbers'}</ThemedButton>
          </div>
        </div>
        <div className="prt-form-field">
          <div className="prt-form-cell full-width">
            <Select
              className='selector'
              isMulti
              id="numbers"
              name="numbers"
              value={this.state.form.numbers}
              onChange={this.handleSelectChange}
              ref={ref => this.numberSelect = ref}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              menuIsOpen={this.state.isNumberSelectOpen}
              styles={optionStyles}
              options={options}
            />
          </div>
        </div>
        <div className="prt-form-field">
          <div className="prt-form-cell one-half">
            <label>Partner</label>
            <Select
              name="partner"
              className="selector"
              onChange={this.handleSelectPartner}
              value={this.state.partner}
              options={partners}
            />
          </div>
          <div className="prt-form-cell one-half">
            <label>Customer</label>
            <Select
              name="customer"
              options={customers}
              className="selector"
              onChange={this.handleSelectCustomer}
              value={this.state.customer}
            />
          </div>
        </div>
        <div className="prt-form-field">
          <ThemedButton
            className="btn-primary"
            disabled={this.props.isFetching
              || !this.state.customer.value
              || (!this.state.form.numbers.length)
                }
            type="submit">{this.props.isFetching ? <Loader /> : 'Add'}</ThemedButton>
          <button className="btn-secondary is-cancel" type="button" onClick={this.props.closeModal}>Cancel</button>
        </div>
      </form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm as any);