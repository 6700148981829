import Axios, { AxiosError, AxiosResponse } from 'axios';
import { API_URL } from '../config';
import { Dispatch, AnyAction } from 'redux';
import { withAuthorizationHeader } from './index';
import { MODAL_HIDE } from './modal';

// ------------------------------------
// Constants
// ------------------------------------
const ORDER_CREATE_FAILURE = 'ORDER_CREATE_FAILURE';
const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';

const ORDER_LIST_FAILURE = 'ORDER_LIST_FAILURE';
const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';

// const ORDER_GET_FAILURE = 'ORDER_GET_FAILURE';
// const ORDER_GET_REQUEST = 'ORDER_GET_REQUEST';
// const ORDER_GET_SUCCESS = 'ORDER_GET_SUCCESS';

// const ORDER_DELETE_FAILURE = 'ORDER_DELETE_FAILURE';
// const ORDER_DELETE_REQUEST = 'ORDER_DELETE_REQUEST';
// const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS';

// const ORDER_UPDATE_FAILURE = 'ORDER_UPDATE_FAILURE';
// const ORDER_UPDATE_REQUEST = 'ORDER_UPDATE_REQUEST';
// const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS';

export interface IOrderForm {
    numbers: string[],
    customer_id: string,
}

// ------------------------------------
// Action Creators
// ------------------------------------
const createOrderRequest = () => ({ type: ORDER_CREATE_REQUEST });
const createOrderSuccess = (res: AxiosResponse) => ({ type: ORDER_CREATE_SUCCESS, payload: res.data });
const createOrderFailure = (err: AxiosError) => ({ type: ORDER_CREATE_FAILURE, payload: err && err.response && err.response.data });

const listOrdersRequest = () => ({ type: ORDER_LIST_REQUEST });
const listOrdersSuccess = (res: AxiosResponse) => ({ type: ORDER_LIST_SUCCESS, payload: res.data });
const listOrdersFailure = (err: AxiosError) => ({ type: ORDER_LIST_FAILURE, payload: err && err.response && err.response.data });

// const deleteOrderRequest = () => ({ type: ORDER_DELETE_REQUEST });
// const deleteOrderSuccess = (userId: string) => ({ type: ORDER_DELETE_SUCCESS, payload: userId });
// const deleteOrderFailure = (err: AxiosError) => ({ type: ORDER_DELETE_FAILURE, payload: err && err.response && err.response.data });

// const getOrderequest = () => ({ type: ORDER_GET_REQUEST });
// const getOrderSuccess = (res: AxiosResponse) => ({ type: ORDER_GET_SUCCESS, payload: res.data });
// const getOrderFailure = (err: AxiosError) => ({ type: ORDER_GET_FAILURE, payload: err && err.response && err.response.data });

// const updateOrderRequest = () => ({ type: ORDER_UPDATE_REQUEST });
// const updateOrderSuccess = (res: AxiosResponse) => ({ type: ORDER_UPDATE_SUCCESS, payload: res.data });
// const updateOrderFailure = (err: AxiosError) => ({ type: ORDER_UPDATE_FAILURE, payload: err && err.response && err.response.data });


// ------------------------------------
// Actions
// ------------------------------------
const createOrder = (form: IOrderForm) => (dispatch: Dispatch, getState: () => any) => {
    return new Promise((resolve, reject) => {
        const { authenticatedUser } = getState().auth;
        dispatch(createOrderRequest());
        Axios.post(`${API_URL}/order`, form, withAuthorizationHeader(authenticatedUser.token))
            .then(res => {
                dispatch(createOrderSuccess(res));
                resolve(res);
            })
            .catch(err => {
                dispatch(createOrderFailure(err));
                reject(err);
            });
    });
}

// const deleteOrder = (orderId: string) => async (dispatch: Dispatch, getState: () => any) => {
//     const { authenticatedUser } = getState().auth;
//     try {
//         dispatch(deleteOrderRequest());
//         await Axios.delete(`${API_URL}/order/${orderId}`, withAuthorizationHeader(authenticatedUser.token));
//         dispatch(deleteOrderSuccess(orderId));
//     } catch (err) {
//         dispatch(deleteOrderFailure(err));
//     }
// }

const listOrders = () => async (dispatch: Dispatch, getState: () => any) => {
    const { authenticatedUser } = getState().auth;
    try {
        dispatch(listOrdersRequest());
        const res = await Axios.get(`${API_URL}/orders`, withAuthorizationHeader(authenticatedUser.token));
        dispatch(listOrdersSuccess(res));
    } catch (err) {
        dispatch(listOrdersFailure(err));
    }
}

export const ordersActions = {
    createOrder,
    listOrders,
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState = {
    errors: [],
    isFetching: false,
    orders: [],
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
        case ORDER_CREATE_REQUEST:
        case ORDER_LIST_REQUEST:
            return { ...state, isFetching: true, errors: [] };

        case ORDER_CREATE_FAILURE:
        case ORDER_LIST_FAILURE:
            return { ...state, isFetching: false, errors: payload && payload.messages ? payload.messages : [] };

        case ORDER_CREATE_SUCCESS:
            return { ...state, isFetching: false, orders: [payload.data, ...state.orders] };

        case ORDER_LIST_SUCCESS:
            return { ...state, isFetching: false, orders: payload.data };

        case MODAL_HIDE:
            return { ...state, errors: [] };

        default:
            return state;
    }
}
