import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import { modalActions } from '~/store/modal';
import { ThemedButton } from '~/components/common/themed';
import { administratorsActions, IAdministrator } from '~/store/administrator';
import { AxiosResponse } from 'axios';

interface OwnProps { }

interface DispatchProps {
  closeModal: () => void;
  deleteAdministrator: (administratorId: string) => Promise<AxiosResponse>;
  listPartnerAdministrators: (partnerId: string) => Promise<AxiosResponse>;
  listSuperAdministrators: () => Promise<AxiosResponse>;
  openModal: (modalId: string, props: any) => void;
}

interface StateProps {
  administrators: any[];
  authenticatedUser: any;
  isAuthenticated: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {}


const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  deleteAdministrator: administratorsActions.deleteAdmin,
  listPartnerAdministrators: administratorsActions.listPartnerAdministrators,
  listSuperAdministrators: administratorsActions.listSuperAdministrators,
  openModal: modalActions.openModal,
};

const mapStateToProps = (state: any) => ({
  administrators: state.administrators.administrators,
  authenticatedUser: state.auth.authenticatedUser,
  isAuthenticated: state.auth.isAuthenticated,
});

class AdministratorsView extends PureComponent<Props, State> {

  componentDidMount() {
    if (
      this.props.isAuthenticated
    ) {
      // Fetch the list of administrators of the Partner
      this.fetchAdministrators();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.isAuthenticated &&
      this.props.isAuthenticated !== prevProps.isAuthenticated
    ) {
      // Fetch the list of administrators of the Partner
      this.fetchAdministrators();
    }
  }

  fetchAdministrators = () => {
    if (this.props.authenticatedUser) {
      if (this.props.authenticatedUser.user_level === "administrator") {
        this.props.listSuperAdministrators();
      } else if (this.props.authenticatedUser.user_level === "reseller") {
        this.props.listPartnerAdministrators(this.props.authenticatedUser.parent);
      }
    }
  }

  handleEditAdministrator = (administratorId: string) => {
    this.props.openModal('ADMINISTRATOR_MODAL', { administratorId });
  }

  addAdministrator = () => {
    this.props.openModal('ADMINISTRATOR_MODAL', { administratorId: null });
  }

  deleteAdministrator = (id: string) => {
    this.props.deleteAdministrator(id).then(this.props.closeModal).then(this.fetchAdministrators);
  }

  render() {
    const tableData = this.props.administrators.map((administrator: IAdministrator) => {
      const buttons = [{
        text: 'Edit',
        onClick: () => this.handleEditAdministrator(administrator.id as string),
        classnames: ['link']
      }];
      // Show delete button if there is more than one administrator
      if (this.props.administrators) {
        buttons.push({
          classnames: ['link', 'archive'],
          onClick: () => this.props.openModal('CONFIRM_MODAL', {
            title: 'Delete Administrator',
            body: `Are you sure you want to delete <strong>${(administrator.first_name || administrator.name) + (administrator.last_name && ` ${administrator.last_name}`)}</strong> ?`,
            onYes: this.deleteAdministrator.bind(this, administrator.id),
            onNo: this.props.closeModal,
          }),
          text: 'Delete'
        });
      }
      return [
        (administrator.first_name || administrator.name) + (administrator.last_name && ` ${administrator.last_name}`),
        administrator.email,
        new Date(administrator.recent_activity),
        new Date(administrator.created),
        { buttons }
      ];
    });

    return (
      <div className='prt'>
        <Header />
        <main className="prt-body">
          <div className="prt-wrapper">
            <div className="prt-head">
              <h1 className="prt-page-title">Administrators</h1>
              <ThemedButton className="btn-primary" onClick={this.addAdministrator}>Add Administrator</ThemedButton>
            </div>

            <Table
              data={tableData}
              columns={[
                { name: 'Administrator Name', type: 'text' },
                { name: 'Email', type: 'text' },
                { name: 'Recent Activity', type: 'date' },
                { name: 'Added', type: 'date' },
                { name: '', type: 'buttons' },
              ]}
            />
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorsView as any);