import Axios, { AxiosError, AxiosResponse } from 'axios';
import { API_URL } from '../config';
import { Dispatch, AnyAction } from 'redux';
import { withAuthorizationHeader } from './index';
import { MODAL_HIDE } from './modal';

// ------------------------------------
// Constants
// ------------------------------------

const userLevelsAccess = ['reseller', 'administrator'];

const CUSTOMIZE_CREATE_FAILURE = 'CUSTOMIZE_CREATE_FAILURE';
const CUSTOMIZE_CREATE_REQUEST = 'CUSTOMIZE_CREATE_REQUEST';
const CUSTOMIZE_CREATE_SUCCESS = 'CUSTOMIZE_CREATE_SUCCESS';

const CUSTOMIZE_GET_FAILURE = 'CUSTOMIZE_GET_FAILURE';
const CUSTOMIZE_GET_REQUEST = 'CUSTOMIZE_GET_REQUEST';
const CUSTOMIZE_GET_SUCCESS = 'CUSTOMIZE_GET_SUCCESS';

const CUSTOMIZE_RESET_FAILURE = 'CUSTOMIZE_RESET_FAILURE';
const CUSTOMIZE_RESET_REQUEST = 'CUSTOMIZE_RESET_REQUEST';
const CUSTOMIZE_RESET_SUCCESS = 'CUSTOMIZE_RESET_SUCCESS';

const CUSTOMIZE_UPDATE_FAILURE = 'CUSTOMIZE_UPDATE_FAILURE';
const CUSTOMIZE_UPDATE_REQUEST = 'CUSTOMIZE_UPDATE_REQUEST';
const CUSTOMIZE_UPDATE_SUCCESS = 'CUSTOMIZE_UPDATE_SUCCESS';


// ------------------------------------
// Action Creators
// ------------------------------------

const createCustomizeRequest = () => ({ type: CUSTOMIZE_CREATE_REQUEST });
const createCustomizeSuccess = (res: AxiosResponse) => ({ type: CUSTOMIZE_CREATE_SUCCESS, payload: res.data });
const createCustomizeFailure = (err: AxiosError) => ({ type: CUSTOMIZE_CREATE_FAILURE, payload: err && err.response && err.response.data });

const getCustomizeRequest = () => ({ type: CUSTOMIZE_GET_REQUEST });
const getCustomizeSuccess = (res: AxiosResponse) => ({ type: CUSTOMIZE_GET_SUCCESS, payload: res.data });
const getCustomizeFailure = (err: AxiosError) => ({ type: CUSTOMIZE_GET_FAILURE, payload: err && err.response && err.response.data });

const resetCustomizeRequest = () => ({ type: CUSTOMIZE_RESET_REQUEST });
const resetCustomizeSuccess = (res: AxiosResponse) => ({ type: CUSTOMIZE_RESET_SUCCESS, payload: res.data });
const resetCustomizeFailure = (err: AxiosError) => ({ type: CUSTOMIZE_RESET_FAILURE, payload: err && err.response && err.response.data });

const updateCustomizeRequest = () => ({ type: CUSTOMIZE_UPDATE_REQUEST });
const updateCustomizeSuccess = (res: AxiosResponse) => ({ type: CUSTOMIZE_UPDATE_SUCCESS, payload: res.data });
const updateCustomizeFailure = (err: AxiosError) => ({ type: CUSTOMIZE_UPDATE_FAILURE, payload: err && err.response && err.response.data && err.response.data.messages });


// ------------------------------------
// Interfaces
// ------------------------------------

export interface ICustomizeForm {
  [x: string]: any;
  application_name: string;
  cc_domain_name: string;
  cc_name: string;
  domain_name?: string;
  logo_url: string;
  primary_css_color: string;
  show_login_logo_cc: boolean;
  show_login_logo_mv: boolean;
  show_logo_cc: boolean;
  show_logo_mv: boolean;
  welcome_message: string;
}


// ------------------------------------
// Actions
// ------------------------------------

const createCustomize = (form: ICustomizeForm, partnerId?: string) => async (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  const { downloadURL } = getState().assets;
  if (downloadURL) form.logo_url = downloadURL;
  if (authenticatedUser && authenticatedUser.token && userLevelsAccess.includes(authenticatedUser.user_level)) {
    try {
      const id = partnerId ? partnerId : authenticatedUser.id;
      dispatch(createCustomizeRequest());
      const res = await Axios.post(`${API_URL}/company/${id}/whitelabeling`, form, withAuthorizationHeader(authenticatedUser.token))
      dispatch(createCustomizeSuccess(res));
    } catch (err) {
      dispatch(createCustomizeFailure(err));
    }
  }
}

const getCustomize = (partnerId?: string) => async (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  if (authenticatedUser && authenticatedUser.token && userLevelsAccess.includes(authenticatedUser.user_level)) {
    try {
      const id = partnerId ? partnerId : authenticatedUser.parent;
      dispatch(getCustomizeRequest());
      const res = await Axios.get(`${API_URL}/company/${id}/whitelabeling`, withAuthorizationHeader(authenticatedUser.token))
      dispatch(getCustomizeSuccess(res));
    } catch (err) {
      dispatch(getCustomizeFailure(err));
    }
  }
}

const resetCustomize = (partnerId?: string) => (dispatch: Dispatch, getState: () => any) => {
  return new Promise(async (resolve, reject) => {
    const { authenticatedUser } = getState().auth;
    if (authenticatedUser && authenticatedUser.token && userLevelsAccess.includes(authenticatedUser.user_level)) {
      try {
        const id = partnerId ? partnerId : authenticatedUser.parent;
        dispatch(resetCustomizeRequest());
        const res = await Axios.delete(`${API_URL}/company/${id}/whitelabeling`, withAuthorizationHeader(authenticatedUser.token));
        dispatch(resetCustomizeSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(resetCustomizeFailure(err));
        reject(err);
      }
    }
  });
}

const updateCustomize = (form: ICustomizeForm, partnerId?: string) => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  const { downloadURL } = getState().assets;
  return new Promise(async (resolve, reject) => {
    if (downloadURL) form.logo_url = downloadURL;
    if (authenticatedUser && authenticatedUser.token && userLevelsAccess.includes(authenticatedUser.user_level)) {
      dispatch(updateCustomizeRequest());
      try {
        const id = partnerId ? partnerId : authenticatedUser.parent;
        const res = await Axios.put(`${API_URL}/company/${id}/whitelabeling`, form, withAuthorizationHeader(authenticatedUser.token));
        dispatch(updateCustomizeSuccess(res.data));
        resolve(res);
      } catch (err) {
        dispatch(updateCustomizeFailure(err));
        reject(err);
      }
    } else {
      reject();
    }
  });
}

export const customizeActions = {
  createCustomize,
  getCustomize,
  resetCustomize,
  updateCustomize
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState = {
  errors: [],
  isFetching: false,
  customize: null,
  customers: [],
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case CUSTOMIZE_CREATE_REQUEST:
    case CUSTOMIZE_GET_REQUEST:
    case CUSTOMIZE_RESET_REQUEST:
    case CUSTOMIZE_UPDATE_REQUEST:
      return { ...state, isFetching: true };

    case CUSTOMIZE_CREATE_FAILURE:
    case CUSTOMIZE_GET_FAILURE:
    case CUSTOMIZE_RESET_FAILURE:
    case CUSTOMIZE_UPDATE_FAILURE:
      return { ...state, isFetching: false, errors: payload };

    case CUSTOMIZE_CREATE_SUCCESS:
    case CUSTOMIZE_GET_SUCCESS:
      return { ...state, isFetching: false, customize: payload.data.data };

    case CUSTOMIZE_RESET_SUCCESS:
      return { ...state, isFetching: false, customize: {} };

    case CUSTOMIZE_UPDATE_SUCCESS:
      return { ...state, isFetching: false, customize: payload.data.data };

    case MODAL_HIDE:
      return { ...state, isFetching: false, errors: [] };

    default:
      return state;
  }
}