import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import './PartnersView.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import { modalActions } from '~/store/modal';
import { partnersActions } from '~/store/partner';
import { ThemedButton } from '~/components/common/themed';
import { assetsActions } from '~/store/assets';

interface OwnProps { }

interface DispatchProps {
  deletePartner: (partnerId: number) => void;
  listPartners: () => void;
  openModal: (modalId: string, modalProps?: any) => void;
  setPartner: (partnerId: string) => void;
  exportItems: (opt: any) => Promise<any>;
}

interface StateProps {
  partners: IPartner[];
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {}


const mapDispatchToProps = {
  deletePartner: partnersActions.deletePartner,
  listPartners: partnersActions.listPartners,
  setPartner: partnersActions.setPartner,
  openModal: modalActions.openModal,
  exportItems: assetsActions.exportItems
};

const mapStateToProps = (state: any) => ({
  partners: state.partners.partners,
});


class PartnersView extends PureComponent<Props, State> {

  componentDidMount() {
    // Fetch the list of users
    this.props.listPartners();
  }

  handleEditPartner = (id: string) => {
    this.props.setPartner(id);
    this.props.openModal('PARTNER_MODAL', { partnerId: id });
  }

  handleCustomizePartner = (id: string) => {
    this.props.setPartner(id);
    this.props.openModal('CUSTOMIZE_MODAL', { partnerId: id });
  }

  exportItems = (e: MouseEvent) => {
    e.preventDefault();
    const opt = {
      url: 'csv/reseller'
    };
    this.props.exportItems(opt);

  }

  openPartnerModal = () => this.props.openModal('PARTNER_MODAL');

  render() {

    const partnersData = this.props.partners.map(partner => ([
      { text: partner.name, link: `/partners/${partner.id}` },
      partner.customer_count,
      '' + partner.status,
      new Date(partner.recent_activity),
      new Date(partner.created),
      {
        buttons: [
          {
            classnames: ['link'],
            onClick: () => {
              this.handleEditPartner(partner.id)
            },
            text: 'Edit',
          },
          {
            classnames: ['link'],
            onClick: () => {
              this.handleCustomizePartner(partner.id)
            },
            text: 'customize',
          }
        ]
      }
    ]));

    return (
      <div className='prt'>
        <Header />
        <main className="prt-body">
          <div className="prt-wrapper">
            <div className="prt-head">
              <h1 className="prt-page-title">Partners</h1>
              <ThemedButton className="btn-primary" onClick={this.openPartnerModal}>Add Partner</ThemedButton>
            </div>

            <Table
              data={partnersData}
              columns={[
                { name: 'Partner Name', type: 'link' },
                { name: 'Customers', type: 'number' },
                { name: 'Status', type: 'status' },
                { name: 'Recent Activity', type: 'date' },
                { name: 'Added', type: 'date' },
                { name: '', type: 'buttons' },
              ]}
            />
            <div className="prt-table-footer">
              <a href="#" onClick={this.exportItems} className="btn-secondary">Export</a>
            </div>

          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnersView as any);