import React from "react";
import Modal from "../AbstractModal/AbstractModal";
import './PartnerModal.scss';
import PartnerForm from "~/components/forms/PartnerForm/PartnerForm";
import { connect } from "react-redux";

function PartnerModal({ partnerId }: { partnerId: string }) {
  return (
    <Modal title={`${partnerId ? 'Edit' : 'Add'} Partner`} className='PartnerModal'>
      <div><PartnerForm partnerId={partnerId} /></div>
    </Modal>
  );
}

const mapStateToProps = (_state: any) => ({

});

export default connect(mapStateToProps)(PartnerModal);
