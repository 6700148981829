import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import Footer from '~/components/Footer/Footer';
import Header from '~/components/Header/Header';
import './PartnerView.scss';
import { modalActions } from '~/store/modal';
import Table from '../../components/Table/Table';
import { customersActions, ICustomer } from '../../store/customer';
import { partnersActions } from '~/store/partner';
import { statuses } from '~/store/user';
import { assetsActions } from '~/store/assets';
import { administratorsActions, IAdministrator } from '~/store/administrator';

interface OwnProps extends RouteComponentProps { }

interface DispatchProps {
  clearPartner: () => void;
  closeModal: () => void;
  deleteAdministrator: (administratorId: string) => Promise<any>;
  deleteCustomer: (customerId: string) => Promise<any>;
  exportItems: (opt: any) => Promise<any>;
  listPartnerAdministrators: (partnerId: string) => Promise<void>;
  listCustomers: (partnerId: string) => Promise<void>;
  openModal: (modalId: string, props: any) => void;
  setPartner: (partnerId: string) => void;
}

interface StateProps {
  administrators: any[];
  authenticatedUser: any;
  customers: ICustomer[];
  customers_errors: string[];
  errors: string[];
  isAuthenticated: boolean;
  isFetching: boolean;
  partner: any;
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {}


const mapDispatchToProps = {
  clearPartner: partnersActions.clearPartner,
  closeModal: modalActions.closeModal,
  deleteAdministrator: administratorsActions.deleteAdmin,
  deleteCustomer: customersActions.deleteCustomer,
  exportItems: assetsActions.exportItems,
  listPartnerAdministrators: administratorsActions.listPartnerAdministrators,
  listCustomers: customersActions.listCustomers,
  openModal: modalActions.openModal,
  setPartner: partnersActions.setPartner,
};

const mapStateToProps = (state: any) => ({
  administrators: state.administrators.administrators,
  authenticatedUser: state.auth.authenticatedUser,
  customers: state.customers.customers,
  customers_errors: state.customers.errors,
  isAuthenticated: state.auth.isAuthenticated,
  isFetching: state.customers.isFetching,
  partner: state.partners.partner,
});

class PartnerView extends PureComponent<Props, State> {

  exportItems = (e: MouseEvent) => {
    e.preventDefault();
    const { partnerId } = this.props.match.params as any;
    const opt = {
      url: `csv/customer/?partnerId=${partnerId}`
    };
    this.props.exportItems(opt);
  }

  componentWillMount() {
    if (this.props.isAuthenticated) {
      const { partnerId } = this.props.match.params as any;
      this.loadPartner();
      this.props.listCustomers(partnerId);
      this.props.listPartnerAdministrators(partnerId);
    }
  }

  componentWillUnmount = () => {
    this.props.clearPartner();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isAuthenticated) {
      const { partnerId } = this.props.match.params as any;
      if (prevProps.location.hash !== this.props.location.hash) {
        if (this.props.location.hash === '#administrators') {
          this.props.listPartnerAdministrators(partnerId);
        } else if (this.props.location.hash === '#customers') {
          this.props.listCustomers(partnerId);
        }
      }
    }
  }

  handleEditCustomer = (customerId: string) => {
    this.props.openModal('CUSTOMER_MODAL', { customerId });
  }

  handleDeleteCustomer = (customerId: string) => {
    const { partnerId } = this.props.match.params as any;
    this.props.deleteCustomer(customerId)
      .then(() => this.props.listCustomers(partnerId));
  }

  handleEditAdministrator = (administratorId: string) => {
    this.props.openModal('ADMINISTRATOR_MODAL', { administratorId });
  }

  deleteAdministrator = (administratorId: string) => {
    const { partnerId } = this.props.match.params as any;
    this.props.deleteAdministrator(administratorId)
      .then(() => {
        this.props.closeModal();
        this.props.listPartnerAdministrators(partnerId);
      });
  }

  addAdministrator = () => {
    this.props.openModal('ADMINISTRATOR_MODAL', { administratorId: null });
  }

  addCustomer = () => {
    this.props.openModal('CUSTOMER_MODAL', { customerId: null });
  }

  loadPartner = () => {
    const { partnerId } = this.props.match.params as any;
    this.props.setPartner(partnerId);
  }

  renderAdministrators = () => {
    const tableData = this.props.administrators.map((administrator: IAdministrator) => {
      const buttons = [{
        classnames: ['link'],
        text: 'Edit',
        onClick: this.handleEditAdministrator.bind(this, administrator.id),
      }];
      // Show delete button if there is more than one administrator
      if (this.props.administrators) {
        buttons.push({
          classnames: ['link', 'archive'],
          onClick: () => this.props.openModal('CONFIRM_MODAL', {
            title: 'Delete Administrator',
            body: `Are you sure you want to delete <strong>${(administrator.first_name || administrator.name) + (administrator.last_name && ` ${administrator.last_name}`)}</strong> ?`,
            onYes: this.deleteAdministrator.bind(this, administrator.id),
            onNo: this.props.closeModal,
          }),
          text: 'Delete'
        });
      }
      return [
        (administrator.first_name || administrator.name) + (administrator.last_name && ` ${administrator.last_name}`),
        administrator.email,
        new Date(administrator.recent_activity),
        new Date(administrator.created),
        { buttons }
      ];
    });
    return (<>
      <a
        className="btn-primary addCustomerBtn"
        onClick={this.addAdministrator}
      >Add Administrator</a>
      <Table
        data={tableData}
        columns={[
          { name: 'Name', type: 'text' },
          { name: 'Email', type: 'text' },
          { name: 'Recent Activity', type: 'date' },
          { name: 'Added', type: 'date' },
          { name: '', type: 'buttons' },
        ]}
      />
    </>);
  }

  renderCustomers = () => {
    const tableData = this.props.customers.map((customer: ICustomer) => ([
      { text: customer.name, link: `/customers/${customer.id}` },
      customer.user_cnt,
      customer.phone_cnt,
      '' + customer.status,
      new Date(customer.recent_activity),
      new Date(customer.created),
      {
        buttons: [{
          classnames: ['link'],
          onClick: this.handleEditCustomer.bind(this, customer.id),
          text: 'Edit',
        }, {
          classnames: ['link', 'archive'],
          onClick: () => this.props.openModal('CONFIRM_MODAL', {
            title: 'Archive Customer',
            body: `Are you sure you want to archive the customer <strong>${customer.name}</strong> ?`,
            isFetching: this.props.isFetching,
            onYes: this.handleDeleteCustomer.bind(this, customer.id),
            onNo: this.props.closeModal,
          }),
          text: 'Archive',
        }]
      }
    ]));

    return (<>
      <a
        className="btn-primary addCustomerBtn"
        onClick={this.addCustomer}
      >Add Customer</a>
      {this.props.customers_errors && this.props.customers_errors.length > 0 && (<div className='prt-form-field'>
        <div className="full-width error-msg">
          {this.props.customers_errors.map((msg: string, key: number) => <p key={key}>{msg}</p>)}
        </div>
      </div>)}
      {tableData.length === 0 ? <div>No customers</div> : <>
        <Table
          data={tableData}
          columns={[
            { name: 'Customer Name', type: 'link' },
            { name: 'Users', type: 'number', access: ['administrator', 'reseller'] },
            { name: 'Numbers', type: 'number' },
            { name: 'Status', type: 'status' },
            { name: 'Recent Activity', type: 'date' },
            { name: 'Added', type: 'date' },
            { name: '', type: 'buttons' },
          ]}
        />
        <div className="prt-table-footer">
          <a href="#" onClick={this.exportItems} className="btn-secondary">Export</a>
        </div>
      </>}
    </>);
  }

  renderGeneral = () => {
    const extractContact = (partner, prop: string) => (<>
      {['contact', 'email', 'phone'].map((subprop: string, key: number) => {
        const field = `${prop}_${subprop}`;
        return (partner && partner.contact !== null && partner.contact[field]) ?
          <p key={key}>{partner.contact[field]}</p> :
          null;
      })}
    </>);

    return (this.props.partner &&
      <div>
        <h2 className="prt-group-header invisible">General Information</h2>
        <div className="prt-info-group">
          <div className="prt-info-item">
            <div className="prt-info-label">Partner ID</div>
            <div className="prt-info-value">{this.props.partner.id}</div>
          </div>
          <div className="prt-info-item">
            <div className="prt-info-label">Status</div>
            <div className="prt-info-value">
              <span className={`prt-status-${statuses[this.props.partner.status].toLowerCase()}`}>
                {statuses[this.props.partner.status].toUpperCase()}
              </span>
            </div>
          </div>
        </div>

        <h2 className="prt-group-header">Address information</h2>
        <div className="prt-info-group">
          <div className="prt-info-item">
            <div className="prt-info-label">Address</div>
            <div className="prt-info-value">
              {this.props.partner.reseller && this.props.partner.reseller.address}
            </div>
          </div>
          <div className="prt-info-item">
            <div className="prt-info-label">City</div>
            <div className="prt-info-value">
              {this.props.partner.reseller && this.props.partner.reseller.city}
            </div>
          </div>
          <div className="prt-info-item">
            <div className="prt-info-label">State</div>
            <div className="prt-info-value">
              {this.props.partner.reseller && this.props.partner.reseller.state}
            </div>
          </div>
          <div className="prt-info-item">
            <div className="prt-info-label">Zip Code</div>
            <div className="prt-info-value">
              {this.props.partner.reseller && this.props.partner.reseller.zip}
            </div>
          </div>
        </div>

        <h2 className="prt-group-header">Contacts</h2>
        <div className="prt-info-group">
          <div className="prt-info-item">
            <div className="prt-info-label">Primary Contact</div>
            <div className="prt-info-value">
              {extractContact(this.props.partner, 'admin')}
            </div>
          </div>
          <div className="prt-info-item">
            <div className="prt-info-label">Billing Contact</div>
            <div className="prt-info-value">
              {extractContact(this.props.partner, 'billing')}
            </div>
          </div>
        </div>

        {/* <h2 className="prt-group-header">Agreement</h2>
        <div className="prt-info-group">
          <div className="prt-info-item">
            <div className="prt-info-label">Contract</div>
            <div className="prt-info-value"><a href="#">agreement_01.pdf</a></div>
          </div>
          <div className="prt-info-item">
            <div className="prt-info-label">Contract Signed Date</div>
            <div className="prt-info-value">01/01/2019</div>
          </div>
          <div className="prt-info-item">
            <div className="prt-info-label">Contract Expiration Date</div>
            <div className="prt-info-value">01/01/2020</div>
          </div>
          <div className="prt-info-item">
            <div className="prt-info-label">Brightlink Account Manager</div>
            <div className="prt-info-value">
              Helen Jones <br />
              helen.jones@mail.com <br />
              (404) 342-0029
          </div>
          </div>
          <div className="prt-info-item">
            <div className="prt-info-label">Contract Notes</div>
            <div className="prt-info-value">Agreement Notes</div>
          </div>
        </div> */}
      </div>
    );
  }

  render() {
    return (
      this.props.partner && <div className='prt'>
        <Header />
        <main className="prt-body">
          <div className="prt-head-panel">
            <div className="prt-wrapper">
              <div className="prt-head-panel-inner">
                {this.props.authenticatedUser.user_level !== 'customer' && this.props.authenticatedUser.user_level !== 'customer_admin' && <Link to='/partners' className="prt-back">Partners</Link>}
                <h1 className="prt-page-title">{this.props.partner && this.props.partner.name}</h1>
                <ul className="prt-tabs">
                  <li className={classnames({ active: !this.props.location.hash || this.props.location.hash === '#general' })}><a href="#general">General</a></li>
                  <li className={classnames({ active: this.props.location.hash === '#administrators' })}><a href="#administrators">Administrators</a></li>
                  <li className={classnames({ active: this.props.location.hash === '#customers' })}><a href="#customers">Customers</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="prt-wrapper">
            {(!this.props.location.hash || this.props.location.hash === '#general') && this.renderGeneral()}
            {this.props.location.hash === '#administrators' && this.renderAdministrators()}
            {this.props.location.hash === '#customers' && this.renderCustomers()}
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerView as any);
