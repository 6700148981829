import { AxiosResponse } from 'axios';
import React, { BaseSyntheticEvent, ChangeEvent, MouseEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import Loader from '~/components/common/Loader/Loader';
import { modalActions } from '~/store/modal';
import { numbersActions, ICPAASInvertoryNumberForm, NumberDetail, CpaasNumberDetail } from '~/store/number';
import { ordersActions } from '~/store/orders';
import { optionStyles } from '~/utils';
import { ThemedRadio, ThemedInput, ThemedButton, ThemedSwitch } from '~/components/common/themed';
import { partnersActions } from "~/store/partner";
import _ from 'lodash';
import { EMAIL_REGEXP } from '~/config';
import { Branding } from '~/branding';

const NUMBER_GET_NEW = 'NUMBER_GET_NEW';
const NUMBER_USE_BUSINESS = 'NUMBER_USE_BUSINESS';
const NUMBER_CPAAS_INVENTORY = 'NUMBER_CPAAS_INVENTORY';

interface State {
  [key: string]: any,
  numberCode: number | string;
  country: string;
  didType: string;
  businessCountry: string;
  cpaasNumber: string;
  isNumberSelectOpen: boolean;
  isCPAASNumberSelectOpen: boolean;
  shouldPromptForCampaignId: boolean;
  shouldPromptForCPAASCampaignId: boolean;
  isCampaignIdRequired: boolean;
  isCPAASCampaignIdRequired: boolean;
  shouldShowTollFreeWarning: boolean;
  shouldShowCPAASTollFreeWarning: boolean;
  numberType: string;
  isBusinessTollfreeNumber: boolean;
  numberDetails: NumberDetail[];
  cpaasNumberDetails: CpaasNumberDetail[];
  form: {
    numbers: any[];
    business: string;
    cpaasNumbers: any[];
    campaignId: string;
    businessCampaignId: string;
    cpaasCampaignId: string;
  }
  businessTollfreeInfo: {
    businessName: string;
    contactName: string;
    contactJobTitle: string;
    contactPhone: number | string;
    contactEmail: string;
  }
}

interface StateProps {
  authenticatedUser: any;
  isFetching: boolean;
  isNumbersFetching: boolean;
  isCPAASInventoryNumbersFetching: boolean;
  numbers: any[];
  phones: any[];
  cpaasInventorynumbers: any[];
  location: any;
  errors: string[];
  customer: any;
  partner: any;
}

interface OwnProps {

}

interface DispatchProps {
  closeModal: () => void;
  numbersClear: () => void;
  getNumbers: (areaCode: number | undefined, didType: String, country: String) => Promise<AxiosResponse>;
  getCPAASInventoryNumbers: (number: ICPAASInvertoryNumberForm) => Promise<AxiosResponse>;
  getCompNumbers: () => void;
  createOrder: (payload: any) => Promise<AxiosResponse>;
  allocateNumber: (phone_number: string, owner: string, businessTollfreeInfo?: any, campaignId?: string) => Promise<AxiosResponse>;
  createNumber: (payload: any) => Promise<Response>;
  getPartner: (partnerId: string) => void;
}

type Props = DispatchProps & OwnProps & StateProps;

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  numbersClear: numbersActions.numbersClear,
  getNumbers: numbersActions.getNumbers,
  getCPAASInventoryNumbers: numbersActions.getCPAASInventoryNumbers,
  getCompNumbers: numbersActions.getCompNumbersWithCPaaSStatus,
  createOrder: ordersActions.createOrder,
  allocateNumber: numbersActions.allocateNumber,
  createNumber: numbersActions.createNumber,
  getPartner: partnersActions.getPartner
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.auth.authenticatedUser,
  isFetching: state.numbers.isFetching || state.orders.isFetching,
  isNumbersFetching: state.numbers.isFetching,
  isCPAASInventoryNumbersFetching: state.numbers.isCPAASInventoryNumberFetching,
  numbers: state.numbers.numbers,
  phones: state.numbers.phones,
  cpaasInventorynumbers: state.numbers.cpaasInventoryNumbers,
  user: state.users.user,
  location: state.location,
  errors: (state.numbers.errors && state.numbers.errors.length > 0) ? state.numbers.errors :
    (state.orders.errors && state.orders.errors.length > 0 ? state.orders.errors : (state.numbers.cpaasInventoryErrors && state.numbers.cpaasInventoryErrors.length > 0) ? state.numbers.cpaasInventoryErrors : []),
  customer: state.customers.customer,
  partner: state.partners.partner || {}
});

class NumberAdd extends PureComponent<Props, State> {
  cpaasFetchDetails = {
    pre_num: '',
    fetch: false
  }
  numberSelect: Select | null = null;
  cpaasNumberSelect: Select | null = null;
  excludedNumbers: any[] | null = null;
  state = {
    numberCode: '',
    country: 'US',
    didType: 'Mobile & Landline',
    businessCountry: 'US',
    cpaasNumber: '',
    isNumberSelectOpen: false,
    isCPAASNumberSelectOpen: false,
    shouldPromptForCampaignId: false,
    shouldPromptForCPAASCampaignId: false,
    isCampaignIdRequired: false,
    isCPAASCampaignIdRequired: false,
    shouldShowTollFreeWarning: false,
    shouldShowCPAASTollFreeWarning: false,
    numberType: NUMBER_GET_NEW,
    isBusinessTollfreeNumber: false,
    numberDetails: [],
    cpaasNumberDetails: [],
    form: {
      numbers: [],
      business: '',
      cpaasNumbers: [],
      campaignId: '',
      businessCampaignId: '',
      cpaasCampaignId: '',
    },
    businessTollfreeInfo: {
      businessName: '',
      contactName: '',
      contactJobTitle: '',
      contactPhone: '',
      contactEmail: '',
    }
  }

  componentDidMount = () => {
    this.props.numbersClear();
    if (this.props.phones) {
      this.excludedNumbers = _.map(this.props.phones, phone => phone.phone_number.substring(1));
    }
    const { reseller_id } = this.props.customer;
    if (reseller_id) {
      this.props.getPartner(reseller_id);
    }
  }

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const owner = this.props.authenticatedUser.user_level === 'customer_admin' ? this.props.authenticatedUser.parent : this.props.location.pathname.split('/')[2];
    if (this.state.numberType === NUMBER_GET_NEW) {
      const payload = {
        customer_id: owner,
        numbers: this.state.form.numbers.map((item: any) => (item.value)),
        isCPAASInventory: false,
        cpaasInventoryNumbers: this.state.numberDetails.map((el: NumberDetail) => {
          let details = {
            'did': el.did[0],
            'mmsEnabled': true,
            'smsEnabled': true,
            'action': 'Update'
          };

          if (el.didType !== 'Toll Free' && this.state.form.campaignId) {
            Object.assign(details, {a2p: true, campaignId: this.state.form.campaignId});
          }

          return details;
        })
      };
      this.props.createOrder(payload).then(() => {
        this.props.closeModal();
        this.props.getCompNumbers();
      });
    }
    if (this.state.numberType === NUMBER_USE_BUSINESS) {
      this.props.allocateNumber(
        '1' + this.state.form.business,
        owner,
        this.state.isBusinessTollfreeNumber ? this.state.businessTollfreeInfo : undefined,
        (this.state.form.businessCampaignId && !this.state.isBusinessTollfreeNumber) ? this.state.form.businessCampaignId : undefined
      )
        .then(() => {
          console.log(`[PROVISIONED]: ${this.state.form.business}`);
          const payload = {
            owner,
            phones: [`1${this.state.form.business}`]
          }
          this.props.createNumber(payload).then(() => {
            this.props.closeModal();
            this.props.getCompNumbers();
          });
        });
    }
    if (this.state.numberType === NUMBER_CPAAS_INVENTORY) {
      const payload = {
        customer_id: owner,
        numbers: this.state.form.cpaasNumbers.map((item: any) => (item.value)),
        isCPAASInventory: true,
        cpaasInventoryNumbers: this.state.cpaasNumberDetails.map((el: CpaasNumberDetail) => {
          let details = {
            'did': el.didNumber,
            'mmsEnabled': true,
            'mmsRouteId': this.props.partner.cpaas_detail.mms_key,
            'smsEnabled': true,
            'smsConfigId': this.props.partner.cpaas_detail.sms_key,
            'action': 'Update'
          };

          if (el.didType !== 'Toll Free' && this.state.form.cpaasCampaignId) {
            Object.assign(details, {a2p: true, campaignId: this.state.form.cpaasCampaignId});
          }

          return details;
        })
      };
      this.props.createOrder(payload).then(() => {
        this.props.closeModal();
        this.props.getCompNumbers();
      });
    }
  }

  handleFieldChange = (e: ChangeEvent) => {
    e.stopPropagation();
    const { id, value } = e.target as HTMLInputElement;
    this.setState({ form: { ...this.state.form, [id]: value } });
  }

  handleFieldChangeNumber = (id: string, value: string | number) => {
    this.setState({ [id]: value });
  }

  handleFieldChangeCampaignId = (_id: string, value: string) => {
    this.setState({ form: { ...this.state.form, campaignId: value } });
  }

  handleFieldChangeBusinessCampaignId = (_id: string, value: string) => {
    this.setState({ form: { ...this.state.form, businessCampaignId: value } });
  }

  handleFieldChangeCPAASCampaignId = (_id: string, value: string) => {
    this.setState({ form: { ...this.state.form, cpaasCampaignId: value } });
  }

  handleBusinessTollfreeFieldChangeNumber = (id: string, value: string | number) => {
    this.setState({ businessTollfreeInfo: { ...this.state.businessTollfreeInfo, [id]: value } });
  }

  handleSelectChange = (value: ValueType<{ value: string; label: string; }[]>) => {
    if (value) {
      let numberDetails: NumberDetail[] = [...this.state.numberDetails];
      const newNumbers = [...value];
      const numbers = _.map(newNumbers, 'value');

      // Added a number, find the new value number in the this.props.numbers and add the deets to the array
      numbers.forEach(a => {
        if (numberDetails.find((n: NumberDetail) => ('1' + n.did[0].toString() === a)) === undefined){
          // Add this number's details from the inventory to the stash
          numberDetails.push(this.props.numbers.find((n: NumberDetail) => ('1' + n.did[0].toString() === a)));
        }
      });
      // Removed a number, find the deets number that is no longer in the values and remove it from the deets array
      numberDetails = numberDetails.filter((a: NumberDetail) => numbers.includes('1' + a.did[0].toString()));

      const { shouldPromptForCampaignId, isCampaignIdRequired, shouldShowTollFreeWarning } = this.setCampaignVars(numberDetails);

      this.setState({
        // isNumberSelectOpen: false,
        shouldPromptForCampaignId: shouldPromptForCampaignId,
        isCampaignIdRequired: isCampaignIdRequired,
        shouldShowTollFreeWarning: shouldShowTollFreeWarning,
        numberDetails: numberDetails,
        form: {
          ...this.state.form,
          numbers: newNumbers
        }
      });
    }
  }

  handleCPAASSelectChange = (value: ValueType<{ value: string; label: string; }[]>) => {
    if (value) {
      let numberDetails: CpaasNumberDetail[] = [...this.state.cpaasNumberDetails];
      const newNumbers = [...value];
      const numbers = _.map(newNumbers, 'value');

      // Added a number, find the new value number in the this.props.cpaasInventorynumbers and add the deets to the array
      numbers.forEach(a => {
        if (numberDetails.find((n: CpaasNumberDetail) => ('1' + n.didNumber.toString() === a)) === undefined){
          // Add this number's details from the inventory to the stash
          numberDetails.push(this.props.cpaasInventorynumbers.find((n: CpaasNumberDetail) => ('1' + n.didNumber.toString() === a)));
        }
      });
      // Removed a number, find the deets number that is no longer in the values and remove it from the deets array
      numberDetails = numberDetails.filter((a: CpaasNumberDetail) => numbers.includes('1' + a.didNumber.toString()));

      const { shouldPromptForCampaignId, isCampaignIdRequired, shouldShowTollFreeWarning } = this.setCampaignVars(numberDetails);

      this.setState({
        // isNumberSelectOpen: false,
        shouldPromptForCPAASCampaignId: shouldPromptForCampaignId,
        isCPAASCampaignIdRequired: isCampaignIdRequired,
        shouldShowCPAASTollFreeWarning: shouldShowTollFreeWarning,
        cpaasNumberDetails: numberDetails,
        form: {
          ...this.state.form,
          cpaasNumbers: newNumbers
        }
      });
    }
  }

  setCampaignVars(numberDetails: CpaasNumberDetail[] | NumberDetail[]) {
    let foundTollFree = false;
    let foundUSNonTollFree = false;
    let foundCANonTollFree = false;

    numberDetails.forEach((number: CpaasNumberDetail | NumberDetail) => {
      if (number.didType === 'Toll Free') {
        foundTollFree = true;
      } else {
        if (number.country === 'United States') {
          foundUSNonTollFree = true;
        } else if (number.country === 'Canada') {
          foundCANonTollFree = true
        }
      }
    });

    const shouldPromptForCampaignId = foundUSNonTollFree || foundCANonTollFree;
    const isCampaignIdRequired = foundUSNonTollFree;
    const shouldShowTollFreeWarning = shouldPromptForCampaignId && foundTollFree;

    return { shouldPromptForCampaignId, isCampaignIdRequired, shouldShowTollFreeWarning };
  }

  handleRadio = (e: ChangeEvent) => {
    e.stopPropagation();
    const { value } = e.target as HTMLInputElement;
    this.setState({ numberType: value }, () => {
      //As Per new requirement no need to Eager fetch CpaaS Inventory Numbers.
      // if (value === NUMBER_CPAAS_INVENTORY) {
      //   if (this.state.cpaasNumber !== this.cpaasFetchDetails.pre_num) {
      //     this.cpaasFetchDetails.fetch = false;
      //   }
      //   this.getCPAASNumber(false);
      // }
    });
  }

  handleSelectFocus = () => {
    if (this.numberSelect) {
      return !this.state.isNumberSelectOpen
        ? this.numberSelect.select.focus()
        : this.numberSelect.select.blur();
    }
  }

  handleFocus = () => this.setState({ isNumberSelectOpen: true });
  handleBlur = () => this.setState({ isNumberSelectOpen: false });

  handleCPAASSelectFocus = () => {
    if (this.cpaasNumberSelect) {
      return !this.state.isCPAASNumberSelectOpen
        ? this.cpaasNumberSelect.select.focus()
        : this.cpaasNumberSelect.select.blur();
    }
  }

  handleCPAASFocus = () => this.setState({ isCPAASNumberSelectOpen: true });
  handleCPAASBlur = () => this.setState({ isCPAASNumberSelectOpen: false });

  getNumbers = (e: MouseEvent) => {
    e.stopPropagation();
    const { numberCode, didType, country } = this.state;
    if (typeof numberCode === 'number' || numberCode === '') {
      this.props.getNumbers(numberCode === '' ? undefined : numberCode as unknown as number, didType, country).then(_res => {
        this.setState({ isNumberSelectOpen: true }, this.handleSelectFocus);
      });
    }
  }

  getCPAASNumbers = (e: MouseEvent) => {
    e.stopPropagation();
    this.cpaasFetchDetails.fetch = false;
    this.getCPAASNumber(true);
  }

  getCPAASNumber = (open: boolean) => {
    const { cpaasNumber } = this.state;
    if (this.props.partner && this.props.partner.cpaas_detail) {
      if (!this.cpaasFetchDetails.fetch) {
        const number: ICPAASInvertoryNumberForm = {
          serachNumber: cpaasNumber,
          customerId: this.props.partner.cpaas_detail.customer_key,
          excludedNumbers: this.excludedNumbers
        }
        this.props.getCPAASInventoryNumbers(number).then(_res => {
          this.cpaasFetchDetails.fetch = true;
          this.cpaasFetchDetails.pre_num = cpaasNumber;
          if (open) {
            this.setState({ isCPAASNumberSelectOpen: true }, this.handleCPAASSelectFocus);
          }
        });
      }
    }
  }

  handleFieldChangeNumberBusiness = (e: ChangeEvent) => {
    e.stopPropagation();
    const { value } = e.target as HTMLInputElement;
    if (/^\d*$/.test(value)) this.setState({ form: { ...this.state.form, business: value } });
  }

  validateBusinessTollfreeInfo = () => {
    const { businessName, contactName,
      contactJobTitle,
      contactPhone,
      contactEmail } = this.state.businessTollfreeInfo;
    return !businessName || !contactName || !contactJobTitle || !contactPhone || (!contactEmail || !EMAIL_REGEXP.test(contactEmail));
  }

  validateCampaignId = () => {
    if (this.state.numberType === NUMBER_GET_NEW && this.state.shouldPromptForCampaignId && (this.state.isCampaignIdRequired || this.state.form.campaignId)) {
      return !/^C[A-Z0-9]{6}$/.test(this.state.form.campaignId);
    } else if (this.state.numberType === NUMBER_USE_BUSINESS && !this.state.isBusinessTollfreeNumber && (this.state.businessCountry === 'US' || this.state.form.businessCampaignId)) {
      return !/^C[A-Z0-9]{6}$/.test(this.state.form.businessCampaignId);
    } else if (this.state.numberType === NUMBER_CPAAS_INVENTORY && this.state.shouldPromptForCPAASCampaignId && (this.state.isCPAASCampaignIdRequired || this.state.form.cpaasCampaignId)) {
      return !/^C[A-Z0-9]{6}$/.test(this.state.form.cpaasCampaignId);
    }
    return false;
  }

  render() {
    const options = this.props.numbers.map((number: any) => ({
      value: '1' + number.did[0],
      label: '1' + number.did[0]
    }));

    const cpaasInventoryOptions = this.props.cpaasInventorynumbers.map((number: any) => ({
      value: '1' + number.didNumber,
      label: '1' + number.didNumber
    }));
    const didTypes = [
      {
        value: 'Mobile & Landline',
        label: 'Mobile & Landline'
      },
      {
        value: 'Toll Free',
        label: 'Toll Free'
      },
    ];

    const countries = [{
      value: 'US',
      label: 'United States'
    }, {
      value: 'CA',
      label: 'Canada'
    }];
    const countryValue = countries.find(country => country.value === this.state.country);
    const businessCountryValue = countries.find(country => country.value === this.state.businessCountry);
    const didTypeValue = didTypes.find(didType => didType.value === this.state.didType);

    return (
      <form onSubmit={this.handleSubmit} autoComplete="off">
        {this.props.errors.length > 0 && (<div className="prt-form-field">
          <div className="full-width error-msg">
            {this.props.errors.map((msg: string, key: number) => <p key={key}>{msg}</p>)}
          </div>
        </div>)}

        <div className="prt-form-field">
          <div className="prt-form-cell one-third">
            <ThemedRadio
              checked={this.state.numberType === NUMBER_GET_NEW}
              id='assign_number_new'
              name='assign_number'
              onChange={this.handleRadio}
              text='Get New Number'
              value={'' + NUMBER_GET_NEW}
            />
            {this.props.authenticatedUser.user_level !== 'customer_admin' && <ThemedRadio
              checked={this.state.numberType === NUMBER_USE_BUSINESS}
              id='assign_number_business'
              name='assign_number'
              onChange={this.handleRadio}
              text='Use Business Number'
              value={'' + NUMBER_USE_BUSINESS}
            />}
            {this.props.authenticatedUser
              && (this.props.authenticatedUser.user_level === 'administrator'
                || this.props.authenticatedUser.user_level === 'reseller')
              && this.props.partner.cpaas_detail
              && this.props.partner.cpaas_detail.cpaas_enabled
              && <ThemedRadio
                checked={this.state.numberType === NUMBER_CPAAS_INVENTORY}
                id='cpaas_inventory_number'
                name='cpaas_inventory_number'
                onChange={this.handleRadio}
                text='CPaaS Inventory'
                value={'' + NUMBER_CPAAS_INVENTORY}
              />
            }

          </div>
          <div className="prt-form-cell two-third">

            {this.state.numberType === NUMBER_GET_NEW && <>
              <div className="prt-form-cell full-width">
                <p className="assignNumbers__description">
                  You can request new phone number from {`${Branding.COMPANY_NAME}`}.<br />
                  Choose Area Code and get available number.<br />
                  This number will be your default number.
                </p>
              </div>
              <div className="prt-form-cell one-half">
                <label className="mandatory" htmlFor="number_type">Number Type</label>
                <Select
                  name="number_type"
                  className="selector"
                  onChange={(didType: any) => {
                    this.setState({ didType: didType.value });
                  }}
                  value={didTypeValue}
                  options={didTypes}
                />
              </div>
              <div className="prt-form-cell one-half">
                <label className="mandatory" htmlFor="country">Country</label>
                <Select
                  name="country"
                  className="selector"
                  onChange={(country: any) => {
                    this.setState({ country: country.value });
                  }}
                  value={countryValue}
                  options={countries}
                />
              </div>
              <div className="prt-form-cell one-half">
                <ThemedInput
                  className="prt-text-field assignNumbers__areaCode"
                  id="numberCode"
                  label="Area Code (NPA)"
                  onChange={this.handleFieldChangeNumber}
                  placeholder="Area Code"
                  type="text"
                  maxLength={3}
                  validation="number"
                  value={this.state.numberCode}
                />
              </div>
              <div className="prt-form-cell one-half">
                <label>&nbsp;</label>
                <ThemedButton
                  className="assignNumbers__getButton"
                  disabled={this.props.isNumbersFetching || (this.state.numberCode !== '' && this.state.numberCode as unknown as number < 99)}
                  onClick={this.getNumbers}
                  type="button">
                  {this.props.isNumbersFetching ? <Loader /> : 'Get Numbers'}
                </ThemedButton>
              </div>
              <div className="prt-form-cell full-width">
                <Select
                  className='selector'
                  isMulti
                  id="numbers"
                  name="numbers"
                  value={this.state.form.numbers}
                  onChange={this.handleSelectChange}
                  ref={ref => this.numberSelect = ref}
                  menuIsOpen={this.state.isNumberSelectOpen}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  styles={optionStyles}
                  options={options}
                />
              </div>
              {this.state.shouldPromptForCampaignId && <>
                <div className="prt-form-cell full-width">
                  <ThemedInput
                    className="prt-text-field assignNumbers__campaignId"
                    id="campaignId"
                    label="Campaign ID"
                    onChange={this.handleFieldChangeCampaignId}
                    placeholder="Campaign ID"
                    type="text"
                    maxLength={7}
                    validation="campaign"
                    value={this.state.form.campaignId}
                    required={this.state.isCampaignIdRequired}
                  />
                </div>
              </>}
              {this.state.shouldShowTollFreeWarning && <>
                <div className="prt-form-cell full-width">
                  <span>This list contains toll-free numbers, the campaign ID will not be applied to those.</span>
                </div>
              </>}
            </>}
            {this.state.numberType === NUMBER_USE_BUSINESS && <>
              <div className="prt-form-cell full-width">
                <h2 className='prt-group-header assignNumbers__header'>TOLLFREE {`${this.state.isBusinessTollfreeNumber ? 'ENABLE' : 'DISABLE'}`}</h2>
              </div>
              <div className="prt-form-cell one-half">
                <ThemedSwitch
                  checkedIcon={<i>ON</i>}
                  uncheckedIcon={<i>OFF</i>}
                  onColor={"#00bbe5"}
                  offColor={"#a1a1a1"}
                  onChange={() =>
                    this.setState(prevState => ({
                      isBusinessTollfreeNumber: !prevState.isBusinessTollfreeNumber
                    }))
                  }
                  checked={!!this.state.isBusinessTollfreeNumber}
                />
              </div>
              <div className="prt-form-cell one-half">
                <label className="mandatory" htmlFor="businessCountry">Country</label>
                <Select
                  name="businessCountry"
                  className="selector"
                  onChange={(businessCountry: any) => {
                    this.setState({ businessCountry: businessCountry.value });
                  }}
                  value={businessCountryValue}
                  options={countries}
                />
              </div>

              <div className="prt-form-cell full-width">
                <p className="assignNumbers__description">Non-mobile number, we recommend the business's usual landline</p>
                <section>
                  <img id='phone_number_flag' src={`https://flagicons.lipis.dev/flags/4x3/${this.state.businessCountry.toLowerCase()}.svg`} alt={`${this.state.businessCountry} Flag`} />
                  <span>+1</span>
                  <input
                    type='text'
                    onChange={this.handleFieldChangeNumberBusiness}
                    maxLength={10}
                    placeholder='Phone Number'
                    value={this.state.form.business}
                  />
                </section>
              </div>
              {this.state.isBusinessTollfreeNumber && <>
                <div className="prt-form-cell one-half">
                  <ThemedInput
                    className="prt-text-field"
                    id="businessName"
                    label="Business Name"
                    onChange={this.handleBusinessTollfreeFieldChangeNumber}
                    placeholder="Business Name"
                    type="text"
                    value={this.state.businessTollfreeInfo.businessName}
                    required
                  />
                </div>
                <div className="prt-form-cell one-half">
                  <ThemedInput
                    className="prt-text-field"
                    id="contactName"
                    label="Contact Name"
                    onChange={this.handleBusinessTollfreeFieldChangeNumber}
                    placeholder="Contact Name"
                    type="text"
                    value={this.state.businessTollfreeInfo.contactName}
                    required
                  />
                </div>

                <div className="prt-form-cell one-half">
                  <ThemedInput
                    className="prt-text-field"
                    id="contactJobTitle"
                    label="Contact Job Title"
                    onChange={this.handleBusinessTollfreeFieldChangeNumber}
                    placeholder="Contact Job Title"
                    type="text"
                    value={this.state.businessTollfreeInfo.contactJobTitle}
                    required
                  />
                </div>
                <div className="prt-form-cell one-half">
                  <ThemedInput
                    className="prt-text-field"
                    id="contactPhone"
                    label="Contact Phone"
                    onChange={this.handleBusinessTollfreeFieldChangeNumber}
                    placeholder="Contact Phone"
                    type="text"
                    validation="number"
                    maxLength={10}
                    value={this.state.businessTollfreeInfo.contactPhone}
                    required
                  />
                </div>

                <div className="prt-form-cell full-width">
                  <ThemedInput
                    className="prt-text-field"
                    id="contactEmail"
                    label="Contact Email"
                    onChange={this.handleBusinessTollfreeFieldChangeNumber}
                    placeholder="Contact Email"
                    type="text"
                    validation="email"
                    value={this.state.businessTollfreeInfo.contactEmail}
                    required
                  />
                </div>

              </>
              }
              {!this.state.isBusinessTollfreeNumber && <>
                <div className="prt-form-cell full-width">
                  <ThemedInput
                    className="prt-text-field assignNumbers__campaignId"
                    id="businessCampaignId"
                    label="Campaign ID"
                    onChange={this.handleFieldChangeBusinessCampaignId}
                    placeholder="Campaign ID"
                    type="text"
                    maxLength={7}
                    validation="campaign"
                    value={this.state.form.businessCampaignId}
                    required={this.state.businessCountry === 'US'}
                  />
                </div>
              </>
              }
            </>
            }
            {this.state.numberType === NUMBER_CPAAS_INVENTORY && <>
              <div className="prt-form-cell full-width">
                <p className="assignNumbers__description">
                  You can request Active phone number from {`${Branding.COMPANY_NAME}`}.<br />
                  Choose Number and get active number.<br />
                  This number will be your default number.
                </p>
              </div>
              <div className="prt-form-cell one-half">
                <ThemedInput
                  className="prt-text-field assignNumbers__areaCode"
                  id="cpaasNumber"
                  label="Number"
                  onChange={this.handleFieldChangeNumber}
                  placeholder="Number"
                  type="text"
                  maxLength={10}
                  validation="number"
                  value={this.state.cpaasNumber}
                />
              </div>
              <div className="prt-form-cell one-half">
                <label>&nbsp;</label>
                <ThemedButton
                  className="assignNumbers__getButton"
                  disabled={this.props.isCPAASInventoryNumbersFetching}
                  onClick={this.getCPAASNumbers}
                  type="button">
                  {this.props.isCPAASInventoryNumbersFetching ? <Loader /> : 'Get Numbers'}
                </ThemedButton>
              </div>
              <div className="prt-form-cell full-width">
                <Select
                  className='selector'
                  isMulti
                  id="cpaasNumbers"
                  name="cpaasNumbers"
                  value={this.state.form.cpaasNumbers}
                  onChange={this.handleCPAASSelectChange}
                  ref={ref => this.cpaasNumberSelect = ref}
                  menuIsOpen={this.state.isCPAASNumberSelectOpen}
                  onFocus={this.handleCPAASFocus}
                  onBlur={this.handleCPAASBlur}
                  styles={optionStyles}
                  options={cpaasInventoryOptions}
                />
              </div>
              {this.state.shouldPromptForCPAASCampaignId && <>
                <div className="prt-form-cell full-width">
                  <ThemedInput
                    className="prt-text-field assignNumbers__campaignId"
                    id="cpaasCampaignId"
                    label="Campaign ID"
                    onChange={this.handleFieldChangeCPAASCampaignId}
                    placeholder="Campaign ID"
                    type="text"
                    maxLength={7}
                    validation="campaign"
                    value={this.state.form.cpaasCampaignId}
                    required={this.state.isCPAASCampaignIdRequired}
                  />
                </div>
              </>}
              {this.state.shouldShowCPAASTollFreeWarning && <>
                <div className="prt-form-cell full-width">
                  <span>This list contains toll-free numbers, the campaign ID will not be applied to those.</span>
                </div>
              </>}
            </>}
          </div>
        </div>
        <div className="prt-form-field">
          <ThemedButton
            className="btn-primary"
            disabled={this.props.isFetching || this.props.isCPAASInventoryNumbersFetching || this.validateCampaignId() ||
              (
                (this.state.numberType === NUMBER_GET_NEW && !this.state.form.numbers.length)
                || (this.state.numberType === NUMBER_USE_BUSINESS && (this.state.form.business.length !== 10 || (this.state.isBusinessTollfreeNumber && this.validateBusinessTollfreeInfo())))
                || (this.state.numberType === NUMBER_CPAAS_INVENTORY && !this.state.form.cpaasNumbers.length)
              )}
            type="submit">{this.props.isFetching ? <Loader /> : 'Add'}</ThemedButton>
          <button className="btn-secondary is-cancel" type="button" onClick={this.props.closeModal}>Cancel</button>
        </div>
      </form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberAdd as any);
