import React, { PureComponent } from "react";
import { connect } from "react-redux";
import csvParse from 'csv-parse';

import { modalActions } from '~/store/modal';
import { ThemedButton } from '~/components/common/themed';

import Modal from "../AbstractModal/AbstractModal";
import DragAndDrop from "./DradAndDrop/DragAndDrop";
import { EMAIL_REGEXP, } from '~/config';

import './ImportModal.scss';
import Loader from "~/components/common/Loader/Loader";
import user, { usersActions } from "~/store/user";
import { numbersActions } from "~/store/number";
import { AxiosResponse } from "axios";
import _ from 'lodash';
import { CSVLink } from "react-csv";
import { Branding, Branding } from "~/branding";

type State = {
  csvData: Array<any>;
  csvUploadError: string;
  csvUploadSuccess: string;
  csvError: any;
}

type Props = {
  closeModal: Function;
  importUsers: Function;
  isLoading: boolean;
  csvUploadSuccess: string;
  getCompNumbers: (customerId?: string) => Promise<AxiosResponse>;
  phones: any[];
}

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  importUsers: usersActions.importUsers,
  getCompNumbers: numbersActions.getCompNumbers
};

const mapStateToProps = ({ users, numbers }: { users: any, numbers: any }) => ({
  isLoading: users.isFetching,
  csvUploadSuccess: users.info,
  phones: _.map(numbers.phones, phone => phone.phone_number),
});

class ImportModal extends PureComponent<Props, State> {
  state = {
    csvData: [],
    csvUploadError: '',
    csvUploadSuccess: '',
    csvError: []
  }
  csvLink: any = React.createRef();
  emails: string[] = [];

  componentDidMount() {
    this.props.getCompNumbers();
    this.emails = [];
  }
  data: any = [

  ];

  handleImport = () => {
    this.props.importUsers(this.state.csvData.filter(csvData => csvData['exists'] === "True")).then((res: any) => {
      const csvError = [...this.state.csvData.filter(csvData => csvData['exists'] === "False"), ...res.data.data.rejected];
      this.setState({ csvData: [], csvError, csvUploadError: '' }, () => {
        if (this.state.csvError && this.state.csvError.length > 0) {
          setTimeout(() => {
            this.csvLink && this.csvLink.current && this.csvLink.current.link && this.csvLink.current.link.click();
          }, 1);
        }
      });
    });
  }

  handleDrop = (e: FileList) => {
    const reader = new FileReader();
    this.setState({ csvUploadSuccess: '' });
    reader.onload = () => {
      csvParse(reader.result, { trim: true, from: 2 }, (err?: Error, csv?: any) => {
        if (err) {
          console.log('err', err);
          this.setState({ csvData: [], csvUploadError: 'Please use CSV file to import contacts' });
          return;
        }
        this.emails = [];
        const csvData = (csv && csv.length > 0) ? csv.map(this.convertCSVLine) : [];
        if (csvData.length) {
          this.setState({ csvData, csvUploadError: '' });
        } else {
          this.setState({ csvUploadError: 'The file contains errors. Please provide valid phone numbers' });
        }
      });
    };

    reader.readAsText(e[0], 'UTF-8');
  }

  trimLine = (line: string[]): string[] => line.map((cell: string) => cell.trim());
  validateCSVLine = (line: string[]): boolean =>
    /^\d{10,11}$/.test(line[4]) &&
    EMAIL_REGEXP.test(line[0]);

  phoneNumberValidation = (phone_number: string) => /^\d{10,11}$/.test(phone_number);
  emailValidation = (email: string) => EMAIL_REGEXP.test(email);

  convertCSVLine = (line: string[]) => {
    let isValid = false;
    let reason = 'Invalid Phone Number.';
    if (this.phoneNumberValidation(line[4])) {
      isValid = this.props.phones.find(phone => phone === (line[4].length === 10 ? `1${line[4]}` : line[4]));
      reason = isValid ? '' : 'Phone Number Not Exits.'
      if (isValid) {
        isValid = false;
        reason = 'Invalid Email.';
        if (this.emailValidation(line[0])) {
          isValid = this.emails.indexOf(line[0]) != 0;
          this.emails.push(line[0]);
          reason = isValid ? '' : 'Duplicate Email.'
        }
      }
    }

    return {
      email: line[0],
      first_name: line[1] ? line[1] : '',
      last_name: line[2] ? line[2] : '',
      title: line[3] ? line[3] : '',
      phone_number: line[4].length === 10 ? `1${line[4]}` : line[4],
      exists: isValid ? "True" : "False",
      reason
    };
  };

  render() {
    return (
      <Modal title="Import Users" className='ImportModal'>
        <div className="content">
          <CSVLink
            data={this.state.csvError}
            filename={`user_import_error.csv`}
            className="hidden"
            ref={this.csvLink}
          ></CSVLink>
          {!!this.props.csvUploadSuccess && <span className="importSuccess">{this.props.csvUploadSuccess}</span>}
          <DragAndDrop handleDrop={this.handleDrop} csvData={this.state.csvData} error={this.state.csvUploadError} />
          <span className="csv-upload-hint">
            Format of CSV: one recipient per row with email, first name, last name, title,
            existing phone number. Download a sample template:
            <a href="https://messageviewminio-staging.brightlink.com/lol/user_import_template.csv">
              sample.csv
            </a>
            <br />
            {`Import only supports assigning Existing Numbers to users. Please add numbers to the ${Branding.APP_NAME} account before submitting the user import.`}
          </span>
          {/* <span className="importAlert"></span> */}
          <div className="flex">
            <ThemedButton disabled={!this.state.csvData.filter(csvData => csvData['exists'] === "True").length} className="btn-primary" onClick={this.handleImport}>Import</ThemedButton>
            <ThemedButton className="btn-secondary" onClick={this.props.closeModal}>Cancel</ThemedButton>
          </div>

          {this.props.isLoading &&
            <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: '#dedede44', top: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Loader />
            </div>
          }
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportModal as any);
