import React from "react";
import Modal from "../AbstractModal/AbstractModal";
import NumberAddForm from "~/components/forms/Number/NumberAdd";
import { connect } from "react-redux";
import './Number.scss';

function NumberAddModal() {
  return (
    <Modal title={`Add Number`} className='NumberModal'>
      <div><NumberAddForm /></div>
    </Modal>
  );
}

const mapStateToProps = (_state: any) => ({

});

export default connect(mapStateToProps)(NumberAddModal);