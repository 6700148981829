import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { API_URL } from '~/config';
import { withAuthorizationHeader } from '~/store';
import { connect } from 'react-redux';

const PartnerType = ({ partnerId, authenticatedUser }: any) => {
    const partnerTypes = [{
        value: 'White-Label',
        label: 'White-Label'
    }, {
        value: 'Agent',
        label: 'Agent'
    }];

    const [partnerType, setPartnerType] = useState(undefined);

    useEffect(() => {
        if (partnerId) {
            console.log('partnerId : ', partnerId);
            (async () => {
                await fetchReportType(partnerId);
            })();
        }
    }, [partnerId]);

    const fetchReportType = async (partnerId: any) => {
        try {
            const res: any = await Axios.get(`${API_URL}/company/${partnerId}/partner_setting`, withAuthorizationHeader(authenticatedUser.token));
            const partner_type = res.data.data.data.partnerSetting.partner_type;
            const partnerTypeValue: any = partnerTypes.find(partnerType => partnerType.value === partner_type);
            setPartnerType(partnerTypeValue);
        } catch (e) {
            console.log('Createing Error : ', e);
        }
    }

    const createOrUpdatePartnerSettings = async (partnerType: any) => {
        const payload = {
            partner_id: partnerId,
            partner_type: partnerType.value
        }
        try {
            await Axios.post(`${API_URL}/company/partner_setting`, payload, withAuthorizationHeader(authenticatedUser.token));
            setPartnerType(partnerType);
        } catch (e) {

        }
    }

    return (
        <Select
            name="status"
            label="Status"
            className="selector partnerType"
            onChange={(partnerType: any) => {
                (async () => {
                    await createOrUpdatePartnerSettings(partnerType);
                })();
            }}
            value={partnerType}
            options={partnerTypes}
            isSearchable={false}
        />
    )
}

const mapStateToProps = (state: any) => ({
    authenticatedUser: state.auth.authenticatedUser
});

export default connect(mapStateToProps)(PartnerType);
