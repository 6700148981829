import Axios, { AxiosError, AxiosResponse } from 'axios';
import { API_URL } from '../config';
import { Dispatch, AnyAction } from 'redux';
import { withAuthorizationHeader } from './index';

// ------------------------------------
// Constants
// ------------------------------------

const ASSETS_GET_DOWNLOAD_FAILURE = 'ASSETS_GET_DOWNLOAD_FAILURE';
const ASSETS_GET_DOWNLOAD_REQUEST = 'ASSETS_GET_DOWNLOAD_REQUEST';
const ASSETS_GET_DOWNLOAD_SUCCESS = 'ASSETS_GET_DOWNLOAD_SUCCESS';

const ASSETS_GET_UPLOAD_FAILURE = 'ASSETS_GET_UPLOAD_FAILURE';
const ASSETS_GET_UPLOAD_REQUEST = 'ASSETS_GET_UPLOAD_REQUEST';
const ASSETS_GET_UPLOAD_SUCCESS = 'ASSETS_GET_UPLOAD_SUCCESS';

const ASSETS_UPLOAD_FILE_FAILURE = 'ASSETS_UPLOAD_FILE_FAILURE';
const ASSETS_UPLOAD_FILE_REQUEST = 'ASSETS_UPLOAD_FILE_REQUEST';
const ASSETS_UPLOAD_FILE_SUCCESS = 'ASSETS_UPLOAD_FILE_SUCCESS';

const EXPORT_FAILURE = 'EXPORT_FAILURE';
const EXPORT_REQUEST = 'EXPORT_REQUEST';
const EXPORT_SUCCESS = 'EXPORT_SUCCESS';

// ------------------------------------
// Action Creators
// ------------------------------------

const getDownloadURLRequest = () => ({ type: ASSETS_GET_DOWNLOAD_REQUEST });
const getDownloadURLSuccess = (res: AxiosResponse) => ({ type: ASSETS_GET_DOWNLOAD_SUCCESS, payload: res.data && res.data.data });
const getDownloadURLFailure = (err: AxiosError) => ({ type: ASSETS_GET_DOWNLOAD_FAILURE, payload: err && err.response && err.response.data });

const getUploadURLRequest = () => ({ type: ASSETS_GET_UPLOAD_REQUEST });
const getUploadURLSuccess = (res: AxiosResponse) => ({ type: ASSETS_GET_UPLOAD_SUCCESS, payload: res.data && res.data.data });
const getUploadURLFailure = (err: AxiosError) => ({ type: ASSETS_GET_UPLOAD_FAILURE, payload: err && err.response && err.response.data });

const uploadFileRequest = () => ({ type: ASSETS_UPLOAD_FILE_REQUEST });
const uploadFileSuccess = (res: AxiosResponse) => ({ type: ASSETS_UPLOAD_FILE_SUCCESS, payload: res.data && res.data.data });
const uploadFileFailure = (err: AxiosError) => ({ type: ASSETS_UPLOAD_FILE_FAILURE, payload: err && err.response && err.response.data });

const exportRequest = () => ({ type: EXPORT_REQUEST });
const exportSuccess = () => ({ type: EXPORT_SUCCESS });
const exportFailure = (err: Error) => ({ type: EXPORT_FAILURE, payload: err });


// ------------------------------------
// Actions
// ------------------------------------

const getDownloadURL = (bucket: string = '', filename: string) => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  return new Promise(async (resolve, reject) => {
    if (authenticatedUser && authenticatedUser.token) {
      try {
        dispatch(getDownloadURLRequest());
        const res = await Axios.post(`${API_URL}/assets/download_url/lol/${filename}`, null, withAuthorizationHeader(authenticatedUser.token))
        dispatch(getDownloadURLSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(getDownloadURLFailure(err));
        reject(err);
      }
    }
  });
}

const getUploadURL = (bucket: string = '', filename: string) => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  return new Promise(async (resolve, reject) => {
    if (authenticatedUser && authenticatedUser.token) {
      try {
        dispatch(getUploadURLRequest());
        const res = await Axios.post(`${API_URL}/assets/upload_url/lol/${filename}`, null, withAuthorizationHeader(authenticatedUser.token))
        dispatch(getUploadURLSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(getUploadURLFailure(err));
        reject(err);
      }
    }
  });
}

const uploadFile = (file: any) => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  const { uploadURL } = getState().assets;
  return new Promise(async (resolve, reject) => {
    if (uploadURL && authenticatedUser && authenticatedUser.token) {
      try {
        dispatch(uploadFileRequest());
        const res = await Axios.put(uploadURL, file, {
          headers: {
            'Content-type': file.type
          }
        });
        dispatch(uploadFileSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(uploadFileFailure(err));
        reject(err);
      }
    }
  });
}

const uploadFileAndgetURL = (file: any) => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  return new Promise(async (resolve, reject) => {
    if (authenticatedUser && authenticatedUser.token) {
      try {
        const uploadURL = await Axios.post(`${API_URL}/assets/upload_url/lol/${file.name}`, null, withAuthorizationHeader(authenticatedUser.token))
        try {
          await Axios.put(uploadURL.data.data, file, {
            headers: {
              'Content-type': file.type
            }
          });
          try {
            const res = await Axios.post(`${API_URL}/assets/download_url/lol/${file.name}`, null, withAuthorizationHeader(authenticatedUser.token))
            resolve(res);
          } catch (err) {
            reject(err);
          }
        } catch (err) {
          reject(err);
        }
      } catch (err) {
        reject(err);
      }
    }
  });
}

const exportItems = (opt: any) => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  return new Promise(async (resolve, reject) => {
    if (authenticatedUser && authenticatedUser.token) {
      try {
        dispatch(exportRequest());
        fetch(`${API_URL}/export/${opt.url}`, {
          method: 'GET',
          ...withAuthorizationHeader(authenticatedUser.token)
        })
          .then((response: Response) => {
            if (response.ok) {
              return response.blob();
            }
            throw new Error('Error load CSV data');
          })
          .then((blob: Blob) => {
            dispatch(exportSuccess());
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = opt.fileName || 'export.csv';
            a.click();
            resolve(blob);
          });
      } catch (err) {
        dispatch(exportFailure(err));
        reject(err);
      }
    }
  });
}

export const assetsActions = {
  getDownloadURL,
  getUploadURL,
  uploadFile,
  exportItems,
  uploadFileAndgetURL
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState = {
  downloadURL: '',
  uploadURL: '',
  assets: [],
  errors: [],
  isFetching: false,
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case ASSETS_GET_DOWNLOAD_REQUEST:
    case ASSETS_GET_UPLOAD_REQUEST:
    case ASSETS_UPLOAD_FILE_REQUEST:
      return { ...state, isFetching: true };

    case ASSETS_GET_DOWNLOAD_FAILURE:
    case ASSETS_GET_UPLOAD_FAILURE:
    case ASSETS_UPLOAD_FILE_FAILURE:
      return { ...state, isFetching: false, errors: payload };

    case ASSETS_GET_DOWNLOAD_SUCCESS:
      return { ...state, isFetching: false, downloadURL: payload };
    case ASSETS_GET_UPLOAD_SUCCESS:
      return { ...state, isFetching: false, uploadURL: payload };

    case ASSETS_UPLOAD_FILE_SUCCESS:
      return { ...state, isFetching: false, uploadURL: '' };

    default:
      return state;
  }
}