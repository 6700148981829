import { AxiosResponse } from 'axios';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { assetsActions } from '~/store/assets';
import classNames from 'classnames';

import { usersActions } from '~/store/user';
import { modalActions } from '~/store/modal';
import { ThemedButton } from '../common/themed';
import Table from '../Table/Table';

import './CompanyAdministrators.scss';
import { administratorsActions } from '~/store/administrator';

interface StateProps {
  authenticatedUser: any;
  isAuthenticated?: boolean;
  administrators: any[];
}

interface DispatchProps {
  closeModal: () => void;
  deleteAdministrator: (administratorId: string, administrator_delete: boolean) => Promise<AxiosResponse>;
  listCustomerAdministrators: (customerId: string) => Promise<AxiosResponse>;
  setUser: (userId: string) => void;
  openModal: (modalId: string, modalProps?: any) => void;
}

interface OwnProps extends RouteComponentProps {
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {
}


const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  deleteAdministrator: administratorsActions.deleteAdmin,
  listCustomerAdministrators: administratorsActions.listCustomerAdministrators,
  setUser: usersActions.setUser,
  openModal: modalActions.openModal
}

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.auth.authenticatedUser,
  isAuthenticated: state.auth.isAuthenticated,
  administrators: state.administrators.administrators,
  delete_errors: state.administrators.delete_errors,
});


class CompanyAdministrators extends PureComponent<Props, State> {
  componentDidMount() {
    this.fetchAdministrators();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.isAuthenticated &&
      this.props.isAuthenticated !== prevProps.isAuthenticated
    ) {
      this.fetchAdministrators();
    }
  }

  fetchAdministrators = () => {
    const { customerId } = this.props.match.params as { customerId: string };
    if (customerId) {
      this.props.listCustomerAdministrators(customerId);
    }
  }

  openAdministratorModal = () => {
    this.props.openModal('ADMINISTRATOR_MODAL');
  }

  editAdministrator = (id: string) => {
    this.props.openModal('ADMINISTRATOR_MODAL', { administratorId: id });
  }

  deleteAdministrator = (id: string) => {
    this.props.deleteAdministrator(id, true).then(this.props.closeModal).then(this.fetchAdministrators);
  }

  render() {
    let tableData: any = [];
    if (this.props.administrators) {
      tableData = this.props.administrators.map(user => ([
        (user.first_name || user.name) + (user.last_name && ` ${user.last_name}`) + `${user.user_level}`,
        user.email,
        new Date(user.recent_activity),
        new Date(user.created),
        {
          buttons: [{
            classnames: ['link'],
            onClick: this.editAdministrator.bind(this, user.id),
            text: 'Edit',
          }, {
            classnames: ['link', 'archive'],
            onClick: () => this.props.openModal('CONFIRM_MODAL', {
              title: 'Delete Administrator',
              body: `Are you sure you want to delete <strong>${(user.first_name || user.name) + (user.last_name && ` ${user.last_name}`)}</strong> ?`,
              onYes: this.deleteAdministrator.bind(this, user.id),
              onNo: this.props.closeModal,
            }),
            text: 'Delete'
          }]
        }
      ]));
    }

    return (<>
      <div className='prt-companyUsers'>
        <div className="prt-actions">
          <ThemedButton className="btn-primary" onClick={this.openAdministratorModal}>Add Administrator</ThemedButton>
        </div>
      </div>
      <ul className="error-msg">
        {this.props.delete_errors &&
          this.props.delete_errors.map((error: string, i: number) => <li key={i}>{error}</li>)}
      </ul>
      <Table
        data={tableData}
        columns={[
          { name: 'Name', type: 'string' },
          { name: 'Email', type: 'string' },
          { name: 'Recent Activity', type: 'date' },
          { name: 'Added', type: 'date' },
          { name: '', type: 'buttons' },
        ]}
      />
    </>);
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanyAdministrators)
);
