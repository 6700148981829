import classnames from 'classnames';
import { History } from 'history';
import moment from 'moment';
import React, { ChangeEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import SearchColumn from '~/components/common/SearchColumn/SearchColumn';
import { statuses } from '~/store/user';
import './Table.scss';

interface DispatchProps {
  // logoutUser: (cb: Function) => void
};

interface StateProps {
  isAuthenticated?: boolean;
  currentUser: any;
  history: History;
};

interface OwnProps extends RouteComponentProps {
  columns: Column[];
  data: any[];
  className?: string;
  searchType?: string;
};

type Props = StateProps & DispatchProps & OwnProps;

type State = {

};

type Column = {
  type: string;
  name: string;
  access?: string[];
}


const mapDispatchToProps = {
}

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
  currentUser: state.auth.authenticatedUser,
});

class Table extends PureComponent<Props, State> {
  state = {
    sortColumn: -1,
    sortAscending: true,
    str: '',
    searchIsType: false
  }

  setSortColumn = (index: number) => {
    this.setState({ sortColumn: index });
  }

  setSearchStr = (e: ChangeEvent) => {
    e.stopPropagation();
    const { value } = e.target as HTMLInputElement;
    this.setState({ str: value });
  }

  filterRows = (rows: any[]) => {
    const { str } = this.state;
    return rows.filter((row: any) => {
      if (this.props.searchType === 'phone') {
        return `${row[0].data}`.replace(/\D/g,'').indexOf(str.replace(/\D/g,'')) > -1;
      } else {
        return `${(row[0].text ? row[0].text : row[0])}`.toLowerCase().indexOf(str.toLowerCase()) > -1;
      }
    });
  }

  render() {
    const user_level = this.props.currentUser && this.props.currentUser.user_level ? this.props.currentUser.user_level : '';
    const allowedColumns = this.props.columns.map((column: Column) => (column.access === undefined || column.access.includes(user_level)));
    const rows = this.filterRows([...this.props.data]);

    return (
      <table className={`prt-table ${this.props.className ? this.props.className : ''}`}>
        <thead>
          <tr>{this.props.columns.map((column: Column, i: number) =>
            allowedColumns[i] ? <th key={i} className={column.type} onClick={() => this.setSortColumn(i)}>{i === 0 ? <SearchColumn columnName={column.name} onSearchChange={(str: string) => this.setState({ str })} str={this.state.str} /> : column.name}</th> : null)}
          </tr>
        </thead>
        <tbody>{rows.map((row: any[], i: number) =>
          <tr key={i}>{row.map((cell: any, j: number) => {
            if (this.props.columns[j] && allowedColumns[j]) {
              switch (this.props.columns[j].type) {
                case 'date':
                  return <td key={j}>{cell ? moment(cell).format('MM/DD/YYYY hh:mmA') : null}</td>;
                case 'link':
                  return <td key={j}><Link to={cell.link}>{cell.text}</Link></td>;
                case 'props':
                  return <td key={j} {...cell.props}>{cell.data}</td>;
                case 'status':
                  const label = statuses[cell] ? statuses[cell] : cell ? cell : '';
                  return <td key={j}><span className={'prt-status-' + `${label}`.toLowerCase()}>{label.toUpperCase()}</span></td>
                case 'buttons':
                  return <td key={j}>
                    {cell.buttons.map((button: any, key: number) => button ? <button
                      key={key}
                      onClick={button.onClick}
                      className={classnames(button.classnames)}
                    >{button.text}</button> : null)}
                  </td>
                default:
                  return <td key={j}>{`${cell}`}</td>
              }
            } else {
              return null;
            }
          })}
          </tr>)}
        </tbody>
      </table>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Table));
