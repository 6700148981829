import React, { useEffect, useRef, useState } from 'react'
import './Report.scss';
import { ThemedButton } from '~/components/common/themed';
import { connect } from 'react-redux';
import Axios from 'axios';
import { API_URL } from '~/config';
import { withAuthorizationHeader } from '~/store';
import Loader from '~/components/common/Loader/Loader';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import DownloadIcon from "../../assets/downlaod.svg";
import HelpIcon from "../../assets/help.svg";
import { CSVLink } from "react-csv";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const REPORTTYPE: any = {
  ACCOUNT: {
    title: 'Account Report',
    legends: ['Users', 'Numbers'],
    info: `Peak number of users and phone numbers on the account during a specified time period.`,
    download: {
      label: 'Account Report',
      value: 'Account_REPORT'
    }
  },
  SEGMENT: {
    title: 'Segment Report',
    legends: ['SMS', 'MMS'],
    info: `Number of SMS segments and MMS segments processed outbound during a specified time period. 
    Any outbound message over 70 characters is split into multiple segments based on the the total length of characters in the message.`,
    download: {
      label: 'Segment Report',
      value: 'SEGMENT_REPORT'
    }
  },
  MESSAGE: {
    title: 'Messaging Flow',
    legends: ['Inbound', 'Outbound'],
    info: `Total number of inbound and outbound SMS and MMS messages processed during a specified time period.`,
    download: {
      label: 'Message Flow Report',
      value: 'MESSAGE_REPORT'
    }
  },
  BLAST: {
    title: 'Blast Compaigns',
    legends: ['Delivered', 'Rejected'],
    info: `Total number of blast messages processed outbound from a Blast campaign during a specified time period.`,
    download: {
      label: 'Blast Report',
      value: 'BLAST_REPORT'
    }
  }
}

const INITIAL_STATE = { diagramData: {}, userPhoneReportData: [], segmentReportData: [], blastReportData: [], messageReportData: [], phoneNumbers: [], lineGraphData: [] };
const tansformPhoneNumbers = (phoneNumbers: any) => {
  const options = [
    {
      label: 'Select All',
      value: undefined
    }
  ];
  options.push(...phoneNumbers?.map((phoneNumber: any) => ({
    value: phoneNumber,
    label: phoneNumber
  })));
  return options;
}

const reportType = [
  {
    label: 'Daily',
    value: 'DAILY'
  },
  {
    label: 'Monthly',
    value: 'MONTHLY'
  },
  {
    label: 'Yearly',
    value: 'YEARLY'
  }
];

const lineGraphOptions = [
  {
    label: 'User Peak',
    value: JSON.stringify({
      id: 1,
      color: '#004B87',
      key: 'user_peak'
    })
  },
  {
    label: 'User Created',
    value: JSON.stringify({
      id: 2,
      color: '#4DB0FF',
      key: 'user_created_count'
    })
  },
  {
    label: 'User Deleted',
    value: JSON.stringify({
      id: 3,
      color: '#B31729',
      key: 'user_deleted_count'
    })
  },
  {
    label: 'Phone Peak',
    value: JSON.stringify({
      id: 4,
      color: '#ED8B00',
      key: 'phone_peak'
    })
  },
  {
    label: 'Phone Created',
    value: JSON.stringify({
      id: 5,
      color: '#FFCA80',
      key: 'phone_created_count'
    })
  },
  {
    label: 'Phone Deleted',
    value: JSON.stringify({
      id: 6,
      color: '#F83800',
      key: 'phone_deleted_count'
    })
  }
];

const Report = ({ customer_id, authenticatedUser }: any) => {
  const csvLinkRef = useRef();
  const currentDate = new Date();
  const [reload, setReload] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [reportData, setReportData] = useState(INITIAL_STATE);
  const [selectedReportType, setSelectedReportType] = useState(REPORTTYPE.ACCOUNT)
  const [startDate, setStartDate] = useState(new Date(moment().subtract(1, "months").format('YYYY-MM-DD')));
  const [endDate, setEndDate] = useState(currentDate);
  const [userReportData, setUserReportData] = useState();
  const [csvData, setCSVData] = useState({});

  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState({
    label: 'Select All',
    value: undefined
  });

  const [selectedUserReportType, setSelectedUserReportType] = useState({
    label: 'Monthly',
    value: 'MONTHLY'
  });

  const [selectedLineGraphType, setSelectedLineGraphType] = useState([
    lineGraphOptions[0], lineGraphOptions[3]]);

  useEffect(() => {
    if (customer_id) {
      const fetchData = async () => {
        await fetchReportData({
          customer_id,
          from: moment(startDate).format('YYYY-MM-DD'),
          to: moment(endDate).format('YYYY-MM-DD'),
          phone_number: (selectedPhoneNumber?.value) || undefined,
          report_type: selectedUserReportType?.value
        });
      }
      fetchData();
    }
  }, [customer_id, reload, startDate, endDate, selectedPhoneNumber, selectedUserReportType]);

  useEffect(() => {
    if (csvData.csvData && csvLinkRef) {
      csvLinkRef.current.link.click();
    }
  }, [csvData]);

  useEffect(() => {
    const lineData = reportData.lineGraphData;
    const datasets = (selectedLineGraphType || []).map((lineGraph: any) => {
      const { id, color, key } = JSON.parse(lineGraph.value);
      return {
        id,
        label: lineGraph.label,
        data: lineData.map((data: any) => data[key]),
        backgroundColor: [color],
        borderColor: color,
        borderWidth: 2
      }
    }).sort((a, b) => a.id > b.id ? 1 : -1)
    const userData = {
      labels: lineData.map((data: any) => data.label),
      datasets,
      tension: 0.1
    };
    setUserReportData(userData);
  }, [selectedLineGraphType, reportData]);

  const handleSelectChange = (phoneNumber: any) => {
    setSelectedPhoneNumber(phoneNumber);
  }

  const Tooltip = ({ id, message }: any) => {
    return (
      <ReactTooltip
        className="tooltipContainer"
        id={id}
        effect="solid"
        place="top"
        offset={{ left: 0 }}
      >
        <span className="tooltipContent">{message}</span>
      </ReactTooltip>
    );
  };

  const FilterPanel = () => {
    return (
      <div className='filterPanel'>
        <div className='labelC'>
          <label>From:</label>
          <DatePicker
            selected={startDate}
            onChange={(date: Date) => {
              setStartDate(date)
            }}
            showYearDropdown
            showMonthDropdown
            scrollableMonthYearDropdown
            maxDate={endDate}
          />
        </div>
        <div className='labelC'>
          <label>To:</label>
          <DatePicker
            selected={endDate}
            onChange={(date: Date) => {
              setEndDate(date)
            }}
            minDate={startDate}
            showYearDropdown
            showMonthDropdown
            scrollableMonthYearDropdown
            maxDate={currentDate}
          />
        </div>
        {selectedReportType.title !== REPORTTYPE.ACCOUNT.title ? <div className='labelC'>
          <label>Numbers:</label>
          <Select
            name="PhoneNumber"
            label="PhoneNumber"
            className="selector"
            onChange={handleSelectChange}
            value={selectedPhoneNumber}
            options={tansformPhoneNumbers(reportData.phoneNumbers)}
            isSearchable={false}
          />
        </div> :
          <div className='labelC'>
            <label>Report Type:</label>
            <Select
              name="ReportType"
              label="ReportType"
              className="selector"
              onChange={(userReportType: any) => setSelectedUserReportType(userReportType)}
              value={selectedUserReportType}
              options={reportType}
              isSearchable={false}
            />
          </div>
        }
        {/* <div className='filterButton'>
          <ThemedButton className='btn-primary' onClick={() => setReload(!reload)}>Apply</ThemedButton>
        </div> */}
      </div>
    );
  };

  const fetchReportData = async (payload: any) => {
    try {
      setIsFetching(true);
      const res: any = await Axios.post(`${API_URL}/customer/reports`, payload, withAuthorizationHeader(authenticatedUser.token));
      setReportData({
        ...res.data.reportData
      });
    } catch (e) {
      console.log('Createing Error : ', e);
    }
    setIsFetching(false);
  }
  if (isFetching) {
    return <Loader />;
  }

  const {
    delivered_count = 0,
    inbound_count = 0,
    mms_segment = 0,
    outbound_count = 0,
    phone_count = 0,
    rejected_count = 0,
    sms_segment = 0,
    user_count = 0
  }: any = reportData.diagramData;

  const renderColumnStat = (datas: any, reportType: any) => {
    return (
      <div className={`row ${selectedReportType === reportType ? 'active relative' : 'relative'}`} onClick={() => setSelectedReportType(reportType)}>
        {datas.map((data: any) => (
          <div className='column'>
            <h5>{data.key}</h5>
            <span>{data.value}</span>
          </div>
        ))}
        <div> <HelpIcon className='helpIcon' data-tip
          data-for={reportType.title} /></div>

        <Tooltip id={reportType.title} message={reportType.info} />
      </div>
    );
  }

  const LineChart = () => {
    if (userReportData) {
      return <Line data={userReportData} />
    }
    return <></>
  }

  const renderDiagramData = () => {
    let data: any = [];
    switch (selectedReportType.title) {
      case REPORTTYPE.ACCOUNT.title: {
        // data = reportData.userPhoneReportData.map((dt: any) => {
        //   const [year, month] = dt.created_date.split('-');
        //   return {
        //     heading: MONTHS[Number(month)],
        //     subHeading: year,
        //     inCount: +dt.user_count,
        //     outCount: +dt.phone_count,
        //     total: (+dt.user_count + +dt.phone_count)
        //   }
        // });
        // break;
        return <LineChart />;
      }
      case REPORTTYPE.SEGMENT.title: {
        data = reportData.segmentReportData.map((dt: any) => {
          return {
            heading: moment(dt.sent_at_truncated_to_day)
              .utc()
              .format("MM/DD"),
            inCount: +dt.sms_segment,
            outCount: +dt.mms_segment,
            total: (+dt.sms_segment + +dt.mms_segment)
          }
        });
        break;
      }
      case REPORTTYPE.BLAST.title: {
        data = reportData.blastReportData.map((dt: any) => {
          return {
            heading: dt.name,
            subHeading: moment(dt.sent_at_truncated_to_day)
              .utc()
              .format("MM/DD"),
            inCount: +dt.delivered_count,
            outCount: +dt.rejected_count,
            total: (+dt.delivered_count + +dt.rejected_count)
          }
        });
        break;
      }
      case REPORTTYPE.MESSAGE.title: {
        data = reportData.messageReportData.map((dt: any) => {
          return {
            heading: moment(dt.sent_at_truncated_to_day)
              .utc()
              .format("MM/DD"),
            inCount: +dt.inbound_count,
            outCount: +dt.outbound_count,
            total: (+dt.inbound_count + +dt.outbound_count)
          }
        });;
        break;
      }
    }

    const maxWidth = Math.max(
      ...data.map((day: any) => day.total)
    )

    return (
      data.length > 0 ? data.map((dt: any, index: number) => {
        return (<div className='diagramContainer'>
          <div className='barTitle'>
            {dt.heading}
            <div className='subHeading'>{dt.subHeading}</div>
          </div>
          <div className='chart'>
            <div className='barChart' style={{
              borderLeft: "1px solid #dfdfdf",
              width: `${(dt.total * 100) / maxWidth}%`,
            }}>
              <div style={{
                width: `${(dt.inCount / dt.total) * 100}%`
              }} className='barInbound'
                data-tip
                data-for={`incount-${index}`}
              >
                <Tooltip id={`incount-${index}`} message={dt.inCount} />
              </div>
              <div style={{
                width: `${(dt.outCount / dt.total) * 100}%`
              }} className='barOutbound'
                data-tip
                data-for={`outcount-${index}`}>
                <Tooltip id={`outcount-${index}`} message={dt.outCount} />
              </div>
            </div>
            <span className='total'>{dt.total}</span>
          </div>
        </div>)
      }) :
        <div className="no-data">
          <span>
            No data for selected period
          </span>
        </div>
    )
  }

  const downloadReport = async () => {
    try {
      if (selectedReportType.download.value === REPORTTYPE.ACCOUNT.download.value) {
        const csvData = [
          [
            "Date",
            "User Peak",
            "User Created",
            "User Deleted",
            "Number Peak",
            "Number Created",
            "Number Deleted"
          ],
        ];
        reportData.lineGraphData.forEach(({ label, user_peak, user_created_count, user_deleted_count, phone_peak, phone_created_count, phone_deleted_count }: any) => {
          csvData.push([
            label,
            user_peak,
            user_created_count,
            user_deleted_count,
            phone_peak,
            phone_created_count,
            phone_deleted_count
          ]);
        });

        setCSVData(
          {
            csvData,
            file_name: "Account_Report",
          }
        );
        return;
      }
      if (selectedReportType.download.value === REPORTTYPE.SEGMENT.download.value) {
        const csvData = [
          [
            "SMS Segments",
            "MMS Segments"
          ],
        ];
        csvData.push([
          sms_segment,
          mms_segment
        ]);
        setCSVData(
          {
            csvData,
            file_name: "Segment_Report",
          }
        );
        return;
      }
      const payload = {
        customer_id,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD'),
        phone_number: (selectedPhoneNumber?.value) || undefined,
        report_type: selectedReportType.download.value
      };
      console.log('Download Report : ', payload);
      const res: any = await Axios.post(`${API_URL}/csv-data/customer/reports`, payload, withAuthorizationHeader(authenticatedUser.token));
      const { data: reportDatas, status } = res;
      if (status === 200) {
        switch (selectedReportType.download.value) {
          case 'BLAST_REPORT': {
            const csvData = [
              [
                "Time Stamp",
                "Blast Name",
                "Sender Name",
                "From Phone Number",
                "To Phone Number",
                "Message",
                "Status",
              ],
            ];
            reportDatas.data.forEach((reportData: any) => {
              const {
                sent_at_truncated_to_day,
                blast_name,
                sender_name,
                text_content,
                from_number,
                blast_contact_reject,
                blast_contact_success,
              } = reportData;
              if (blast_contact_reject) {
                blast_contact_reject.split(",").forEach((reject: any) => {
                  csvData.push([
                    moment(sent_at_truncated_to_day).format(
                      "MM/DD/YYYY hh:mm:ss A"
                    ),
                    blast_name,
                    sender_name,
                    from_number,
                    reject,
                    text_content,
                    "Rejected",
                  ]);
                });
              }
              if (blast_contact_success && blast_contact_success.length > 0) {
                blast_contact_success.split(",").forEach((success: any) => {
                  csvData.push([
                    moment(sent_at_truncated_to_day).format(
                      "MM/DD/YYYY hh:mm:ss A"
                    ),
                    blast_name,
                    sender_name,
                    from_number,
                    success,
                    text_content,
                    "Delivered",
                  ]);
                });
              }
            });
            setCSVData(
              {
                csvData,
                file_name: "Blast_Report",
              }
            );
            break;
          }
          case "MESSAGE_REPORT": {
            const csvData: any = [
              [
                "Time Stamp",
                "Sender Name",
                "From Phone Number",
                "To Phone Number",
                "Message",
                "Type",
                "Status",
              ],
            ];
            reportDatas.data.forEach((reportData: any) => {
              const {
                from,
                to,
                sent_at_truncated_to_day,
                name,
                text_content,
                type,
                status,
              } = reportData;
              csvData.push([
                moment(sent_at_truncated_to_day).format(
                  "MM/DD/YYYY hh:mm:ss A"
                ),
                name,
                from,
                to,
                text_content,
                type,
                status,
              ]);
            });
            setCSVData(
              {
                csvData,
                file_name: "Message_Report",
              }
            );
            break;
          }
        }
      } else {
        console.log("Error : ", reportDatas.error_message);
      }
    } catch (err) {
      console.log("err.response.data : ", err);
    }
  };

  const RenderHeader = () => {

    if (selectedReportType.title === REPORTTYPE.ACCOUNT.title) {
      return (
        <div className='wd70'>
          <Select
            isMulti
            name="lineGraphType"
            label="lineGraphType"
            className="selector"
            onChange={(lineGraphType: any) => setSelectedLineGraphType(lineGraphType)}
            value={selectedLineGraphType}
            options={lineGraphOptions}
            isSearchable={false}
          />
        </div>
      )
    }

    return (
      <div className='legend'>
        <span className='inbound'>{selectedReportType.legends[0]}</span>
        <span className='outbound'>{selectedReportType.legends[1]}</span>
      </div>);
  }

  return (
    <>
      <div className='prt-actions'>
        <CSVLink
          data={csvData.csvData || []}
          filename={`${csvData.file_name}.csv`}
          className="hidden"
          ref={csvLinkRef}
        ></CSVLink>
        <ThemedButton className='btn-primary' onClick={() => setReload(!reload)}>Refresh</ThemedButton>
      </div>
      <FilterPanel />
      <div className='reportContainer'>
        <div className='reportDiagram'>
          <div className='dataContainer'>
            <div className='mg20'>
              <div className='header'>
                <header>
                  {selectedReportType.title}
                </header>
                <RenderHeader />
              </div>
              <div className='diagramBox'>
                {renderDiagramData()}
              </div>
              {/* <div style={{ width: 700 }}>
                <LineChart />
              </div> */}
            </div>
          </div>
        </div>
        <div className='reportStat'>
          <div className='dataContainer statPanel'>
            <div className='reportData'>
              {renderColumnStat([{ key: 'Users', value: user_count }, { key: 'Numbers', value: phone_count }], REPORTTYPE.ACCOUNT)}
              {renderColumnStat([{ key: 'Inbound', value: inbound_count }, { key: 'Outbound', value: outbound_count }], REPORTTYPE.MESSAGE)}
              {renderColumnStat([{ key: 'SMS Segments', value: sms_segment }, { key: 'MMS Segments', value: mms_segment }], REPORTTYPE.SEGMENT)}
              {renderColumnStat([{ key: 'Blasts Delivered', value: delivered_count }, { key: 'Blasts Rejected', value: rejected_count }], REPORTTYPE.BLAST)}
            </div>
            <div className='downloadSection'>
              <DownloadIcon className='download-cursor' onClick={downloadReport} />
              <div
                className="message-dropdown"
              >
                {selectedReportType.download.label}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  customer_id: state.customers.customer.id,
  authenticatedUser: state.auth.authenticatedUser
});

export default connect(mapStateToProps)(Report);