import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { API_FRONTEND_ACCESS_URL, CommandcenterAPISidebar } from './APIDocConstants';
import './APIDocumentationView.scss';
import { Branding } from "~/branding";

interface OwnProps { }

interface DispatchProps { }

interface StateProps { }

type Props = StateProps & DispatchProps & OwnProps;

type State = {}

const mapDispatchToProps = {};

const mapStateToProps = (state: any) => ({});

const { Authentication } = CommandcenterAPISidebar;
const { COMMANDCENTER_API_URL, MESSAGEVIEW_API_URL } = API_FRONTEND_ACCESS_URL;

class APIDocumentationView extends PureComponent<Props, State> {

  componentDidMount() {

  }


  render() {
    return (
      <div className='prt'>
        <Header />
        <main className="prt-body">
          <div className="container">
            <h2 className="title">{`API ${Branding.ADMIN_APP_NAME}`}</h2>
            <div className="list">
              <h3>APIs</h3>
              <div style={{ padding: 20 }}>
                <section className="panel-section">
                  <h2 className="panel-title">{`${Branding.ADMIN_APP_NAME} APIs`}</h2>
                  <div className="panel panel-50">
                    <div className="panel-body">
                      <p className="info">{`Access powerful ${Branding.ADMIN_APP_NAME}`}<br />
                        services quickly and easily.
                      </p>
                      <div className="btn">
                        <a href={`${COMMANDCENTER_API_URL}#${Authentication}`} target="_blank" className="btn-ouline-primary">Launch</a>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="panel-section">
                  <h2 className="panel-title">{`${Branding.APP_NAME} APIs`}</h2>
                  <div className="panel panel-50">
                    <div className="panel-body">
                      <p className="info">{`Access powerful ${Branding.APP_NAME}`}<br />
                        services quickly and easily.
                      </p>
                      <div className="btn">
                        <a href={`${MESSAGEVIEW_API_URL}#${Authentication}`} target="_blank" className="btn-ouline-primary">Launch</a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(APIDocumentationView as any);