import React, { ChangeEvent, PureComponent } from 'react';
import './ThemedCheckbox.scss';
import { connect } from 'react-redux';

interface StateProps {
  wl: any;
}

interface OwnProps {
  id: string;
  text: string;
  checked: boolean;
  onChange: (e: ChangeEvent) => void;
}

type Props = StateProps & OwnProps;

const mapStateToProps = (state: any) => ({
  wl: state.users.wl
});

class ThemedCheckbox extends PureComponent<Props> {
  render() {
    const brandColor = (this.props.wl && this.props.wl.primary_css_color) ? this.props.wl.primary_css_color : false;
    return (
      <React.Fragment>
        <input
          checked={!!this.props.checked}
          id={this.props.id}
          onChange={this.props.onChange}
          type='checkbox'
        />
        <label htmlFor={this.props.id}>
          <span style={brandColor && !!this.props.checked ? {backgroundColor: brandColor, borderColor: brandColor} : {}}></span>
          {this.props.text}
        </label>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(ThemedCheckbox);
