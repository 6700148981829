import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

interface StateProps {
  wl: any;
}
interface DispatchProps {

}
interface OwnProps {
  className: string;
  checked: boolean;
  key: any;
  onClick?: any;
  'data-tab-id': any;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: any) => ({
  wl: state.users.wl
});

class ThemedSwitch extends PureComponent<Props> {
  render() {
    const brandColor = (this.props.wl && this.props.wl.primary_css_color) ? this.props.wl.primary_css_color : false;

    let SelectWrapper = styled.div`
      position: relative;
      select:focus {
        background: #fff;
        border-color: ${brandColor};
      }
      ::before {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
      }
    `;

    return (
      <SelectWrapper>
        <select {...this.props} >{this.props.children}</select>
      </SelectWrapper>
    );
  }
}

export default connect(mapStateToProps)(ThemedSwitch);