import Axios, { AxiosError, AxiosResponse } from 'axios';
import { API_URL } from '../config';
import { Dispatch, AnyAction } from 'redux';
import { withAuthorizationHeader } from './index';
import { MODAL_HIDE } from './modal';

// ------------------------------------
// Constants
// ------------------------------------
const PARTNER_CREATE_FAILURE = 'PARTNER_CREATE_FAILURE';
const PARTNER_CREATE_REQUEST = 'PARTNER_CREATE_REQUEST';
const PARTNER_CREATE_SUCCESS = 'PARTNER_CREATE_SUCCESS';

const PARTNER_DELETE_FAILURE = 'PARTNER_DELETE_FAILURE';
const PARTNER_DELETE_REQUEST = 'PARTNER_DELETE_REQUEST';
const PARTNER_DELETE_SUCCESS = 'PARTNER_DELETE_SUCCESS';

const PARTNER_GET_FAILURE = 'PARTNER_GET_FAILURE';
const PARTNER_GET_REQUEST = 'PARTNER_GET_REQUEST';
const PARTNER_GET_SUCCESS = 'PARTNER_GET_SUCCESS';

const PARTNER_LIST_FAILURE = 'PARTNER_LIST_FAILURE';
const PARTNER_LIST_REQUEST = 'PARTNER_LIST_REQUEST';
const PARTNER_LIST_SUCCESS = 'PARTNER_LIST_SUCCESS';

const CPAAS_ACCOUNT_LIST_FAILURE = 'CPAAS_ACCOUNT_LIST_FAILURE';
const CPAAS_ACCOUNT_LIST_REQUEST = 'CPAAS_ACCOUNT_LIST_REQUEST';
const CPAAS_ACCOUNT_LIST_SUCCESS = 'CPAAS_ACCOUNT_LIST_SUCCESS';

const PARTNER_UPDATE_FAILURE = 'PARTNER_UPDATE_FAILURE';
const PARTNER_UPDATE_REQUEST = 'PARTNER_UPDATE_REQUEST';
const PARTNER_UPDATE_SUCCESS = 'PARTNER_UPDATE_SUCCESS';

const PARTNER_SET = 'PARTNER_SET';

export interface IPartnerForm {
  isAttachmentsOpen: boolean,
  isContactsOpen: boolean,
  form: {
    name: string,
    email: string,
    status: number,
    user_level: string,
    cpaas_account_id: number,
    reseller: {
      address: string,
      city: string,
      state: string,
      zip: string
    },
    contact: {
      admin_contact: string,
      admin_email: string,
      admin_phone: string,
      billing_contact: string,
      billing_email: string,
      billing_phone: string,
      primary_contact: string,
      primary_email: string,
      primary_phone: string,
    }
  }
}

export interface IPortalUserForm {
  id: string;
  name: string;
  email: string;
  user_level: string;
  reseller?: any;
}

// ------------------------------------
// Action Creators
// ------------------------------------
const createPartnerRequest = () => ({ type: PARTNER_CREATE_REQUEST });
const createPartnerSuccess = (res: AxiosResponse) => ({ type: PARTNER_CREATE_SUCCESS, payload: res.data });
const createPartnerFailure = (err: AxiosError) => ({ type: PARTNER_CREATE_FAILURE, payload: err && err.response && err.response.data });

const deletePartnerRequest = () => ({ type: PARTNER_DELETE_REQUEST });
const deletePartnerSuccess = (userId: string) => ({ type: PARTNER_DELETE_SUCCESS, payload: userId });
const deletePartnerFailure = (err: AxiosError) => ({ type: PARTNER_DELETE_FAILURE, payload: err && err.response && err.response.data });

const getPartnerRequest = () => ({ type: PARTNER_GET_REQUEST });
const getPartnerSuccess = (res: AxiosResponse) => ({ type: PARTNER_GET_SUCCESS, payload: res.data });
const getPartnerFailure = (err: AxiosError) => ({ type: PARTNER_GET_FAILURE, payload: err && err.response && err.response.data });

const listPartnersRequest = () => ({ type: PARTNER_LIST_REQUEST });
const listPartnersSuccess = (res: AxiosResponse) => ({ type: PARTNER_LIST_SUCCESS, payload: res.data });
const listPartnersFailure = (err: AxiosError) => ({ type: PARTNER_LIST_FAILURE, payload: err && err.response && err.response.data });

const updatePartnerRequest = () => ({ type: PARTNER_UPDATE_REQUEST });
const updatePartnerSuccess = (res: AxiosResponse) => ({ type: PARTNER_UPDATE_SUCCESS, payload: res.data });
const updatePartnerFailure = (err: AxiosError) => ({ type: PARTNER_UPDATE_FAILURE, payload: err && err.response && err.response.data });

const setActivePartner = (partner: IPartnerForm['form']) => ({ type: PARTNER_SET, payload: partner });

const listCPAASAccountRequest = () => ({ type: CPAAS_ACCOUNT_LIST_REQUEST });
const listCPAASAccountSuccess = (res: AxiosResponse) => ({ type: CPAAS_ACCOUNT_LIST_SUCCESS, payload: res.data });
const listCPAASAccountFailure = (err: AxiosError) => ({ type: CPAAS_ACCOUNT_LIST_FAILURE, payload: err && err.response && err.response.data });

// ------------------------------------
// Actions
// ------------------------------------
const createPartner = (form: IPortalUserForm) => (dispatch: Dispatch, getState: () => any) => {
  return new Promise((resolve, reject) => {
    const { authenticatedUser } = getState().auth;
    if (authenticatedUser && authenticatedUser.token && authenticatedUser.user_level === 'administrator') {
      // form.reseller.zip = form.reseller.zip || "0";
      dispatch(createPartnerRequest());
      Axios.post(`${API_URL}/users`, form, withAuthorizationHeader(authenticatedUser.token))
        .then(res => {
          dispatch(createPartnerSuccess(res));
          resolve(res);
        })
        .catch(err => {
          dispatch(createPartnerFailure(err));
          reject(err);
        });
    }
  });
}

/**
 * Delete a particular partner. Admin-only method.
 * @function updatePartner
 * @param partnerId {String} Partner ID
 * @param form {Object} Partner form with new data
 */
const deletePartner = (partnerId: string) => async (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  if (authenticatedUser && authenticatedUser.token && authenticatedUser.user_level === 'administrator') {
    try {
      dispatch(deletePartnerRequest());
      await Axios.delete(`${API_URL}/users/${partnerId}`, withAuthorizationHeader(authenticatedUser.token));
      dispatch(deletePartnerSuccess(partnerId));
    } catch (err) {
      dispatch(deletePartnerFailure(err));
    }
  }
}


const getPartner = (partnerId: string) => async (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  if (authenticatedUser && authenticatedUser.token) {
    try {
      dispatch(getPartnerRequest());
      const res = await Axios.get(`${API_URL}/partners/${partnerId}`, withAuthorizationHeader(authenticatedUser.token))
      dispatch(getPartnerSuccess(res));
    } catch (err) {
      dispatch(getPartnerFailure(err));
    }
  }
}

const setPartner = (partnerId: string) => async (dispatch: Dispatch, getState: () => any) => {
  const { partners } = getState().partners;
  if (!partners.partner) {
    getPartner(partnerId)(dispatch, getState);
  } else {
    dispatch(setActivePartner(partners.partner));
  }
}

const clearPartner = () => async (dispatch: Dispatch) => {
  dispatch(setActivePartner(null));
}

/**
 * Fetch a list of partners. Admin-only method.
 * @function listPartners
 */
const listPartners = () => async (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  if (authenticatedUser && authenticatedUser.token && authenticatedUser.user_level === 'administrator') {
    try {
      dispatch(listPartnersRequest());
      const res = await Axios.get(`${API_URL}/partners`, withAuthorizationHeader(authenticatedUser.token));
      dispatch(listPartnersSuccess(res));
    } catch (err) {
      dispatch(listPartnersFailure(err));
    }
  }
}

/**
 * Update a particular partner. Admin-only method.
 * @function updatePartner
 * @param partnerId {String} Partner ID
 * @param form {Object} Partner form with new data
 */
const updatePartner = (partnerId: string, form: IPortalUserForm) => async (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  if (authenticatedUser && authenticatedUser.token && authenticatedUser.user_level === 'administrator') {
    try {
      dispatch(updatePartnerRequest());
      const res = await Axios.put(`${API_URL}/partners/${partnerId}`, form, withAuthorizationHeader(authenticatedUser.token));
      dispatch(updatePartnerSuccess(res));
    } catch (err) {
      dispatch(updatePartnerFailure(err));
    }
  }
}

/**
 * Fetch a list of CPAAS Account. Admin-only method.
 * @function listOfCPAASAccounts
 */
const listCPAASAccounts = () => async (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().auth;
  if (authenticatedUser && authenticatedUser.token) {
    try {
      dispatch(listCPAASAccountRequest());
      const res = await Axios.get(`${API_URL}/cpaas-account`, withAuthorizationHeader(authenticatedUser.token));
      dispatch(listCPAASAccountSuccess(res));
    } catch (err) {
      dispatch(listCPAASAccountFailure(err));
    }
  }
}

export const partnersActions = {
  createPartner,
  deletePartner,
  getPartner,
  setPartner,
  clearPartner,
  listPartners,
  updatePartner,
  listCPAASAccounts
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState = {
  errors: [],
  isFetching: false,
  partner: null,
  partners: [],
  cpaasAccounts: []
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case PARTNER_CREATE_REQUEST:
    case PARTNER_DELETE_REQUEST:
    case PARTNER_GET_REQUEST:
    case PARTNER_LIST_REQUEST:
    case PARTNER_UPDATE_REQUEST:
    case CPAAS_ACCOUNT_LIST_REQUEST:
      return { ...state, isFetching: true, errors: [] };

    case PARTNER_CREATE_FAILURE:
    case PARTNER_DELETE_FAILURE:
    case PARTNER_GET_FAILURE:
    case PARTNER_LIST_FAILURE:
    case PARTNER_UPDATE_FAILURE:
    case CPAAS_ACCOUNT_LIST_FAILURE:
      return { ...state, isFetching: false, errors: payload && payload.messages ? payload.messages : payload };

    case PARTNER_CREATE_SUCCESS:
      return { ...state, isFetching: false, partners: [payload.data, ...state.partners] };

    case PARTNER_GET_SUCCESS:
      return { ...state, isFetching: false, partner: payload };

    case PARTNER_LIST_SUCCESS:
      return { ...state, isFetching: false, partners: payload };

    case PARTNER_UPDATE_SUCCESS:
      return { ...state, isFetching: false, partners: state.partners.map((partner: IPortalUserForm) => partner.id === payload.id ? payload : partner) };

    case PARTNER_DELETE_SUCCESS:
      return { ...state, isFetching: false, partners: state.partners.filter((partner: IPortalUserForm) => partner.id !== payload) };

    case PARTNER_SET:
      return { ...state, partner: payload };

    case MODAL_HIDE:
      return { ...state, errors: [] };

    case CPAAS_ACCOUNT_LIST_SUCCESS:
      return { ...state, isFetching: false, cpaasAccounts: payload };

    default:
      return state;
  }
}
