import React from "react";
import Modal from "../AbstractModal/AbstractModal";
import OrderForm from "~/components/forms/OrderForm/OrderForm";
import './OrderModal.scss';
import { connect } from "react-redux";

function UserModal({ userId }: { userId: string }) {
  return (
    <Modal title={`Add Order`} className='OrderModal'>
      <div><OrderForm /></div>
    </Modal>
  );
}

const mapStateToProps = (_state: any) => ({

});

export default connect(mapStateToProps)(UserModal);