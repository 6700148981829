import React from 'react';
import { connect } from 'react-redux';
import { Branding } from "~/branding";

interface StateProps {
  wl: any;
}

const mapStateToProps = (state: any) => ({
  wl: state.users.wl
});

class Logo extends React.PureComponent<StateProps> {

  render() {
    return this.props.wl && this.props.wl.logo_url ? <img src={this.props.wl.logo_url} /> : <img src={Branding.COMPANY_LOGO} alt={`${Branding.COMPANY_NAME} Portal Logo`} style={{ "width": "50%" }} />;
  }
}

export default connect(mapStateToProps, null)(Logo);