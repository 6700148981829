import { applyMiddleware, compose, createStore as createReduxStore, Store } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import makeRootReducer from './reducers';
import { updateLocation } from './location';
import { __DEV__ } from '../config';
import { History } from 'history';
import { AxiosRequestConfig } from 'axios';
import interceptor from './interceptor';

export const withAuthorizationHeader = (token: string): AxiosRequestConfig | undefined => {
  return token ? {headers: {'Authorization': `Bearer ${token}`}} : undefined;
};

export default (initialState = {}, history: History): Store => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [routerMiddleware(history), thunk];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers: any[] = [];
  let composeEnhancers = compose;

  if (__DEV__) {
    middleware.push(logger);
    if (typeof window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] === 'function')
      composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'];
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createReduxStore(
    makeRootReducer(),
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware),
      ...enhancers
    )
  )
  store['asyncReducers'] = {}
  
  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  store['unsubscribeHistory'] = history.listen(updateLocation(store));

  // Catch auth errors
  interceptor.setup(store);

  if (module['hot']) {
    module['hot'].accept('./reducers', () => {
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store['asyncReducers']))
    })
  }

  return store;
}
