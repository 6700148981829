import React from 'react';
import { connect } from 'react-redux';
import AdministratorModal from './AdministratorModal/AdministratorModal';
import ConfirmModal from './ConfirmModal/ConfirmModal'; 
import CustomerModal from './CustomerModal/CustomerModal';
import CustomizeModal from './CustomizeModal/CustomizeModal';
import PartnerModal from './PartnerModal/PartnerModal';
import UserModal from './UserModal/UserModal';
import OrderModal from './OrderModal/OrderModal';
import NumberAddModal from './Number/NumberAddModal';
import NumberEditModal from './Number/NumberEditModal';
import ImportModal from './ImportModal/ImportModal';

const MODAL_COMPONENTS: any = {
  'ADMINISTRATOR_MODAL': AdministratorModal,
  'CONFIRM_MODAL': ConfirmModal,
  'CUSTOMER_MODAL': CustomerModal,
  'CUSTOMIZE_MODAL': CustomizeModal,
  'PARTNER_MODAL': PartnerModal,
  'USER_MODAL': UserModal,
  'CSV_IMPORT_MODAL': ImportModal,
  'ORDER_MODAL': OrderModal,
  'NUMBER_ADD_MODAL': NumberAddModal,
  'NUMBER_EDIT_MODAL': NumberEditModal,
};

const ModalRoot = ({modalType, modalProps}: {modalType: string, modalProps?: any}) => {
  if (!modalType) return null;
  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...modalProps} />;
};

export default connect((state: any) => state.modal)(ModalRoot);
