import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '~/store/modal';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import { ordersActions } from '../../store/orders';
import { IOrderForm } from '../../store/orders';
import './OrdersView.scss';
import { ThemedButton } from '~/components/common/themed';
import { assetsActions } from '~/store/assets';

interface OwnProps { }

interface DispatchProps {
  createOrder: (form: IOrderForm) => Promise<Response>;
  exportItems: (opt: any) => Promise<any>;
  listOrders: () => void;
  openModal: (modalId: string) => void;
}

interface StateProps {
  orders: any;
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {}


const mapDispatchToProps = {
  listOrders: ordersActions.listOrders,
  createOrder: ordersActions.createOrder,
  openModal: modalActions.openModal,
  exportItems: assetsActions.exportItems
};

const mapStateToProps = (state: StateProps) => ({
  orders: state.orders
});

class OrdersView extends PureComponent<Props, State> {

  componentDidMount() {
    this.props.listOrders();
  }

  exportItems = (e: MouseEvent) => {
    e.preventDefault();
    const opt = {
      url: 'csv/orders'
    };
    this.props.exportItems(opt);

  }

  render() {
    const data = this.props.orders.orders.map((order: any) => ([
      order.id,
      { text: order.customer ? order.customer.name : '', link: `/customers/${order.customer_id}` },
      {
        text: order.customer && order.customer.partner ? order.customer.partner.name : '',
        link: order.customer && `/partners/${order.customer && order.customer.partner && order.customer.partner.id}` || ''
      },
      order.numbers.length,
      'New number',
      order.uid ? order.uid.name : order.uid,
      order.status,
      order.created_at
    ]));

    return (
      <div className='prt'>
        <Header />
        <main className="prt-body">
          <div className="prt-wrapper">
            <div className="prt-head">
              <h1 className="prt-page-title">Orders</h1>
              <ThemedButton className="btn-primary" onClick={() => this.props.openModal('ORDER_MODAL')}>Add Order</ThemedButton>
            </div>

            <Table
              data={data}
              columns={[
                { name: 'ID', type: 'string' },
                { name: 'Customer', type: 'link' },
                { name: 'Partner', type: 'link', access: ['administrator'] },
                { name: 'Number', type: 'string' },
                { name: 'Order Type', type: 'string' },
                { name: 'Ordered by', type: 'string' },
                { name: 'Status', type: 'status' },
                { name: 'Added', type: 'date' },
              ]}
            />


            <div className="prt-table-footer">
              <a href="#" onClick={this.exportItems} className="btn-secondary">Export</a>
            </div>

          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersView as any);
