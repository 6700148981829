import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';


interface StateProps {
  authenticatedUser: any;
};

type Props = RouteComponentProps & StateProps;


function ResolvePage(props: Props) {
  let redirectTo = '/login';
  let pathname: string[] = [''];
  let hash = '';
  if (props.history.location.state) {
    pathname = props.history.location.state.pathname;
    hash = props.history.location.state.hash;
  }

  if (props.authenticatedUser) {
    if (props.authenticatedUser.must_change_password) {
      redirectTo = '/signup';
    } else if (props.authenticatedUser.user_level === 'administrator') {
      redirectTo = pathname[0] && pathname[0] !== 'login' ?
        `/${pathname.join('/') + hash}` :
        '/partners';
    } else if (props.authenticatedUser.user_level === 'reseller') {
      redirectTo = pathname[0] && pathname[0] !== 'login' && pathname[0] !== 'partners' ?
        `/${pathname.join('/') + hash}` :
        '/customers';
    } else {
      pathname.shift();
      pathname.shift();
      const prevUrl = pathname.join('/');
      redirectTo = `/customers/${props.authenticatedUser.id}${prevUrl && '/' + prevUrl}${hash}`;
    }
  }

  console.log('Redirect to', redirectTo);
  if (redirectTo) props.history.push(redirectTo);
  return null;
}

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.auth.authenticatedUser,
});

export default withRouter(connect(mapStateToProps)(ResolvePage));