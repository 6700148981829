import React, { PureComponent } from 'react';
import { Accordion, Table, useAccordionToggle } from 'react-bootstrap';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import { connect } from 'react-redux';
import { Branding } from '~/branding';
import { apiTags, CommandcenterAPISidebar, MessageviewAPISidebar, API_FRONTEND_ACCESS_URL } from './APIDocConstants';
import './APIDocumentation.scss';
import { getCommandcenterData, getMessageviewData } from './APIDocumentationData';


interface OwnProps { }

interface DispatchProps {

}

interface StateProps {
  wl: any;
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {}


const mapDispatchToProps = {

};

const mapStateToProps = (state: any) => ({
  wl: state.users.wl
});

const { TABLE, IMG, JSON, H3, H4, SECTION, P, A } = apiTags;
// const { Authentication, Customer } = apiSidebar;

class APIDocumentation extends PureComponent<Props, State> {

  state = {
    icon: { eventKey: CommandcenterAPISidebar.Authentication, open: true },
    currentSection: CommandcenterAPISidebar.Authentication,
    clickedIndex: 0
  }
  deafultKey = CommandcenterAPISidebar.Authentication;
  isCommandcenter = false;

  constructor(props: any) {
    super(props);
    this.isCommandcenter = this.isCommandCenterAPIURL();
    const hash = this.getHash(this.isCommandcenter ? CommandcenterAPISidebar : MessageviewAPISidebar);
    if (hash) {
      this.deafultKey = hash[0];
    }
  }

  componentDidMount() {
    const hash = this.getHash(this.isCommandcenter ? CommandcenterAPISidebar : MessageviewAPISidebar);
    if (hash) {
      this.setState({ ...this.state, currentSection: hash[0], clickedIndex: hash.length == 2 ? hash[1] : 0 })
    }
  }

  componentDidUpdate(prevProps: Props) {
    this.handleClick(this.state.clickedIndex);
  }

  isCommandCenterAPIURL = () => {
    let pathname = window.location.pathname;
    return pathname === API_FRONTEND_ACCESS_URL.COMMANDCENTER_API_URL;
  }

  getHash = (apiSidebar: any) => {
    const hash = window.location.hash;
    if (hash) {
      const hashs = hash.substr(1).split("#");
      if (apiSidebar[hashs[0]]) {
        return hashs;
      }
    }
    return null;
  }

  _getKeyValue = (key: string) => (obj: any) => obj[key];

  brandColor = () => (this.props.wl && this.props.wl.primary_css_color) ? this.props.wl.primary_css_color : false;

  handleClick = (id: any) => {
    const testDiv = document.getElementById(id + '');
    if (testDiv && testDiv.offsetTop) {
      const offsetPosition = testDiv.offsetTop - 62;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }


  CustomToggle = ({ children, eventKey }: any) => {
    const brandColor = this.brandColor();
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      const isSame = this.state.icon.eventKey == eventKey;
      this.state.icon.eventKey = eventKey;
      if (isSame) {
        this.state.icon.open = !this.state.icon.open;
      } else {
        this.state.icon.open = true;
      }
    }
    );
    return (
      <button
        type="button"
        onClick={decoratedOnClick}
        className={this.state.icon.eventKey == eventKey && this.state.icon.open ? 'open' : ''}
        style={{ backgroundColor: brandColor ? brandColor : 'auto' }}
      >
        {children}
      </button>
    );
  }

  renderParentTags = (parentTag: any) => {
    return Object.keys(parentTag).map((key: any) => {
      const pTag = parentTag[key];
      switch (key) {
        case P:
          return <p>
            {pTag.detail}
            {this.renderChildTags(pTag.childTags)}
          </p>
        case SECTION:
          return <section>
            {pTag.detail}
            {this.renderChildTags(pTag.childTags)}
          </section>
        default:
          return null;
      }
    });
  }

  renderChildTags = (childTags: any) => {
    if (childTags) {
      return childTags.map((cTag: any) => {
        switch (cTag.key) {
          case H3:
            return <h3 className="h3">{cTag.detail}</h3>
          case H4:
            return <h4 className="h4">{cTag.detail}</h4>
          case P:
            return <p>
              {cTag.detail}
            </p>
          case A:
            return <a href={cTag.link} target="_blank">{cTag.detail}</a>;
          case TABLE:
            return <div className="table-responsive">
              <Table bordered>
                <tbody>
                  {cTag.rows ? cTag.rows.map((row: any, j: number) => (
                    <tr key={j}>
                      {(row.columns) ? row.columns.map((cell: any, k: number) => (
                        row.rowSpan && k == 0 ? <td rowSpan={row.rowSpan} key={k}><samp>{cell}</samp></td> : <td key={k}><samp>{cell}</samp></td>
                      )) : null}
                    </tr>
                  )) : null}
                </tbody>
              </Table>
            </div>
          case IMG:
            return <div className="imgtag">
              <img src={cTag.detail}></img>
            </div>
          case JSON:
            return <div className="pre-tag">
              <JSONPretty className="pre" id="json-pretty" data={cTag.detail} ></JSONPretty>
            </div>
        }
        return null;
      })
    }
    return null;
  }

  renderSidebar = (apiDetails: any) => {
    return <Accordion defaultActiveKey={this.deafultKey}>
      {Object.keys(apiDetails.sidebarLinks).map((key: any, i: number) => {
        const childLink = Object.keys(this._getKeyValue(key)(apiDetails.sidebarLinks).chlidLinks);
        return <ul key={i}>
          <li>
            <this.CustomToggle eventKey={key}>{key} <i className="fa fa-caret-down"></i></this.CustomToggle>
            <Accordion.Collapse eventKey={key}>
              <ul>
                {childLink ? childLink.map((chlidLink: any, j: number) =>
                  <li key={j}>
                    <a className={(key === this.state.currentSection && this.state.clickedIndex == j) ? 'active' : ''} onClick={() => {
                      this.setState({
                        ...this.state,
                        currentSection: key,
                        clickedIndex: j
                      });
                    }}>{chlidLink}</a>
                  </li>)
                  : null}
              </ul>
            </Accordion.Collapse>
          </li>
        </ul>
      }
      )};
    </Accordion>
  }

  renderMain = (apiDetails: any) => {
    const brandColor = this.brandColor();
    const childLinks = this._getKeyValue(this.state.currentSection)(apiDetails.sidebarLinks).chlidLinks;
    return Object.keys(childLinks).map((key: any, i: number) => {
      const tag = childLinks[key];
      const parentTags = tag.parentTags;
      return <div className="panel panel-default" id={i + ''}>
        <div className="panel-heading" style={{ backgroundColor: brandColor ? brandColor : 'auto', borderColor: brandColor ? brandColor : 'auto' }} > <h2 className="heading1 mt-0"><span>{tag.title}</span></h2></div>
        <div className="panel-body">
          {parentTags ? parentTags.map((parentTag: any) => this.renderParentTags(parentTag)) : null}
        </div>
      </div>
    });
  }


  render() {
    const apiDetails = this.isCommandcenter ? getCommandcenterData() : getMessageviewData();
    const brandColor = this.brandColor();
    return (
      <div className='api-page'>
        <div className="header">
          {this.props.wl && this.props.wl.logo_url ? <img src={this.props.wl.logo_url} /> : <img src={Branding.COMPANY_LOGO} style={{ "height": "75%" }} />}
        </div>
        <div className='sidebar' style={{ backgroundColor: brandColor ? brandColor : 'auto' }}>
          {this.renderSidebar(apiDetails)}
        </div>

        <main className="api-page-container">
          {this.renderMain(apiDetails)};
        </main>

        {/* <Footer /> */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(APIDocumentation as any);