import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import Outside from '~/components/common/Outside/Outside';
import { authActions } from '~/store/auth';
import { modalActions } from '~/store/modal';
import Logo from '~/components/common/Logo/Logo';
import AppName from '~/components/common/AppName/AppName';
import './Header.scss';

interface StateProps {
  authenticatedUser: any;
  isAuthenticated?: boolean;
  wl: any;
}

interface DispatchProps {
  logoutUser: (cb: Function) => void;
  openModal: (modalId: string) => void;
}

interface OwnProps extends RouteComponentProps {

}

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  isMenuOpen: boolean;
}

const mapDispatchToProps = {
  logoutUser: authActions.logoutUser,
  openModal: modalActions.openModal
}

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.auth.authenticatedUser,
  isAuthenticated: state.auth.isAuthenticated,
  wl: state.users.wl
});

class Header extends PureComponent<Props, State> {

  state = {
    isMenuOpen: false,
  }

  handleLogout = () => {
    this.props.logoutUser(() => this.props.history.push('/login'));
  }

  closeMenu = (e: MouseEvent) => {
    e.stopPropagation();
    this.setState({ isMenuOpen: false });
  }

  toggleMenu = () => {
    this.setState(prevState => ({ isMenuOpen: !prevState.isMenuOpen }));
  }

  openCustomize = () => this.props.openModal('CUSTOMIZE_MODAL');

  render() {
    if (!this.props.authenticatedUser) return null;
    const name = this.props.authenticatedUser.name ?
      this.props.authenticatedUser.name + (this.props.authenticatedUser.last_name ? ` ${this.props.authenticatedUser.last_name}` : '') :
      `${this.props.authenticatedUser.first_name} ${this.props.authenticatedUser.last_name}`;
    const letters = name.charAt(0);
    const brandColor = (this.props.wl && this.props.wl.primary_css_color) ? this.props.wl.primary_css_color : false;
    return (
      <header className="prt-header">
        <div className="prt-wrapper">
          <div className="prt-logo">
            <Logo />
          </div>
          <div className="prt-logo2">
            <AppName />
          </div>
          {this.props.isAuthenticated && <Outside className={classNames('prt-account', { open: this.state.isMenuOpen })} callback={this.closeMenu}>
            <React.Fragment>
              <div onClick={this.toggleMenu}>
                {name} <span className="prt-account-pic">{letters}</span>
              </div>
              <div className='prt-account-dropdown'>
                <span>{this.props.authenticatedUser.email}</span>
                <ul className='prt-account-links'>
                  {this.props.authenticatedUser.user_level === 'reseller' && <li onClick={this.openCustomize}>Customize</li>}
                  <li onClick={this.handleLogout}>Logout</li>
                </ul>
              </div>
            </React.Fragment>
          </Outside>}
        </div>
        <nav className="prt-nav" style={{ backgroundColor: brandColor ? brandColor : 'auto' }}>
          {this.props.isAuthenticated && this.props.authenticatedUser && <ul className="prt-nav-list">
            {this.props.authenticatedUser.user_level === 'administrator' && <li><NavLink to="/partners">Partners</NavLink></li>}
            {(this.props.authenticatedUser.user_level === 'administrator' || this.props.authenticatedUser.user_level === 'reseller') && <li className="active"><NavLink to="/customers">Customers</NavLink></li>}
            {/* {(this.props.authenticatedUser.user_level === 'administrator' || this.props.authenticatedUser.user_level === 'reseller') && <li><NavLink to="/orders">Orders</NavLink></li>} */}
            {(this.props.authenticatedUser.user_level === 'administrator' || this.props.authenticatedUser.user_level === 'reseller') && <li><NavLink to="/administrators">Administrators</NavLink></li>}
            {(this.props.authenticatedUser.user_level === 'administrator' || this.props.authenticatedUser.user_level === 'reseller') && <li><NavLink to="/api">API Documentation</NavLink></li>}
          </ul>}
        </nav>
      </header>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));