import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import CompanyAdministrators from '~/components/CompanyAdministrators/CompanyAdministrators';
import CompanyGeneral from '~/components/CompanyGeneral/CompanyGeneral';
import CompanyUsers from '~/components/CompanyUsers/CompanyUsers';
import Footer from '~/components/Footer/Footer';
import Header from '~/components/Header/Header';
import { ThemedTab } from '~/components/common/themed';
import { customersActions, ICustomer } from '../../store/customer';
import './CompanyView.scss';
import { modalActions } from '~/store/modal';
import Numbers from '../Numbers/Numbers';
import Report from '../Report/Report';

interface OwnProps extends RouteComponentProps { }

interface DispatchProps {
  getCustomer: (customerId: string) => Promise<void>;
  setCustomer: (customerId: string) => void;
  listCustomers: () => Promise<void>;
  clearCustomer: () => void;
  openModal: () => void;
}

interface StateProps {
  authenticatedUser: any;
  isAuthenticated: boolean;
  customer: ICustomer;
  customers: ICustomer[];
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {}


const mapDispatchToProps = {
  getCustomer: customersActions.getCustomer,
  setCustomer: customersActions.setCustomer,
  listCustomers: customersActions.listCustomers,
  clearCustomer: customersActions.clearCustomer,
  openModal: modalActions.openModal
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.auth.authenticatedUser,
  isAuthenticated: state.auth.isAuthenticated,
  customer: state.customers.customer,
  customers: state.customers.customers
});


class CompanyView extends PureComponent<Props, State> {

  state = {
    activeTab: 'general'
  }

  componentDidMount() {
    this.loadCustomer();
  }

  componentWillUnmount = () => {
    this.props.clearCustomer();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.customer === null && prevProps.customer !== null) {
      this.props.listCustomers().then(this.loadCustomer);
    }
    if (this.props.isAuthenticated && prevProps.isAuthenticated !== this.props.isAuthenticated) {
      this.loadCustomer();
    }
  }

  loadCustomer = () => {
    const { customerId } = this.props.match.params as any;
    if (customerId) {
      this.props.setCustomer(customerId);
    }
  }

  render() {
    const { customer } = this.props;
    // const NumbersTab = () => <React.Fragment></React.Fragment>;
    // const GroupsTab = () => <React.Fragment></React.Fragment>;

    return customer && (
      <div className='prt'>
        <Header />
        <main className="prt-body">
          <div className="prt-head-panel">
            <div className="prt-wrapper">
              <div className="prt-head-panel-inner">
                {(this.props.authenticatedUser.user_level !== 'customer' && this.props.authenticatedUser.user_level !== 'customer_admin') && <Link to={this.props.authenticatedUser.user_level === 'administrator' ? `/partners/${this.props.customer.reseller_id}#customers` : '/customers'} className="prt-back">{this.props.authenticatedUser.user_level === 'administrator' ? 'Customers' : 'Customer'}</Link>}
                <h1 className="prt-page-title">{this.props.authenticatedUser.user_level === 'customer_admin' ? customer.parent_name : customer.name}</h1>
                <ul className="prt-tabs">
                  {this.props.authenticatedUser.user_level !== 'customer_admin' && <ThemedTab className={classnames({ active: !this.props.location.hash || this.props.location.hash === '#general' })}><a href="#general">General</a></ThemedTab>}
                  <ThemedTab className={classnames({ active: this.props.location.hash === '#stats' || (this.props.authenticatedUser.user_level === 'customer_admin' && !this.props.location.hash) })}><a href="#stats">STATS</a></ThemedTab>
                  <ThemedTab className={classnames({ active: this.props.location.hash === '#administrators' || (this.props.authenticatedUser.user_level === 'customer_admin' && !this.props.location.hash) })}><a href="#administrators">Administrators</a></ThemedTab>
                  <ThemedTab className={classnames({ active: this.props.location.hash === '#users' })}><a href="#users">Users</a></ThemedTab>
                  <ThemedTab className={classnames({ active: this.props.location.hash === '#numbers' })}><a href="#numbers">Numbers</a></ThemedTab>
                </ul>
              </div>
            </div>
          </div>
          <div className="prt-wrapper">
            {((!this.props.location.hash || this.props.location.hash === '#general') && this.props.authenticatedUser.user_level !== 'customer_admin') && <CompanyGeneral customer={customer} setCustomer={this.props.setCustomer} openModal={this.props.openModal} />}
            {(this.props.location.hash === '#administrators' || (!this.props.location.hash && this.props.authenticatedUser.user_level === 'customer_admin')) && <CompanyAdministrators />}
            {this.props.location.hash === '#users' && <CompanyUsers />}
            {this.props.location.hash === '#numbers' && <Numbers />}
            {this.props.location.hash === '#stats' && <Report />}
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyView);